import React, { useEffect, useState } from 'react'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'
import RenderLost from './RenderLost'
import RenderWonTicketAndKYCPhase from './RenderWonTicketAndKYCPhase'
import RenderReceipt from './RenderReceipt'
import RenderSolanaAddress from './RenderSolanaAddress'
import RenderContainer from './RenderContainer'
import ChainSwitchBSC from './ChainSwitchBSC'
import InvestmentBox from './InvestmentBox'
import { useAccount } from 'wagmi'
import { MAIN_CHAIN_ID } from '../../constant'

const RenderInvestmentPhase = ({
  userInvested,
  _userParticipation,
  user,
  idoData,
  totalInvested,
  multiplierData,
  score,
  totalParticipants,
  totalAssetsConnected,
  onInvested,
  projectDetails,
}: any) => {
  const [solanaAddressUpdated, setSolanaAddressUpdated] = useState(false)

  const isSolanaIdo = idoData.claimNetwork === 'Solana'

  const { chain } = useAccount()

  // if(_userParticipation) {
  //   _userParticipation = {
  //     ..._userParticipation,
  //     solanaAddress: null,
  //   }
  // }

  const [
    updateSolanaAddressInParticipation,
    {
      data: solanaAddressInParticipationData,
    },
  ] = useUpdateSolanaAddressInParticipationMutation()

  useEffect(() => {
    if (solanaAddressInParticipationData) {
      setSolanaAddressUpdated(true)
    }
  }, [solanaAddressInParticipationData])


  const renderInvestmentPhase = () => {
    if (userInvested && _userParticipation?.isWinner) {
      return (
        <>
          {!isSolanaIdo || _userParticipation?.solanaAddress ? (
            <RenderContainer
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              idoData={idoData}
              sideComponent={
                <RenderReceipt
                  idoData={idoData}
                  score={score}
                  totalParticipants={totalParticipants}
                  totalAssetsConnected={totalAssetsConnected}
                  multiplierData={multiplierData}
                />
              }
              type="won"
              solanaAddress={_userParticipation?.solanaAddress}
            />
          ) : (
            <RenderContainer
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              idoData={idoData}
              sideComponent={
                <RenderSolanaAddress
                  idoData={idoData}
                  score={score}
                  totalParticipants={totalParticipants}
                  totalAssetsConnected={totalAssetsConnected}
                  multiplierData={multiplierData}
                  userParticipation={_userParticipation}
                  updateSolanaAddressInParticipation={
                    updateSolanaAddressInParticipation
                  }
                />
              }
              type="registered"
              solanaAddress={_userParticipation?.solanaAddress}
            />
          )}
        </>
      )
    } else if (_userParticipation?.isWinner && user.kycStatus !== 'approved') {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderWonTicketAndKYCPhase
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              user={user}
            />
          }
          type="won"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (
      !solanaAddressUpdated &&
      _userParticipation &&
      _userParticipation?.isWinner &&
      !_userParticipation?.solanaAddress &&
      (idoData._id === '65967ae64492fe630b2a1c63' ||
        idoData._id === '65ba5079ed723a4f03528cfe' ||
        idoData._id === '65fda62c90a71328b0e712f1') // for mixMob
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderSolanaAddress
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              userParticipation={_userParticipation}
              updateSolanaAddressInParticipation={
                updateSolanaAddressInParticipation
              }
            />
          }
          type="registered"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (_userParticipation?.isWinner && !userInvested) {
      if (chain?.id !== MAIN_CHAIN_ID) {
        return (
          <RenderContainer
            score={score}
            totalParticipants={totalParticipants}
            totalAssetsConnected={totalAssetsConnected}
            multiplierData={multiplierData}
            idoData={idoData}
            sideComponent={<ChainSwitchBSC />}
            type="won"
            solanaAddress={_userParticipation?.solanaAddress}
          />
        )
      }

      return (
        <RenderContainer
          type={'won'}
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <InvestmentBox
              idoData={idoData}
              project={projectDetails}
              onInvested={onInvested}
            />
          }
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (userInvested && _userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderReceipt
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (
      _userParticipation?.isWinner &&
      totalInvested >= idoData.totalInvestors
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (!_userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="registered"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    }
  }
  return <>{renderInvestmentPhase()}</>
}

export default RenderInvestmentPhase
