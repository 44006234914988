import DarkSwapIcon from '../../assets/d_swap.png'
import LightSwapIcon from '../../assets/l_swap.png'
import HomeIcon from '@mui/icons-material/Home'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import { ReactComponent as LaunchpadIcon } from '../../assets/ape_app_icon.svg'
import { ReactComponent as PortfolioIcon } from '../../assets/portfolio_icon.svg'
import { ReactComponent as ApeAppIcon } from '../../assets/launchpad_icon.svg'

const navList = [
  {
    name: 'For Adopters',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: ApeAppIcon,
    href: '/',
    authorizationRequired: true,
    icon: ApeAppIcon,
    target: '_self',
  },
  {
    name: 'For Builders',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: ApeAppIcon,
    href: '/',
    authorizationRequired: true,
    icon: ApeAppIcon,
    target: '_self',
  },
  {
    name: 'Products',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: ApeAppIcon,
    href: '/',
    authorizationRequired: true,
    icon: ApeAppIcon,
    target: '_self',
  },
  {
    name: 'About',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: LaunchpadIcon,
    href: '',
    authorizationRequired: false,
    icon: HomeIcon,
    target: '_self',
    submenu: [
      {
        name: "Web3 General",
        href: "https://www.apeterminal.io/about/",
      },
      {
        name: "Web2 Institutions",
        href: "https://www.apeterminal.io/about/web2-institutions",
      },
      {
        name: "Web2 Retail",
        href: "https://www.apeterminal.io/about/web2-retail",
      },
    ],
  },
  // {
  //   name: 'Launchpad',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: LaunchpadIcon,
  //   href: '/',
  //   authorizationRequired: false,
  //   icon: HomeIcon,
  //   target: '_self',
  // },
  // {
  //   name: 'Portfolio',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: PortfolioIcon,
  //   href: '/portfolio',
  //   authorizationRequired: true,
  //   icon: CreditCardIcon,
  //   target: '_self',
  // },
  // {
  //   name: 'Transparency',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: ApeAppIcon,
  //   href: '/transparency',
  //   authorizationRequired: true,
  //   icon: ApeAppIcon,
  //   target: '_self',
  // },
  // {
  //   name: 'Ape Activator',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: ApeAppIcon,
  //   href: 'https://www.apeterminal.io/activator/',
  //   authorizationRequired: false,
  //   icon: ApeAppIcon,
  //   target: '_blank',
  // },
  // {
  //   name: 'News',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: ApeAppIcon,
  //   href: 'https://www.apeterminal.io/news/',
  //   authorizationRequired: false,
  //   icon: ApeAppIcon,
  //   target: '_blank',
  // },
  // {
  //   name: 'Yield App',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: ApeAppIcon,
  //   href: 'https://app.apeterminal.io',
  //   target: '_blank',
  //   authorizationRequired: true,
  //   icon: ApeAppIcon,
  // },
]

export default navList
