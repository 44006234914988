import {
  Dialog,
  IconButton,
  Box,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { useAccount } from 'wagmi'
import { useState } from 'react'
import { LightCross, GridLines, GoogleIcon } from '../../assets'
import GreenButton from '../GreenButton'
import SignatureModal from '../../components/SignatureModal'
import SignInWithGoogleModal from '../../components/SignInWithGoogleModal'
import { useParticipationEntryPoint } from '../../Context'
import ConnectButton from '../../components/Buttons/ConnectButton'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { setLoginMethod, setWalletAddress } from '../../redux/slices/userSlice'

interface IProps {
  open: boolean
  onClose: () => void
  openSignautureModal: boolean
  setOpenSignautureModal: (open: boolean) => void
}

const LoginModal = ({
  open,
  onClose,
  openSignautureModal,
  setOpenSignautureModal,
}: IProps) => {
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const isDown600 = useMediaQuery('(max-width:600px)')
  const { open: openWeb3Modal } = useWeb3Modal()
  const { setParticipationEntryPoint } = useParticipationEntryPoint()
  const [openSignInWithGoogleModal, setOpenSignInWithGoogleModal] =
    useState(false)
  const user = useAppSelector(state => state.user)

  const handleConect = () => {
    dispatch(setWalletAddress(''))
    dispatch(setLoginMethod('metamask'))
    onClose()
    openWeb3Modal()
    setParticipationEntryPoint('Header')
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: '12px',
            maxWidth: '468px',
            width: '100%',
            height: '300px',
            borderRadius: '24px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            backgroundColor: '#fff',
            backgroundImage:
              'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
            position: 'relative',
          },
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '40%',
            backgroundImage: `url(${GridLines})`,
            backgroundRepeat: 'repeat',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            edge="end"
            onClick={onClose}
            aria-label="close"
            sx={{
              backgroundColor: '#1D1F21',
              height: '40px',
              width: '40px',
              border: '1px solid #1D1F21',
              transition: 'all 0.2s ease',
              marginRight: '2px',
              '&:hover': {
                backgroundColor: '#0F1113',
                border: '1px solid #313335',
              },
            }}
          >
            <img src={LightCross} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '20px',
            width: isDown600 ? '90%' : '70%',
            alignSelf: 'center',
            textAlign: 'center',
            position: 'relative',
            zIndex: 3,
          }}
        >
          <Typography
            sx={{
              paddingBottom: '40px',
              fontSize: isDown600 ? '24px'  : '32px',
              fontWeight: 600,
              color: '#000',
              whiteSpace: 'nowrap',
              fontFamily: 'Hellix',
            }}
          >
            Login to Your Account
          </Typography>
          <GreenButton
            IconStart={() => <img src={GoogleIcon} alt="google icon" />}
            text="Sign in with Google"
            br="24px"
            fs={15}
            fw={400}
            fm="Inter"
            lh="22.5px"
            p="8px 14px"
            h="38px"
            w="max-content"
            onClick={() => {
              onClose()
              setOpenSignInWithGoogleModal(true)
            }}
            disabled={!!user.email}
            sx={{ textTransform: 'none', mb: '20px' }}
          />
          <ConnectButton
            text="Connect Wallet"
            onClick={handleConect}
            disabled={!!address}
          />
        </Box>
      </Dialog>
      <SignatureModal
        open={openSignautureModal}
        setOpen={() => setOpenSignautureModal(false)}
      />

      <SignInWithGoogleModal
        open={openSignInWithGoogleModal}
        onClose={() => setOpenSignInWithGoogleModal(false)}
      />
    </>
  )
}

export default LoginModal
