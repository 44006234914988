import { Button, Typography } from '@mui/material'

const OrangeButton = ({
  mt = 0,
  text,
  Icon,
  mb = 0,
  h,
  w,
  fs,
  fw,
  lh,
  br,
  onClick,
  sx,
  disabled = false,
  border,
  fm,
  bgColor,
  id = 'BTN',
  IconStart,
  p,
}: {
  mt?: number
  mb?: number
  text?: string
  Icon?: any
  h?: string
  w?: string
  fs?: number | string
  fw?: number
  lh?: string
  br?: string
  sx?: any
  onClick?: () => void
  disabled?: boolean
  border?: string
  fm?: string
  bgColor?: string
  id?: string
  IconStart?: any
  p?: string,
}) => {
  return (
    <Button
      sx={{
        mt: mt,
        mb: mb,
        padding: p ? p : 'auto',
        height: h,
        width: w ? w : '100%',
        borderRadius: br,
        border: border ? border : 'none',
        fontFamily: fm ? fm : 'Hellix',
        bgcolor: bgColor ? bgColor : '#FF7722',

        ...sx,

        '&:hover': {
          backgroundColor: bgColor ? bgColor : 'rgba(255,119,34,0.9)',
        },

        '&:disabled': {
          background: '#333333',
          border: 'none',
          cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
      }}
      variant="contained"
      color="primary"
      onClick={e => {
        e.stopPropagation()
        onClick && onClick()
      }}
      disabled={disabled}
      id={id}
    >
      {IconStart && <IconStart />}
      {text && (
        <Typography
          fontSize={fs ? fs : 17}
          fontWeight={fw ? fw : 500}
          lineHeight={lh && lh}
          color="#000"
          fontFamily={fm ? fm : 'Hellix'}
          textTransform="initial"
        >
          {text}
        </Typography>
      )}

      {Icon && <Icon />}
    </Button>
  )
}

export default OrangeButton
