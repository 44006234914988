import { CHAIN_ID } from '../chains';

export const SERVER_URL = 'https://api.ape-launchpool.eachop.tech/api';

export const CONTRACT_ADDRESS = {
  APE_INVESTMENT: {
    [CHAIN_ID.BSC]: "0x2aF527Bd9fd71b98494c6404f8Af5011b17a1ed7",
    [CHAIN_ID.ETH]: "0x6B02dA8eE04494B324Cb400D6d8Cfac239d4FDc5",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_VESTING_UNIFIED: {
    [CHAIN_ID.ETH]: "0x8407490c88667C1C5Ca2910f95Dd4027C84e1804",
    [CHAIN_ID.ARB]: "",
    [CHAIN_ID.BASE]: "0x33b239885466b3a5791bFAb7023752950784D122",
    [CHAIN_ID.BSC]: "0x632137641FFd923217c4b289C4159Ee480360098",
    [CHAIN_ID.AVAX]: "0x3614d378ab6334F65942dA5d4A5423710bCcD362",
    [CHAIN_ID.ARB_TESTNET]: "0x0db1d1b956ca01cfa3108921e5fe22973e0e0873",
    [CHAIN_ID.BSC_TESTNET]: "0x0cc5c47089351d889fe082661fb13bff1ba2a632",
  },
  APE_INVESTMENT_UNIFIED: {
    [CHAIN_ID.BSC]: "0x9B0Eba47330C54E8Ec051F673319de2046e6dA2e",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_REFUND_UNIFIED: {
    [CHAIN_ID.BSC]: "0xE27E1b4B593fC4bCA68bBD753133C88D519E8B8b",
    [CHAIN_ID.BSC_TESTNET]: "0x7c273601600f4dfda3742aae2f341a8c1fa1a124",
  },
  USDC: {
    [CHAIN_ID.BSC]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    [CHAIN_ID.BSC_TESTNET]: "0xbA386FB039f7d35BE74214e88aBe9D0d055139Bd",
    [CHAIN_ID.ARB_TESTNET]: "0xb0ef8029ab21baab33d3b8eda9792d30f9057f74",
  }
}
