import { CHAIN_ID } from '../chains';

export const SERVER_URL = 'https://ape-launchpad-backend-6867088bb0b2-uat.azurewebsites.net/api';

export const CONTRACT_ADDRESS = {
  APE_INVESTMENT: {
    [CHAIN_ID.BSC]: "0xd45b64fd74c7f9842ce00b78c8fa9d267bc694cd",
    [CHAIN_ID.ETH]: "0x6B02dA8eE04494B324Cb400D6d8Cfac239d4FDc5",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_VESTING_UNIFIED: {
    [CHAIN_ID.ETH]: "0x8407490c88667C1C5Ca2910f95Dd4027C84e1804",
    [CHAIN_ID.ARB]: "",
    [CHAIN_ID.BASE]: "0x33b239885466b3a5791bFAb7023752950784D122",
    [CHAIN_ID.BSC]: "0xb38fB2371cBF3AaB7d190C9BE8b28d5697f98f6f",
    [CHAIN_ID.AVAX]: "0x3614d378ab6334F65942dA5d4A5423710bCcD362",
    [CHAIN_ID.ARB_TESTNET]: "0x0db1d1b956ca01cfa3108921e5fe22973e0e0873",
    [CHAIN_ID.BSC_TESTNET]: "0x0cc5c47089351d889fe082661fb13bff1ba2a632",
  },
  APE_INVESTMENT_UNIFIED: {
    [CHAIN_ID.BSC]: "0xd45b64fd74c7f9842ce00b78c8fa9d267bc694cd",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_REFUND_UNIFIED: {
    [CHAIN_ID.BSC]: "0x8F1edf4639f0bFe056Be6d200BE8C30D719A4002",
    [CHAIN_ID.BSC_TESTNET]: "0x7c273601600f4dfda3742aae2f341a8c1fa1a124",
  },
  USDC: {
    [CHAIN_ID.BSC]: "0x903535891D477d6b2901d5fDee4d8626E12e6a42",
    [CHAIN_ID.BSC_TESTNET]: "0xbA386FB039f7d35BE74214e88aBe9D0d055139Bd",
    [CHAIN_ID.ARB_TESTNET]: "0xb0ef8029ab21baab33d3b8eda9792d30f9057f74",
  }
}
