import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import  * as amplitude from '@amplitude/analytics-browser';
import { useAppSelector, useGetUserKyc } from './hooks/';
import axios from 'axios'
import { useParticipationEntryPoint } from './Context';
import { useWalletInfo } from '@web3modal/wagmi/react';
import { useAccount, useBalance } from 'wagmi';
import { ENABLE_ANALYTIC } from './constant';

interface AnalyticsContextProps {
  trackEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
  updateUserProperties: (properties: Record<string, any>) => void;
  updateProjectJoin: () => void;
  updateWalletType: (value: string) => void;
  updateWalletSize: (value: string) => void;
  updateReferralCode: (value: string) => void;
}
const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

export const AnalyticsProvider: React.FC<{ apiKey: string; children: ReactNode }> = ({ apiKey, children }) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const currentUser = useAppSelector(state => state.user.mainAppToken);
  const user = useAppSelector(state => state.user)
  const deviceId = amplitude.getDeviceId();
  const [utmParams, setUtmParams] = useState({
    utm_campaign: '',
    utm_source: '',
    utm_content: '',
    utm_medium: '',
    referral_code: ''
  });
  const [locationParams, setLocation] = useState({
    city: '',
    region: '',
    country: '',
  });
  const { projectLinkSource } = useParticipationEntryPoint()
  const { participationEntryPoint } = useParticipationEntryPoint()
  const { userKycStatus } = useGetUserKyc()

  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setLocation({
          city: response.data.city,
          region: response.data.region,
          country: response.data.country,
        })
        // Handle the response data here
        console.log(response.data); 
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
      }
    }
    fetchData();
  }, []) 

  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    const params = new URLSearchParams(location.search);
    const utm_campaign = params.get('utm_campaign') || '';
    const utm_source = params.get('utm_source') || '';
    const utm_content = params.get('utm_content') || '';
    const utm_medium = params.get('utm_medium') || '';
    const referral_code = params.get('code') || '';

    setUtmParams({
      utm_campaign,
      utm_source,
      utm_content,
      utm_medium,
      referral_code,
    });
  }, [location]);

  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    if (user.walletAddress && !initialized && locationParams.city && locationParams.region && locationParams.country && user.kycStatus) {
      const identify = new amplitude.Identify()
        .set('city', locationParams.city)
        .set('region', locationParams.region)
        .set('country', locationParams.country)
        .set('registration_date', new Date().toISOString())
        .set('referral_code', utmParams.referral_code)
        .set('utm_campaign', utmParams.utm_campaign)
        .set('utm_source', utmParams.utm_source)
        .set('utm_content', utmParams.utm_content)
        .set('utm_medium', utmParams.utm_medium)
        .set('user_id', user.walletAddress)
        .set('device_id', deviceId || 'unknown')
        .set('kyc_status', userKycStatus)
      amplitude.setUserId(user.walletAddress);
      
      setInitialized(true);
      amplitude.identify(identify);
    }
  }, [user.walletAddress, initialized, locationParams, utmParams, deviceId, user.kycStatus]);

  // Initialize Amplitude
  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    amplitude.init(apiKey, {
      defaultTracking: false,
    });
  }, [apiKey]);

  function normalizeUrl(path: string) {
    // const path = window.location.pathname.split('/');
    const p = path.split('/');
    const result = p.length > 1 ? p.slice(0, -1).join('/') : path;
    const decodeResult = decodeURIComponent(result.toLowerCase().replace(/_/g, ' '));
    return decodeResult;
  }

  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    const trackPageView = (path: string) => {
      const referrer = document.referrer;
      switch (path) {
        case '/':
          amplitude.track('MainPageOpen', { timestamp: new Date().toISOString(), referrer });
          break;
        case '/transparency':
          amplitude.track('TransparencyPageOpen', { timestamp: new Date().toISOString(), referrer });
          break;
        case '/portfolio':
          amplitude.track('PortfolioPageOpen', { timestamp: new Date().toISOString(), referrer });
          break;
        default:
        break;
        }
    };
    trackPageView(location.pathname);
  }, [location, apiKey, utmParams.referral_code]);

  const { walletInfo } = useWalletInfo()
  const prevWalletInfoRef = useRef(walletInfo);
  const { address } = useAccount()
  const { data: ethBalanceData } = useBalance({
    address: address,
  })
  const balance = ethBalanceData ? ethBalanceData.formatted : '0';
  useEffect(() => {
    if(!ENABLE_ANALYTIC) return;
    if (!prevWalletInfoRef.current && walletInfo) {
      switch (participationEntryPoint) {
        case 'Header':
          trackEvent('WalletConnected', {
            wallet_type: walletInfo?.name,
            wallet_size: Number.parseFloat(balance) ? Number.parseFloat(balance).toString() : 0,
            referral_code: utmParams.referral_code,
            button_source: 'Header'
          });
          updateWalletType(walletInfo?.name ?? '')
          updateWalletSize(balance)
          updateReferralCode(utmParams.referral_code ?? '')
        break;
        case 'StepsSection':
          trackEvent('WalletConnected', {
            wallet_type: walletInfo?.name,
            wallet_size: Number.parseFloat(balance) ? Number.parseFloat(balance).toString() : 0,
            referral_code: utmParams.referral_code,
            button_source: 'StepsSection'
          });
          updateWalletType(walletInfo?.name ?? '')
          updateWalletSize(balance)
          updateReferralCode(utmParams.referral_code ?? '')
        break;
        case 'ProjectPage':
          trackEvent('WalletConnected', {
            wallet_type: walletInfo?.name,
            wallet_size: Number.parseFloat(balance) ? Number.parseFloat(balance).toString() : 0,
            referral_code: utmParams.referral_code,
            button_source: 'ProjectPage'
          });
          updateWalletType(walletInfo?.name ?? '')
          updateWalletSize(balance)
          updateReferralCode(utmParams.referral_code ?? '')
        break;
        default:
          break;
      }

      //server-side tracking
      // fetch('https://api2.amplitude.com/2/httpapi', {
      //   method: 'POST',
      //   headers: {
      //       'Content-Type': 'application/json',
      //       'Accept': '*/*'
      //   },
      //   body: JSON.stringify({
      //     "api_key": apiKey,
      //     "events": [{
      //         "user_id": currentUser,
      //         "event_type": 'WalletConnectedServer',
      //         "event_properties": {
      //           "wallet_type": walletInfo?.name,
      //           "wallet_size": balance,
      //           "referral_code": utmParams.referral_code,
      //         }
      //     }]
      //   })
      // })
      // .then(response => response.json())
      // .then(data => console.log('Event sent:', data))
      // .catch(error => console.error('Error sending event:', error));
    }
    prevWalletInfoRef.current = walletInfo;
  }, [walletInfo])
    
  const trackEvent = useMemo(() => (eventName: string, eventProperties?: Record<string, any>) => {
    if(!ENABLE_ANALYTIC) return;
    amplitude.track(eventName, {
      timestamp: new Date().toISOString(),
      ...eventProperties
    });
  }, []);

  const updateUserProperties = (properties: Record<string, any>) => {
    if (initialized) {
      const identify = new amplitude.Identify();
      for (const key in properties) {
        if (properties.hasOwnProperty(key)) {
          identify.append(key, properties[key]);
        }
      }
      amplitude.identify(identify);
    }
  };

  const updateProjectJoin = () => {
    if (!initialized) {
      return
    }
    const identifyObj = new amplitude.Identify();
    identifyObj.add('number_of_projects', 1);
    amplitude.identify(identifyObj)
  };
  const updateWalletType = (value: string) => {
    if (!initialized) {
      return
    }
    const identifyObj = new amplitude.Identify();
    identifyObj.set('wallet_type', value);
    amplitude.identify(identifyObj)
  };
  const updateWalletSize = (value: string) => {
    if (!initialized) {
      return
    }
    const identifyObj = new amplitude.Identify();
    identifyObj.set('wallet_size', value);
    amplitude.identify(identifyObj)
  };
  const updateReferralCode = (value: string) => {
    if (!initialized) {
      return
    }
    const identifyObj = new amplitude.Identify();
    identifyObj.set('referral_code', value);
    amplitude.identify(identifyObj)
  };

  const value:AnalyticsContextProps = useMemo(() => ({
    trackEvent,
    updateUserProperties,
    updateProjectJoin,
    updateWalletType,
    updateWalletSize,
    updateReferralCode,
  }), [currentUser, trackEvent, updateUserProperties, updateProjectJoin, updateWalletType, updateWalletSize, updateReferralCode]);

  return (
    <AnalyticsContext.Provider value={ value }>
      {children}
    </AnalyticsContext.Provider>
  );
};
export const useAnalytics = (): AnalyticsContextProps => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};