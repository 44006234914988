import _ from 'lodash'
import { getEpoch } from '../utils'
import { generateToken } from './firebase'
import { store } from '../redux/configureStore';
import eventBus from '../sockets/event-bus';
import { ESocketEvent, TypeNotiEvent } from '../sockets/socket';

const defaultNotificationPerm = 'default'
const grantedNotificationPerm = 'granted'

let swRegistration: any
const sentNotificationIdList = new Set()

const setupNotificationPermission = async () => {
  try {
    if (typeof Notification !== 'function') return
    // ask for Notification perm
    if (Notification.permission == defaultNotificationPerm) {
      await Notification.requestPermission()
    }
    if (Notification.permission != grantedNotificationPerm) {
      console.error('Notification permission not granted')
    }
  } catch (e) {
    console.error(e)
  }
}

const dispatchInAppNotification = (event: any) => {
  const { data } = event;
  const { data: payload } = data;
  const type = payload.type || ESocketEvent.BROADCAST

  let notiPayload = {};

  if(type === ESocketEvent.BROADCAST) {
    notiPayload = {
      title: payload.title || 'APE Terminal',
      content: payload.body || 'New notification',
      time: Number(payload.time) || new Date().getTime(),
      url: payload.url || '',
    }
  } else {
    notiPayload = {
      projectId: payload.projectId || '',
      projectName: payload.projectName || 'APE Terminal',
      routeName: payload.routeName || '',
      projectLogo: payload.projectLogo || '',
      startRegistration: Number(payload.startRegistration) || '',
      endRegistration: Number(payload.endRegistration) || '',
      time: Number(payload.time) || '',
      type: payload.type || '',
    }
  }

  eventBus.dispatch(type === TypeNotiEvent ? ESocketEvent.EVENT_REGISTRATION : ESocketEvent.RESPONSE_EVENT_CONTRIBUTION , notiPayload)
}

// TODO: stop using global scope
export const setupServiceWorker =
  async (): Promise<null | ServiceWorkerRegistration> => {
    let ret: null | ServiceWorkerRegistration = null
    try {
      setupNotificationPermission()
      console.warn('setupServiceWorker ...')
      if (!('serviceWorker' in navigator && 'PushManager' in window)) {
        throw new Error('Push notifications are not supported')
      }
      // swRegistration = await navigator.serviceWorker.register(
      //   '/service-worker.js',
      // )
      swRegistration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
      )

      let userAddress = store?.getState()?.user?.walletAddress || '';
      await generateToken(userAddress);

      navigator.serviceWorker.addEventListener('message', event => {
        console.info(`got msg from serviceWorker`, event)

        // Show in-app notification
        dispatchInAppNotification(event);

        sendPushNotification({
          id: '',
          title: event.data?.data?.title || 'Ape Terminal',
          body: event.data?.data?.body || '',
          icon: event.data?.icon || '',
          badge: event.data?.badge || '',
          link: event.data?.data?.url || '/',
        })
      })
      // @ts-ignore
      window['swRegistration'] = swRegistration
    } catch (e) {
      console.error(e)
    }
    return ret
  }

interface IPushNotificationParams {
  id?: string
  title: string
  body?: string
  icon?: string
  badge?: string
  link?: string
}

export const sendPushNotification = (params: IPushNotificationParams) => {
  if (!swRegistration || !params) return
  const { id } = params
  if (id && sentNotificationIdList.has(id)) return
  const postMessageFn = _.get(swRegistration, 'active.postMessage')
  if (typeof postMessageFn != 'function') return
  swRegistration.active.postMessage(JSON.stringify(params))
  if (id) {
    sentNotificationIdList.add(id)
  }
}
// @ts-ignore
window['sendPushNotification'] = sendPushNotification
