import { Box, Typography, useMediaQuery } from '@mui/material'
import { KeepTryingBoxIcon, KeepTryingPattern, KeepTryingBoxGridIcon } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useNavigate } from 'react-router-dom'

const KeepTryingBox = ({
  heading,
  subText,
}: {
  heading: string
  subText: string
}) => {
  const navigate = useNavigate()
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={isDown1000 ? '24px' : '22%'}
        paddingY={isDown1000 ? '24px' : '32px'}
      >
        {isDown1000 ? (
          <img
            src={KeepTryingPattern}
            alt="keep trying"
            style={{ width: '208px', height: '208px' }}
          />
        ) : (
          <img
            src={KeepTryingBoxGridIcon}
            alt="keep trying"
            style={{ width: '208px', height: '208px' }}
          />
        )}
        <Typography
          pt={isDown1000 ? 0 : '0px'}
          marginTop={isDown1000 ? '0' : '-48px'}
          pb={'16px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#000'}
        >
          {heading || 'Keep Trying!'}
        </Typography>
        <Typography
          fontFamily={'Inter'}
          fontSize={16}
          fontWeight={400}
          lineHeight={'24px'}
          color={'#7E8180'}
        >
          {subText ||
            'We’re rooting for you to win next time, don’t forget to maximize your support for projects to better your chances.'}
        </Typography>
        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text="Other Sales"
          mt={5}
          onClick={() => {}}
          w="200px"
        />
        <Typography
          fontFamily={'Inter'}
          fontSize={14}
          fontWeight={400}
          lineHeight={'21px'}
          color={'#000'}
          mt={2}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/')
          }}
        >
          <u>Back to Home page</u>
        </Typography>
      </Box>
    </Box>
  )
}

export default KeepTryingBox
