import { Box, TableRow, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FixedBodyTableCell, StyledBodyTableCell } from './MySalesTable'
import {
  replaceUnderscoresWithSpaces,
  getReplacedCdnEndpoint,
  getChainIdFromName,
  formatBigNumber,
} from '../../utils'
import { useAccount } from 'wagmi'
import { BigNumber, ethers } from 'ethers'
import ClaimNewAbi from '../../contracts/claim_new.json'
import Erc20Abi from '../../contracts/erc-20.json'
import { useNavigate } from 'react-router-dom'
import { RPC_URL, CONTRACT_ADDRESS } from '../../constant'
import { AbiFunction } from 'viem'
import { multicall } from '@wagmi/core'
import { wagmiConfig } from '../../components/Web3Provider'
interface IUnifiedVestingClaimInfoStats {
  refundRequestedAt: number
  refundedAt: number
  claimedAmountBI: bigint
  claimableAmountBI: bigint
  tokenDeposited: bigint
  tokenRemains: bigint
  allocationAmountBI: string
  tokenSymbol: string
  tokenDecimals: number
}

const VESTING_UNIFIED_ABI_NEW: AbiFunction[] = ClaimNewAbi as AbiFunction[]
const ERC20_ABI: AbiFunction[] = Erc20Abi as AbiFunction[]

const ClaimRow = ({ sale, index }: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')

  const { address } = useAccount()
  const navigate = useNavigate()

  const [claimableAmount, setClaimableAmount] = useState(0)
  const [unifiedVestingClaimInfoStats, setUnifiedVestingClaimInfoStats] =
    useState<IUnifiedVestingClaimInfoStats | undefined>(undefined)

  const [claimedAmount, setClaimedAmount] = useState(0)

  useEffect(() => {
    ;(async () => {
      try {
        let projectClaimNetwork =
          sale.ido?.project?.token?.network || sale.ido?.claimNetwork

        if (
          !projectClaimNetwork ||
          !['BSC', 'ETH', 'ARB', 'AVAX', 'BSC_TESTNET'].includes(
            projectClaimNetwork,
          )
        )
          return

        let claimChainID = getChainIdFromName(sale.ido.project.token.network)
        // @ts-ignore
        let claimContractAddress =
          CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainID!]
        let provider

        if (projectClaimNetwork === 'BSC') {
          console.log('calling BSC')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.BSC)
        } else if (projectClaimNetwork === 'ETH') {
          console.log('calling ETH')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.ETH)
        } else if (projectClaimNetwork === 'ARB') {
          console.log('calling ARB')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.ARB)
        } else if (projectClaimNetwork === 'AVAX') {
          console.log('calling AVAX')
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.AVAX)
        } else if (projectClaimNetwork === 'BSC_TESTNET') {
          console.log('calling BSC_TESTNET')
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.BSC_TESTNET)
        }

        // initialize claim contract
        const claimContract = new ethers.Contract(
          claimContractAddress || sale.ido?.claimContract,
          ClaimNewAbi,
          provider,
        )

        // FIXME: check with Brian why SC don't have this method anymore
        // get decimals
        // let tokenDecimals = await claimContract.returnTokenDecimal()
        let tokenDecimals = 18 // FIXME: remove hard code, should call from SC

        // FIXME: check with Brian why SC don't have this method anymore
        // get claimable amount
        // let claimableAmount = await claimContract.getClaimableAmount(address)

        // // set claimable amount
        // setClaimableAmount(
        //   Number(
        //     ethers.utils.formatUnits(
        //       claimableAmount.toString(),
        //       Number(tokenDecimals),
        //     ),
        //   ),
        // )

        // FIXME: check with Brian why SC don't have this method anymore
        // let claimedAmount = await claimContract.claimedAmount(address)

        // get claimedAmount
        let claimedAmount = await claimContract.s_claimedAmount(
          sale.ido.project.vestingId,
          address,
        )

        // set claimed amount
        setClaimedAmount(
          Number(
            ethers.utils.formatUnits(
              claimedAmount.toString(),
              Number(tokenDecimals),
            ),
          ),
        )
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [sale?.ido?.claimContract, sale?.ido?.claimNetwork, address])

  const claimedTokenText = () => {
    if (!unifiedVestingClaimInfoStats?.claimedAmountBI) return 0

    return Number(unifiedVestingClaimInfoStats?.claimedAmountBI) < 0.01
      ? '<0.01'
      : Number(
          Number(unifiedVestingClaimInfoStats?.claimableAmountBI).toFixed(2),
        ).toLocaleString('en')
  }

  // info data claim

  useEffect(() => {
    const claimChainId: number | undefined = getChainIdFromName(
      sale?.ido?.project?.token?.network,
    )
    const claimContract = CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]
    const claimProjectId = Number(sale?.ido?.project?.vestingId)
    const isUnifiedVesting =
      claimContract &&
      claimChainId &&
      CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId] === claimContract

    if (!isUnifiedVesting) return
    if (!(claimProjectId >= 0)) {
      console.error('claimProjectId is not set')
      return
    }
    if (!address) {
      setUnifiedVestingClaimInfoStats(undefined)
      return
    }
    ;(async () => {
      const vestingUnifiedContract = {
        address: claimContract as `0x${string}`,
        abi: VESTING_UNIFIED_ABI_NEW,
      } as const

      const multicallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            ...vestingUnifiedContract,
            functionName: 'getAccountStatsAt',
            args: [claimProjectId, address],
          },
          {
            ...vestingUnifiedContract,
            functionName: 's_project',
            args: [claimProjectId],
          },
        ],
      })
      const [
        id,
        name,
        active,
        investors,
        merkleProofRoot,
        tgeAt,
        tgeAmount,
        cliffDuration,
        vestingDuration,
        vestingAmount,
        tokenAddr,
        tokenDeposited,
        tokenRemains,
        tokenDecimals,
        refundInvestors,
        refundDeadlineAt,
        refundTokenAddr,
        refundAmount,
        refundTokenDecimals,
        refundTokenDeposited,
        refundTokenRemains,
      ] = multicallResp?.[1]?.result

      const tokenMulticallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            address: tokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
          {
            address: refundTokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
        ],
      })

      const [
        refundRequestedAtBI,
        refundedAtBI,
        claimedAmountBI,
        claimableAmountBI,
      ] = multicallResp?.[0]?.result

      const unifiedVestingClaimInfoData = {
        refundRequestedAt: Number(refundRequestedAtBI),
        refundedAt: Number(refundedAtBI),
        claimedAmountBI: claimedAmountBI,
        claimableAmountBI: claimableAmountBI,
        tokenDeposited: tokenDeposited.toString(),
        tokenRemains: tokenRemains.toString(),
        allocationAmountBI: (tgeAmount + vestingAmount).toString(),
        tokenSymbol: tokenMulticallResp[0].result,
        tokenDecimals: tokenDecimals
      }
      setUnifiedVestingClaimInfoStats(unifiedVestingClaimInfoData)
    })()
  }, [
    address,
    sale?.ido?.project?.token?.network,
    sale?.ido?.project?.vestingId,
  ])

  return (
    <TableRow
      key={index}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#FF7722',
          td: {
            color: '#000',
          },
        },
      }}
      onClick={() => navigate(`/project/${sale.ido.project.name}`)}
    >
      {isDown800 ? (
        <FixedBodyTableCell>
          <img
            src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
            alt={sale.ido.project.name}
            style={{ width: 40, height: 40, borderRadius: 10 }}
          />
        </FixedBodyTableCell>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center">
            <img
              src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
              alt={sale.ido.project.name}
              style={{ width: 40, height: 40, borderRadius: 10 }}
            />
            <Box ml={'12px'}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              >
                {replaceUnderscoresWithSpaces(sale.ido.project.name)}
              </Typography>
              <Typography
                sx={{
                  color: '#767676',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.ido.project.token.symbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        {formatBigNumber(
          unifiedVestingClaimInfoStats?.claimableAmountBI || BigInt(0),
          unifiedVestingClaimInfoStats?.tokenDecimals,
        )}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {formatBigNumber(
          unifiedVestingClaimInfoStats?.tokenDeposited || BigInt(0),
          unifiedVestingClaimInfoStats?.tokenDecimals,
        )}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {formatBigNumber(
          unifiedVestingClaimInfoStats?.claimedAmountBI || BigInt(0),
          unifiedVestingClaimInfoStats?.tokenDecimals,
        )}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>N/A</StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '69px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            border: '1px solid #4fbb5a',
            backgroundColor: '#63EA71',
            color: '#000',
          }}
          onClick={e => {
            e.stopPropagation()
            navigate(`/project/${sale.ido.project.name}?claim=true`)
          }}
        >
          Claim
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

export default ClaimRow
