import { setUsedParticipationCode } from '../../../redux/slices/userSlice'
import { api } from '../../api'
import { IIdo } from '../project'
import { IUser } from '../users'

// export interface IParticipation {
//   _id: string
//   participator: IUser
//   ido: IIdo
//   invested: boolean
//   signature: string
//   amount: number
//   participationDate: number
//   investmentDate: number
//   solanaAddress: string
//   isWinner: boolean
// }
export interface IParticipation {
  _id: string
  participator: IUser
  ido: IIdo
  invested: boolean
  amount: number
  participationDate: number
  investmentDate: number
  referral: string
  solanaAddress: string
  signature: string
  isWinner: boolean
  isSnapshot: boolean
  ticketCount: number
  investorIndex: number
  isInvestor: boolean
  claimContract: string
  claimNetwork: string
}

export interface ITokenMetrics {
  _id: string
  TGE: number
  allocation: string
  allocationPercentage: number
  amount: number
  price: number
  raise: number
  vesting: string
}

export interface MakeParticipationPayload {
  projectId: string
  idoNumber: number
  code?: string
}

export interface ReferralCountResponse {
  count: number
  multiplier: number
}

export const participationApi = api.injectEndpoints({
  endpoints: build => ({
    // * For get requests
    fetchReferralCount: build.query<ReferralCountResponse, string | undefined>({
      query: idoId => `referrer/get-referrer-count/${idoId || ''}`,
    }),
    fetchUserAllParticipations: build.query<IParticipation[], void>({
      query: () => `participation/user/all/`,
    }),
    fetchUserPortfolioStats: build.query<any, void>({
      query: () => `participation/user/portfolio-stats/`,
    }),
    fetchTotalInvestments: build.query<
      IParticipation[],
      MakeParticipationPayload
    >({
      query: payload =>
        `participation/investments?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
    }),
    fetchTotalInvestedAmount: build.query<number, MakeParticipationPayload>({
      query: payload =>
        `participation/investmented-amount?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
    }),

    fetchUserTotalInvestedAmount: build.query<number, void>({
      query: () => `participation/user/invested-amount/`,
    }),

    fetchTotalAssetsConnected: build.query<number, MakeParticipationPayload>({
      query: payload =>
        `participation/total-assets-connected?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
      keepUnusedDataFor: 5,
    }),

    fetchTotalParticipants: build.query<number, MakeParticipationPayload>({
      query: payload =>
        `participation/ido/count?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
    }),

    fetchSingleParticipation: build.query<
      IParticipation | null,
      MakeParticipationPayload & { address: string }
    >({
      query: payload =>
        `participation?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}&address=${payload.address}`,
    }),

    fetchTotalParticipationsInIdo: build.query<
      IParticipation[],
      MakeParticipationPayload
    >({
      query: payload =>
        `participation/ido?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
    }),

    fetchUserWinningChances: build.query<
      number,
      MakeParticipationPayload & { address: string }
    >({
      query: payload =>
        `participation/user/winning-chances?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}&address=${payload.address}`,
    }),

    fetchIsUserWinner: build.query<
      boolean,
      MakeParticipationPayload & { address: string }
    >({
      query: payload =>
        `participation/user/is-winner?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}&address=${payload.address}`,
    }),

    // * For post requests
    participateInProject: build.mutation<
      IParticipation,
      MakeParticipationPayload
    >({
      query: payload => ({
        url: payload.code
          ? `participation?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}&code=${payload.code}`
          : `participation?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
        method: 'POST',
      }),
      async onQueryStarted(reqPayload, { dispatch, queryFulfilled }) {
        try {
          const abc = await queryFulfilled

          if (reqPayload?.code) {
            dispatch(
              setUsedParticipationCode(
                `${reqPayload?.projectId}:${reqPayload?.code}`,
              ),
            )
          }
        } catch (err) {
          console.error(err, 'could not set project id and referral code')
        }
      },
    }),

    updateParticipation: build.mutation<
      IParticipation,
      { invested: boolean; idoId: string }
    >({
      query: payload => ({
        url: `participation/update-participation?idoId=${payload.idoId}&invested=${payload.invested}`,
        method: 'PATCH',
      }),
    }),

    updateSolanaAddressInParticipation: build.mutation<
      IParticipation,
      {
        participationId: string
        solanaAddress: string
        signature: string
        message: string
      }
    >({
      query: payload => ({
        url: `participation/verify-investment-details/${payload.participationId}`,
        method: 'POST',
        body: {
          solanaAddress: payload.solanaAddress,
          signature: payload.signature,
          message: payload.message,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchReferralCountQuery,
  useFetchUserAllParticipationsQuery,
  useLazyFetchUserAllParticipationsQuery,
  useFetchUserTotalInvestedAmountQuery,
  useFetchTotalParticipationsInIdoQuery,
  useFetchTotalInvestedAmountQuery,
  useParticipateInProjectMutation,
  useUpdateParticipationMutation,
  useUpdateSolanaAddressInParticipationMutation,
  useFetchUserWinningChancesQuery,
  useFetchTotalAssetsConnectedQuery,
  useFetchSingleParticipationQuery,
  useFetchIsUserWinnerQuery,
  useLazyFetchSingleParticipationQuery,
  useFetchTotalParticipantsQuery,
  useFetchUserPortfolioStatsQuery,
} = participationApi
