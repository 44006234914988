import { Button, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Link, useNavigate } from 'react-router-dom'

type SubmenuProps = {
  name: string
  href: string
}

type Props = {
  img?: string
  text: string
  href: string
  textStyles?: any
  active?: boolean
  IconSrc?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  padding?: string
  IconSvg?: any
  target?: string
  submenu?: SubmenuProps[]
}

function NavItem({
  img,
  text,
  href,
  textStyles,
  active,
  IconSrc,
  padding,
  IconSvg,
  target,
  submenu,
}: Props) {

  const navigate = useNavigate();

  return (
    <div
      style={{
        textDecoration: 'none',
        position: 'relative',
      }}
      onClick={($evt: any) => {
        if (String(href).startsWith('https://')) {
          window.open(href, '_blank')
          $evt.preventDefault();
          return;
        }
        if (href) navigate(href);
      }}
      className="d-menu-outer-item"
    >
      <Button
        sx={{
          // backgroundColor: active ? '#171717' : 'transparent',
          // border: `0.5px solid  ${active ? 'rgba(255, 255, 255, 0.16)' : 'transparent'}`,
          // boxShadow: active ? '-70px 50px 80px 0px #6FEB7C08 inset' : 'none',
          padding: '0 12px',
          height: 34,
          borderRadius: '25px',
          width: 'auto',
          mb: 0,
          transition: 'all 0.2s',
          // '&:hover': { border: `0.5px solid ${active ? 'rgba(255, 255, 255, 0.16)' : 'transparent'}`,
          //   backgroundColor: '#171717',
          //   boxShadow: '-70px 50px 80px 0px #6FEB7C08 inset',
          // },
        }}
      >
        {IconSrc && <IconSrc sx={{ mr: 4 }} />}
        {IconSvg && (
          <IconSvg
            style={{
              height: '20px',
              width: '20px',
              fill: active ? '#FF7722' : '#F4FDAB',
            }}
          />
        )}

        {/* <img src={img} width={26} height={26} /> */}
        <Typography
          color="#000"
          fontSize={17}
          fontWeight={500}
          lineHeight={'22px'}
          fontFamily={'Inter'}
          textTransform={'none'}
        // ml={1}
        >
          {text}
        </Typography>
      </Button>
      {submenu && (
        <div
          className="d-menu-inner-item"
        >
          <div className="d-menu-items">
            {submenu.map((item: SubmenuProps) => {
              const { name, href } = item
              return (
                <a key={name} href={href}>
                  {name}
                </a>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default NavItem
