import { Box, Typography, useMediaQuery } from '@mui/material'
import { CompleteKYC, FilledBlackArrow, KYCPattern } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useNavigate } from 'react-router-dom'
import CircularProgess from './CircularProgess'
import KYCButton from '../../components/KYCBox/kycButton'

const WinAndKYCBox = ({ kycStatus }: any) => {
  const navigate = useNavigate()
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingY={isDown1000 ? '24px' : ''}
        paddingX={isDown1000 ? '24px' : '22%'}
      >
        {isDown1000 ? (
          kycStatus !== 'approved' ? (
            <img src={KYCPattern} alt="keep trying" />
          ) : (
            <CircularProgess percent={60} />
          )
        ) : kycStatus !== 'approved' ? (
          <img
            src={CompleteKYC}
            alt="keep trying"
            style={{ width: '64px', height: '64px' }}
          />
        ) : (
          <CircularProgess percent={60} />
        )}
        <Typography
          pt={isDown1000 ? (kycStatus ? '32px' : 0) : '32px'}
          marginTop={isDown1000 && kycStatus ? '0px' : '0px'}
          pb={'8px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#000'}
        >
          {kycStatus !== 'approved' ? 'Complete KYC' : 'You win!'}
        </Typography>
        <Typography
          fontFamily={'Inter'}
          fontSize={16}
          fontWeight={400}
          lineHeight={'24px'}
          color={'#7E8180'}
        >
          {kycStatus !== 'approved'
            ? 'To confirm your investment, you need to create an account & complete KYC. It will only take a few seconds.'
            : 'Invest fast to secure your spot!'}
        </Typography>

        {kycStatus !== 'approved' ? (
          <KYCButton component="participation" />
        ) : (
          <GreenButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            text="Start Investing"
            mt={5}
            onClick={() => {}}
            w="200px"
            IconStart={() => <img src={FilledBlackArrow} alt="filled arrow" />}
          />
        )}
      </Box>
    </Box>
  )
}

export default WinAndKYCBox
