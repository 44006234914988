import { api } from '../../api'

export interface IProject {
  readonly _id: string
  readonly token: IProjectToken
  readonly name: string
  readonly telegramId: string
  readonly website: string
  readonly introduction: string
  readonly chainId: number
  readonly previousSalesBreakdown: string
  readonly equityRaiseValuation: string
  readonly overview: string[]
  readonly viability: string
  readonly marketFocusAndCompetition: string[]
  readonly quaterlyRoadmap: string
  readonly revenueStreams: {
    heading: string[]
    data: { heading: string; text: string[] }[]
  }
  readonly whitepaper: string
  readonly socialCommunityLinks: string[]
  readonly creationDate: number
  readonly team: Record<string, any>
  readonly keyFeatures: string[]
  readonly contractId: number
  readonly privateKey: string
  readonly whatIs: string[]
  readonly timeline: {
    heading: string
    subHeading: string
    text: string[]
  }[]
  readonly logoUrl: string
  readonly timelineUrl: string
  readonly launched: boolean
  readonly projectImageUrl: string
  readonly tags: string[]
  // added on 22Nov'24
  readonly videoUrl?: string
}
export interface IProjectToken {
  readonly _id: string
  readonly symbol: string
  readonly totalSupply: number
  readonly fullyDilutedMarketCap: number
  readonly initialMarketCap: number
  readonly valuation: number
  readonly hardcap: number
  readonly metrics: TokenMetric[]
  readonly overviewLink: string
  readonly releaseScheduleLink: string
  readonly distributionLink: string
  readonly useOfProceeds: string
  readonly network: string
  readonly utility: {
    heading: string
    data: {
      heading: string
      text: string[]
    }[]
  }
  readonly platformRaise: string
}
export interface TokenMetric {
  allocation: string
  allocationPercentage: number
  amount: number
  price: number
  raise: number
  TGE: number
  vesting: string
}

// ido status enum
export enum IdoStatus {
  PARTICIPATION = 'Participation',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETED = 'Completed',
  PROCESSING = 'Processing',
  CANCELLED = 'Cancelled',
}

export interface IIdo {
  readonly _id: string
  readonly project: IProject
  readonly idoNumber: number
  readonly proofUrl: string
  readonly completed: boolean
  readonly totalParticipation: number
  readonly selectedForTransparency: boolean
  readonly idoWinners: string[]
  readonly startDate: number
  readonly endDate: number
  readonly investmentStartDate: number
  readonly investmentEndDate: number
  readonly status: IdoStatus
  readonly emailsSent: string[]
  readonly totalWinners: number
  readonly totalInvestors: number
  readonly amountToRaise: number
  readonly presaleTokenPrice: number
  readonly totalIssued: number
  readonly vestingAmounts: number[]
  readonly totalAssetsConnected: number

  readonly claimContract: string
  readonly claimNetwork: string
  readonly investmentNetwork: string
}
export interface InvestInProjectPayload {
  projectId: string
  idoNumber: number
}

export interface GetIdoPayload {
  projectId: string
  idoNumber: number
}

export interface UpdateIdoPayload
  extends Partial<Omit<IIdo, 'project' | '_id'>> {
  projectId: string
  idoNumber: number
}

export interface ILaunch {
  project: string | undefined
  _id: string
  bannerImageUrl: string
  logoUrl: string
  projectTitle: string
  description: string
  registrationStarted: boolean
  projectTags: string[]
  totalRaise: number
  startsIn: number
  registrationStartsDate: number
  saleType: string
  marketMaker: string
  active: boolean
  socialLinks: string[]
  type: LaunchType
  videoUrl?: string
  routeName: string
  registrationEndDate: string
  order: number
  backers?: string[]
  highlights?: string
  hostedBy?: string
}

export type LaunchType =
  | 'upcoming'
  | 'featured'
  | 'coming-soon'
  | 'completed'
  | 'test'

export const projectApi = api.injectEndpoints({
  endpoints: build => ({
    // * For get requests
    fetchOneProject: build.query<IProject, string>({
      query: id => `project/${id}`,
    }),

    fetchOneProjectByName: build.query<IProject, string>({
      query: name => `project/get-project-by-name/${name}`,
    }),
    
    fetchOneProjectById: build.query<IProject, string>({
      query: id => `project/get-project-by-id/${id}`,
    }),

    fetchOneIdo: build.query<IIdo, GetIdoPayload>({
      query: payload => ({
        url: `project/ido?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
      }),
    }),
    fetchAllProjects: build.query<IProject[], void>({
      query: () => `project`,
    }),

    fetchAllIDOs: build.query<IIdo[], void>({
      query: () => `/project/idos`,
    }),

    fetchAllLaunches: build.query<ILaunch[], void>({
      query: type => `launch`,
    }),
    fetchLaunchpadPublicStats: build.query<ILaunch[], void>({
      query: type => `/stats/get-launchpad-public-stats`,
    }),

    fetchTransparencyProjects: build.query<IIdo[], void>({
      query: () => `project/getProjectWinnersForTransparency`,
    }),
    fetchProjectIdos: build.query<IIdo[], string>({
      query: projectId => `project/ido/${projectId}`,
    }),

    fetchIsEmailSent: build.query<boolean, GetIdoPayload>({
      query: payload => ({
        url: `project/ido/emails/is-sent?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
      }),
    }),

    fetchInvestMerkleProof: build.query<string[], InvestInProjectPayload>({
      query: payload => ({
        url: `project/ido/merkle-tree/get-proof?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
      }),
    }),

    fetchClaimMerkleProof: build.query<string[], InvestInProjectPayload>({
      query: payload => ({
        url: `project/ido/merkle-tree/get-claim-proof?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
      }),
    }),
    fetchAdminProject: build.query<any, string>({
      query: projectId => ({
        url: `http://localhost:3005/api/project/admin/ido?projectId=${projectId}&idoNumber=0&adminKey=jwk24124_421buuus_321uf_ape`,
      }),
    }),

    // * For post requests
    investInProject: build.mutation<
      { signature: string },
      InvestInProjectPayload
    >({
      query: payload => ({
        url: `project/invest?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
        method: 'POST',
      }),
    }),

    claimSignature: build.mutation<{ claimSignature: string }, GetIdoPayload>({
      query: payload => ({
        url: `project/get-claim-signature?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
        method: 'PATCH',
      }),
    }),

    getClaimFinalHash: build.mutation<any, any>({
      query: payload => ({
        url: `project/claim-final-hash`,
        method: 'POST',
        body: payload,
      }),
    }),
    getClaimFinalHashes: build.mutation<any, any>({
      query: payload => ({
        url: `project/claim-final-hashes`,
        method: 'POST',
        body: payload,
      }),
    }),

    updateIdoOfProject: build.mutation<IIdo, UpdateIdoPayload>({
      query: payload => ({
        url: `project/ido?projectId=${payload.projectId}&idoNumber=${payload.idoNumber}`,
        method: 'PATCH',
        body: payload,
      }),
    }),

    getFinalHash: build.mutation<any, any>({
      query: payload => ({
        url: `project/final-hash`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchOneProjectQuery,
  useLazyFetchOneProjectQuery,

  useFetchOneProjectByNameQuery,
  useFetchOneProjectByIdQuery,
  useFetchAllProjectsQuery,
  useFetchOneIdoQuery,
  useFetchAllIDOsQuery,
  useFetchTransparencyProjectsQuery,

  useFetchAdminProjectQuery,
  useFetchAllLaunchesQuery,
  useFetchLaunchpadPublicStatsQuery,

  useInvestInProjectMutation,
  useUpdateIdoOfProjectMutation,
  useGetFinalHashMutation,

  useClaimSignatureMutation,

  useGetClaimFinalHashMutation,
  useGetClaimFinalHashesMutation,
  useFetchClaimMerkleProofQuery,

  useFetchProjectIdosQuery,

  useFetchIsEmailSentQuery,
  useFetchInvestMerkleProofQuery,
} = projectApi
