import { useIsKycVerifiedQuery } from '../services/modules/users'
import { useAppSelector } from './index'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setKycStatus } from '../redux/slices/userSlice'
import { useAccount } from 'wagmi'

const useGetUserKyc = () => {
  const { address } = useAccount()

  const dispatch = useDispatch()
  const kycStatus = useAppSelector(state => state.user.kycStatus)

  // const refId = email + account

  // useEffect(() => {
  //   if (address && !email) {
  //     setPayload(address)
  //   } else if (address && email) {
  //     setPayload(email + address)
  //   }
  // }, [address, email])

  const { data, isLoading } = useIsKycVerifiedQuery(address!, {
    pollingInterval: 1500000,
    skip: !address,
  })

  useEffect(() => {
    if (data && address) {
      dispatch(setKycStatus(data.kycStatus))
    }
    if (!address) {
      dispatch(setKycStatus('not-logged'))
    }
  }, [address, data, dispatch])

  return { userKycStatus: kycStatus, isLoading }
}

export default useGetUserKyc
