import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AffiliateStatus } from '../../../services/modules/affiliate'

interface UserState {
  email: string
  web3AuthToken: string
  appPubKey: string
  isLoggedIn: boolean
  mainAppToken: string
  profilePicture?: string
  pageLoaded: boolean
  elligibleForGiftCard: boolean
  signature: string
  kycStatus: string
  walletAddress: string
  refferCode: string
  primaryReferralCodes: string[]
  usedParticipationCode: { [key: string]: boolean }
  affiliateStatus: AffiliateStatus
  isLoggedInWithEmail: boolean
  googleToken: string
  nameGoogleToken: string
  addressLinkEmail: string
  emailRegisterUserAccount: string
  addressRegisterUserAccount: string
  loginMethod: string;
  accessTokenGoogle: string;
}

const initialState: UserState = {
  email: '',
  web3AuthToken: '',
  appPubKey: '',
  isLoggedIn: false,
  mainAppToken: '',
  profilePicture: undefined,
  pageLoaded: false,
  elligibleForGiftCard: false,
  signature: '',
  kycStatus: 'incomplete',
  walletAddress: '',
  refferCode: '',
  primaryReferralCodes: [],
  usedParticipationCode: {},
  affiliateStatus: AffiliateStatus.NOT_APPLIED,
  isLoggedInWithEmail: false,
  googleToken: '',
  nameGoogleToken: '',
  addressLinkEmail:'',
  emailRegisterUserAccount:'',
  addressRegisterUserAccount:'',
  loginMethod: '',
  accessTokenGoogle: ''
}

interface Web3AuthDataPayload {
  token: string
  email: string
  isLoggedin: true
  profilePictureUrl: string
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSignature: (state, action: PayloadAction<string>) => {
      state.signature = action.payload
    },
    setPageLoaded: (state, action: PayloadAction<boolean>) => {
      state.pageLoaded = action.payload
    },
    setUserState: (state, action: PayloadAction<UserState>) => {
      state = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setWeb3AuthToken: (state, action: PayloadAction<string>) => {
      state.web3AuthToken = action.payload
    },
    setAppPubKey: (state, action: PayloadAction<string>) => {
      state.appPubKey = action.payload
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setMainAppToken: (state, action: PayloadAction<string>) => {
      state.mainAppToken = action.payload
    },
    setGiftCardElligibility: (state, action: PayloadAction<boolean>) => {
      state.elligibleForGiftCard = action.payload
    },
    setProfilePictureUrl: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.profilePicture = action.payload
    },
    setKycStatus: (state, action: PayloadAction<string>) => {
      state.kycStatus = action.payload
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.walletAddress = action.payload
    },
    setAffiliateStatus: (state, action: PayloadAction<AffiliateStatus>) => {
      state.affiliateStatus = action.payload
    },
    setRefferCode: (state, action: PayloadAction<string>) => {
      state.refferCode = action.payload
    },
    setPrimaryReferralCode: (state, action: PayloadAction<string>) => {
      // check if the code is already added or not
      if (!state.primaryReferralCodes.includes(action.payload)) {
        state.primaryReferralCodes = [
          ...state.primaryReferralCodes,
          action.payload,
        ]
      }
    },
    setUsedParticipationCode: (state, action: PayloadAction<string>) => {
      state.usedParticipationCode = {
        ...state.usedParticipationCode,
        [action.payload]: true,
      }
    },

    setWeb3AuthData: (state, action: PayloadAction<Web3AuthDataPayload>) => {
      state.web3AuthToken = action.payload.token
      state.email = action.payload.email
      state.isLoggedIn = action.payload.isLoggedin
      state.profilePicture = action.payload.profilePictureUrl
    },
    setIsLoggedInWithEmail: (state, action: PayloadAction<boolean>) => {
      state.isLoggedInWithEmail = action.payload
    },
    setGoogleToken: (state, action: PayloadAction<string>) => {
      state.googleToken = action.payload
    },
    setNameGoogleToken: (state, action: PayloadAction<string>) => {
      state.nameGoogleToken = action.payload
    },
    setAddressLinkEmail: (state, action: PayloadAction<string>) => {
      state.addressLinkEmail = action.payload
    },
    setEmailRegisterUserAccount: (state, action: PayloadAction<string>) => {
      state.emailRegisterUserAccount = action.payload
    },
    setAddressRegisterUserAccount: (state, action: PayloadAction<string>) => {
      state.addressRegisterUserAccount = action.payload
    },
    setLoginMethod: (state, action: PayloadAction<string>) => {
      state.loginMethod = action.payload
    },
    setAccessTokenGoogle: (state, action: PayloadAction<string>) => {
      state.accessTokenGoogle = action.payload
    },
    
    logout: state => {
      localStorage.removeItem('openlogin_store')
      state.isLoggedIn = false
      state.appPubKey = ''
      state.email = ''
      state.web3AuthToken = ''
      state.profilePicture = ''
      state.mainAppToken = ''
      state.elligibleForGiftCard = false
      state.kycStatus = 'incomplete'
      state.signature = ''
      state.walletAddress = ''
      state.refferCode = ''
      state.affiliateStatus = AffiliateStatus.NOT_APPLIED
      state.isLoggedInWithEmail = false
      state.googleToken = ''
      state.nameGoogleToken = ''
      state.addressLinkEmail = ''
      state.emailRegisterUserAccount = ''
      state.loginMethod = ''
      state.accessTokenGoogle = ''
    },
  },
})

export const {
  setUserState,
  setEmail,
  setWeb3AuthToken,
  setAppPubKey,
  setIsLoggedIn,
  logout,
  setProfilePictureUrl,
  setMainAppToken,
  setPageLoaded,
  setGiftCardElligibility,
  setSignature,
  setKycStatus,
  setWalletAddress,
  setRefferCode,
  setPrimaryReferralCode,
  setUsedParticipationCode,
  setAffiliateStatus,
  setIsLoggedInWithEmail,
  setGoogleToken,
  setNameGoogleToken,
  setAddressLinkEmail,
  setEmailRegisterUserAccount,
  setAddressRegisterUserAccount,
  setLoginMethod,
  setAccessTokenGoogle,
} = userSlice.actions

export default userSlice.reducer
