import React, { useMemo } from 'react'
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect'
import { TrustWalletAdapter } from '@solana/wallet-adapter-trust'
// import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger'
// import { NufiWalletAdapter } from '@solana/wallet-adapter-nufi';
import { KeystoneWalletAdapter } from '@solana/wallet-adapter-keystone'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase'
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare'
import { WALLET_CONNECT_PROJECT_ID } from '../constant'

import '@solana/wallet-adapter-react-ui/styles.css'

export const SolanaContextWrapper = ({children}: {children: React.ReactNode}) => {
  const network = WalletAdapterNetwork.Mainnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  // https://github.com/anza-xyz/wallet-adapter/blob/master/PACKAGES.md
  const wallets = useMemo(
    () => [
      new WalletConnectWalletAdapter({
				network: WalletAdapterNetwork.Mainnet,
				options: {
					projectId: WALLET_CONNECT_PROJECT_ID,
				},
			}),
      new TrustWalletAdapter(),
      // new LedgerWalletAdapter(),
      // new NufiWalletAdapter(),
      new PhantomWalletAdapter(),
      new KeystoneWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SolflareWalletAdapter(
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  )

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        {/* <WalletMultiButton />
        <WalletDisconnectButton /> */}
        <WalletModalProvider>
          {children}
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
