import React, { useMemo, FC } from 'react'
import Router from '../../components//Router'
import type { Account, Chain, Client, Transport } from 'viem'
import {
  arbitrum,
  bsc,
  goerli,
  mainnet,
  avalanche,
  sepolia,
  polygon,
  base,
  arbitrumSepolia,
  bscTestnet,
} from 'viem/chains'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { providers } from 'ethers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http, createConfig, WagmiProvider } from 'wagmi'
import { walletConnect, injected, coinbaseWallet } from 'wagmi/connectors'
import { Config, useConnectorClient } from 'wagmi'
import { SolanaContextWrapper } from '../../vendors/SolanaContext'
import { WALLET_CONNECT_PROJECT_ID, IS_DEV_MODE } from '../../constant'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 1 * 1000 * 60, // 1 Min
    },
  },
})

const projectId = WALLET_CONNECT_PROJECT_ID

const metadata = {
  name: 'Ape Launchpad',
  description:
    "Ape Terminal is the fastest growing IDO launchpad with the crypto industry's greatest yield generating tools.",
  url: window.location.origin, // origin must match your domain & subdomain
  icons: [
    'https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/45099fef-bfa0-4ab7-31f3-f78f78efbc00/lg',
  ],
}

export const wagmiConfig = defaultWagmiConfig({
  chains: [mainnet, bsc, arbitrum, avalanche, polygon, base, ...(IS_DEV_MODE ? [arbitrumSepolia, bscTestnet] : [])],
  projectId,
  metadata,
  // transports: {
  //   [mainnet.id]: http(),
  //   [goerli.id]: http(),
  //   [bsc.id]: http(),
  //   [arbitrum.id]: http(),
  //   [avalanche.id]: http(),
  //   [sepolia.id]: http(),
  // },
  // connectors: [
  //   walletConnect({ projectId, metadata, showQrModal: false }),
  //   injected({ shimDisconnect: true }),
  //   coinbaseWallet({
  //     appName: metadata.name,
  //     appLogoUrl: metadata.icons[0],
  //   }),
  // ],
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

const Web3Provider = () => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <SolanaContextWrapper>
          <Router />
        </SolanaContextWrapper>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  try {
    const { account, chain, transport } = client
    const network = {
      chainId: chain?.id,
      name: chain?.name,
      ensAddress: chain?.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network)
    const signer = provider.getSigner(account.address)
    return signer
  } catch (e) {
    console.error(e)
    return undefined
  }
}

/** Action to convert a Viem Client to an ethers.js Signer. */
// https://wagmi.sh/react/guides/ethers
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId })
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client])
}

export default Web3Provider
