// import ConnectWalletModal from '../../components//Modals/ConnectWalletModal'
import { Box, Button, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  getChainCoinNameByChainId,
  shortenAddress,
  truncateExact,
} from '../../utils/'
import { ethers } from 'ethers'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import { useAccount, useBalance, usePublicClient } from 'wagmi'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'
import { ClipboardIcon, BNBIcon } from '../../assets'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../redux/slices/themeSlice'
import CopyToClipboard from 'react-copy-to-clipboard'
import getIconByNetwork from '../../pages/RevampedOverview/IconByNetwork'
import toast from 'react-hot-toast'
import { useAppSelector } from '../../hooks'

interface BalanceAndAddressBoxProps {
  sx?: any
}

const BalanceAndAddressBoxContainer = styled(Button)(() => ({
  height: 40,
  padding: '0 8px',
  backgroundColor: 'rgba(15, 17, 19, 1)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'max-content',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: 'rgba(15, 17, 19, 1)',
    opacity: 0.8,
  },
}))

const Divider = styled(Box)(() => ({
  width: '0.5px',
  height: 16,
  backgroundColor: 'rgba(49, 51, 53, 1)',
  margin: '0 10px',
}))

const BalanceAndAddressBox: React.FC<BalanceAndAddressBoxProps> = ({ sx }) => {
  const { address, chain } = useAccount()
  const provider = usePublicClient({ chainId: chain?.id })
  const user = useAppSelector(state => state.user)
  const IconComponent = chain ? getIconByNetwork(chain?.name) : null

  console.log('wagmi => ', { address, chain, provider })

  const [userBalance, setUserBalance] = useState('')

  const { data: ethBalanceData, isError: ethBalanceError } = useBalance({
    address,
  })

  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)

  const [showDisconnectWalletModal, setShowDisconnectWalletModal] =
    useState(false)

  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  console.log('showConnectWalletModal', ethBalanceData)
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const balance = address && provider ? 0 : 0

      setUserBalance(ethers.utils.formatEther(balance.toString()).toString())
    })()
  }, [provider, address])

  const handleClick = () => {
    if (address || user.email) {
      setShowDisconnectWalletModal(true)
      //disconnectWalletAndProfile()
    } else setShowConnectWalletModal(true)
  }

  // function disconnectWallet() {
  //   console.log('disconnect wallet')
  //   sessionStorage.removeItem('connection-type')
  //   sessionStorage.removeItem('account')
  //   sessionStorage.removeItem('signature')

  //   dispatch(setSignature(''))
  //   dispatch(onlogout())

  //   connector.deactivate && connector.deactivate()
  //   connector.resetState && connector.resetState()
  // }

  return (
    <BalanceAndAddressBoxContainer
      onClick={handleClick}
      sx={{ ...sx }}
      // onClick={() => {
      //   const connectionType = sessionStorage.getItem('connection-type')

      //   console.log('connectionType', connectionType)

      //   if (connectionType) {
      //     const connector = getConnection(
      //       connectionType as ConnectionType,
      //     ).connector

      //     console.log('connector', connector)

      //     connector && connector.deactivate && connector.deactivate()
      //   }
      // }}
    >
      {IconComponent ? (
        <IconComponent
          height={'20px'}
          width={'20px'}
          style={{ marginRight: '4px' }}
        />
      ) : (
        <img
          src={BNBIcon}
          style={{ marginRight: '4px', height: '20px' }}
          alt="Chain Icon"
        />
      )}
      <Typography
        color="rgba(255, 255, 255, 1)"
        fontWeight={500}
        fontSize={14}
        fontFamily={'Inter'}
      >
        {truncateExact(parseFloat(ethBalanceData?.formatted || '0') || 0, 4)}{' '}
        {getChainCoinNameByChainId(chain?.id ? chain?.id : 56)}
      </Typography>

      {address && (
        <>
          <Divider />
          <Typography
            fontWeight={400}
            fontSize={14}
            fontFamily={'Inter'}
            color={'rgba(118, 118, 118, 1)'}
            sx={{
              // on hover
              '&:hover': {
                color: '#63ea71',
              },
            }}
          >
            {shortenAddress(address!, 5, 4)}
          </Typography>
        </>
      )}

      {address && (
        <CopyToClipboard
          text={address!}
          onCopy={() => toast.success('Copied')}
        >
          <img
            src={ClipboardIcon}
            style={{ marginLeft: '4px', height: '16px' }}
            onClick={e => {
              e.stopPropagation()
            }}
            alt="Clipboard Icon"
          />
        </CopyToClipboard>
      )}

      {(user.email || user.emailRegisterUserAccount) && (
        <>
          <Divider />
          <Typography
            fontWeight={400}
            fontSize={16}
            fontFamily={'Inter'}
            color={'rgba(118, 118, 118, 1)'}
            sx={{
              // on hover
              '&:hover': {
                color: '#63ea71',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBlockStart: 0,
              textTransform: 'lowercase',
              marginBlockEnd: 0,
            }}
          >
            {shortenAddress(user.email || user.emailRegisterUserAccount, 5, 4)}
          </Typography>
        </>
      )}
      {/* <ConnectWalletModal
        open={showConnectWalletModal}
        setOpen={setShowConnectWalletModal}
        wallets={[ConnectionType.INJECTED, ConnectionType.WALLET_CONNECT]}
      /> */}

      <DisconnectWalletModal
        open={showDisconnectWalletModal}
        setOpen={setShowDisconnectWalletModal}
      />
    </BalanceAndAddressBoxContainer>
  )
}

export default BalanceAndAddressBox
