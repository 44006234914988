export enum ESocketEvent {
  LOGIN = 'LOGIN',
  TROLL = 'TROLL',
  TROLL_RESPONSE = 'TROLL_RESPONSE',
  LOGIN_RESPONSE = 'LOGIN_RESPONSE',
  BROADCAST = 'BROADCAST',
  EVENT_REGISTRATION = 'EVENT_REGISTRATION',
  EVENT_CONTRIBUTION = 'EVENT_CONTRIBUTION',
  RESPONSE_EVENT_CONTRIBUTION = 'RESPONSE_EVENT_CONTRIBUTION',
}

export enum TypeNotiEvent {
  CONTRIBUTION = 'CONTRIBUTION',
  REGISTRATION = 'REGISTRATION',
}

export const events: ESocketEvent[] = [
  ESocketEvent.BROADCAST,
  ESocketEvent.EVENT_REGISTRATION,
  ESocketEvent.RESPONSE_EVENT_CONTRIBUTION,
]

export type EventData = IDataEventBroadCast | IDataEventIDO | any
export interface IDataEventBroadCast {
  title: string
  content: string
  time: number
}

export interface IDataEventIDO {
  endRegistration: number
  projectId: string
  projectName: string
  routeName: string
  startRegistration: number
  time: number
  projectLogo: string
  type: string
  investmentStartDate: string
}
