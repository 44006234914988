import React from 'react'
import { Box, Typography, Stack, Link } from '@mui/material'
import CallMade from '@mui/icons-material/CallMade'
import moment from 'moment'
import {
  INotificationHistoryResponse,
  TypeNoti,
} from '../../services/modules/notification'
import { Logo } from '../../assets'

const NotificationItem = ({
  notification,
  isLogin,
  expandedNotification,
  handleNavigate,
  handleMarkAsRead,
  handleExpandNotification,
}: {
  notification: INotificationHistoryResponse
  isLogin: boolean
  expandedNotification: string | null
  handleNavigate: (route: string) => void
  handleMarkAsRead: (id: string) => void
  handleExpandNotification: (id: string) => void
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (isLogin && !notification.isRead) {
      handleMarkAsRead(notification._id)
    }

    if (notification.routeName) {
      handleNavigate(notification.routeName)
    } else {
      handleExpandNotification(notification._id)
    }
  }

  const renderLogo = () => {
    if (notification?.project?.logoUrl) {
      return (
        <img
          src={notification.project.logoUrl}
          alt="logo"
          style={{
            width: 56,
            height: 56,
            borderRadius: '12px',
            objectFit: 'contain',
            border: '0.5px solid #1D1F21',
          }}
        />
      )
    }

    return (
      <Box
        sx={{
          border: '0.5px solid #1D1F21',
          borderRadius: '12px',
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            width: 40,
            height: 40,
            objectFit: 'contain',
          }}
        />
      </Box>
    )
  }

  const renderContent = () => {
    if (notification.type === TypeNoti.REGISTRATION) {
      return <SaleRegistrationContent notification={notification} onClick={handleClick} />
    }

    if (notification.type === TypeNoti.CONTRIBUTION) {
      return <ContributionContent notification={notification} onClick={handleClick} />
    }

    return (
      <Typography
        sx={{
          fontWeight: 400,
          color: expandedNotification === notification._id ? '#000' : '#767676',
          fontFamily: 'Inter',
          fontSize: '14px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '8px',
          wordBreak: 'break-word',
          display:
            expandedNotification === notification._id ? 'block' : '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp:
            expandedNotification === notification._id ? 'none' : 2,
        }}
      >
        {notification.content}
      </Typography>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        padding: 2,
        background: notification.isRead ? 'unset' : '#ffffff',
        cursor: 'pointer',
        gap: 2,
        '&:hover': { background: 'rgba(230, 247, 255, 0.8)' },
        position: 'relative',
        zIndex: 10,
        margin: '1px 0',
        borderBottom: '0.5px solid rgba(0,0,0,0.2)',
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Box
          sx={{
            background: notification.isRead ? 'transparent' : '#2E70E8',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
          }}
        />
        {renderLogo()}
      </Box>

      <Stack sx={{ width: '100%', overflow: 'hidden' }}>
        {notification.title && (
          <Typography
            sx={{
              fontWeight: 700,
              color: '#000000',
              fontFamily: 'Inter',
              fontSize: '14px',
              marginBottom: '8px',
              textOverflow: 'ellipsis',
              display:
                expandedNotification === notification._id
                  ? 'block'
                  : '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflow: 'hidden',
            }}
          >
            {notification.title}
          </Typography>
        )}
        {renderContent()}
        {notification.url && expandedNotification === notification._id && (
          <Link
            target="_blank"
            href={notification.url}
            sx={{
              fontSize: 14,
              marginBottom: '6px',
              color: '#767676',
              '&:hover': { color: '#000' },
            }}
          >
            More detail{' '}
            <CallMade sx={{ fontSize: 14, verticalAlign: 'text-bottom' }} />
          </Link>
        )}
        {notification.time && (
          <Typography
            sx={{
              fontWeight: 400,
              color: '#767676',
              fontFamily: 'Inter',
              fontSize: '14px',
            }}
          >
            {moment(notification.time).format('DD/MM/YYYY HH:mm')}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export default NotificationItem

const ContributionContent = ({ notification }: any) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        Contributions Round Starting
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        {(() => {
          const now = moment()
          const investmentStartDate = moment(notification?.investmentStartDate)

          if (investmentStartDate.isAfter(now)) {
            const duration = moment.duration(investmentStartDate.diff(now))
            const days = Math.floor(duration.asDays())
            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()

            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{notification?.project?.name}</b>{' '}
                  contributions start in {days > 0 ? `${days}d` : ''} {hours}h{' '}
                  {minutes}m {seconds}s. You registered and won a spot on this
                  sale. Don’t forget to contribute.
                </Typography>
              </Box>
            )
          } else {
            return (
              <Typography
                sx={{
                  fontWeight: 400,
                  color: '#767676',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                }}
              >
                <b style={{ color: '#000' }}>{notification?.project?.name}</b>{' '}
                contributions start now. You registered and won a spot on this
                sale. Don’t forget to contribute.
              </Typography>
            )
          }
        })()}
      </Typography>
    </>
  )
}

const SaleRegistrationContent = ({ notification }: any) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        Sales Registration Starting
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          marginBottom: '8px',
          // display:
          //   expandedNotification === notification._id
          //     ? 'block'
          //     : '-webkit-box',
          // WebkitBoxOrient: 'vertical',
          // WebkitLineClamp:
          //   expandedNotification === notification._id
          //     ? 'none'
          //     : 3,
        }}
      >
        {(() => {
          const startRegistration = moment(notification?.startRegistration)
          const endRegistration = moment(notification?.endRegistration)
          if (endRegistration.isAfter(startRegistration)) {
            const duration = moment.duration(
              endRegistration.diff(startRegistration),
            )
            const days = Math.floor(duration.asDays())
            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()

            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{notification?.project?.name}</b>{' '}
                  sales registration opt in is upcoming in{' '}
                  {days > 0 ? `${days}d` : ''} {hours}h {minutes}m {seconds}s.
                  Don’t miss out!
                </Typography>
              </Box>
            )
          } else {
            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{notification?.project?.name}</b>{' '}
                  sales registration start now . Don’t miss out!
                </Typography>
              </Box>
            )
          }
        })()}
      </Typography>
    </>
  )
}
