import { ethers } from 'ethers'
import _ from 'lodash'

const pr0x1edVestingAddress: Record<string, string> = {
  // z k a s i n o
  '0xa27445dea339dc70914bee07fda7bf8f1a5bea9eebbf66d27dbf348bb342ca2f': atob(
    'MHgwNmYzYzc5MTM3RDNCQTMyQmQ4MzA0MTZmOUE4NDg3NGUxMzQ5RDBC',
  ),
  // e v e r m o o n
  '0x2a92dcf136933f2ce453e5528de489cc9a4152794861f461368c5314f7b86eff': atob(
    'MHhhZTAzMTFkRWFGMDlDOGYwRjVjQjYyM0NDZEY3NjRCRDhhZTA2OGY5',
  ),
  // e e s e e
  '0xcee2ffd24d33748309909d8f0e6738b8db211a07d4e9e28dd487198913976e7d': atob(
    'MHgzM2IyMzk4ODU0NjZiM2E1NzkxYkZBYjcwMjM3NTI5NTA3ODREMTIy',
  ),
  // c a t a m o t o
  // n/a
}

// export const us3rByVestingAddress = {
//   // z k a s i n o
//   '0xa27445dea339dc70914bee07fda7bf8f1a5bea9eebbf66d27dbf348bb342ca2f': _.keys({
//   }).map(ethers.utils.id),
//   // e v e r m o o n
//   '0x2a92dcf136933f2ce453e5528de489cc9a4152794861f461368c5314f7b86eff': _.keys({
//   }).map(ethers.utils.id),
//   // e e s e e
//   '0xcee2ffd24d33748309909d8f0e6738b8db211a07d4e9e28dd487198913976e7d': _.keys({
//   }).map(ethers.utils.id),
// }

const us3rByVestingAddress: Record<string, string[]> = {
  // z k a s i n o
  '0xa27445dea339dc70914bee07fda7bf8f1a5bea9eebbf66d27dbf348bb342ca2f': [
    '0x62c82d5240b438bd6b1423241658592cab32035a1f37ea70aa1fbc76801656d9',
    '0xc9a50bee32462d8535b927ed7b61fb1f0bba391b70f5920b8e0a590bed790d9d',
    '0x9728b265acbaf91f7edfd289082e391230274687b2549be360d71099b6d1ad3c',
    '0x53e01a5761e0fdbd26f50a5f8df7e3b15ca872677a28e1886c1505b46a2bf36b',
    '0xe5b4ee43f53c742bdb4a1fdc829ef443d9b28dfaf0004ca4349ee2aa97db64b2',
    '0x55b69cdbc633ff3ee7e11f45c25a38bcf04c82e9282b7d014bdf0701a9f5f0eb',
    '0xc69f8f81f9d9ed136b450a5deb68b9cb6e13f5986d3ccad0063823100c2205e1',
    '0xfa255312a97322e7f2c12b6e258027677322289ccaea3f42f2c9e2f5cbbd06f4',
    '0xaba75944905f489028ff4df24e5b02630d1dbbcc67e667dcb1a85ce3913b8a38',
    '0x7a03b03416a7cf66020265c8ccdd26d9c738ba42879c97b619b5a463e7df146e',
    '0x10d24eb594386a6cada6d9c02d34a7afaca5eef35ea003488e45674b7aaa229b',
    '0xbfc18595b472744ebf8777131ddce8da1a7efff6b4bf8188d2f75be108564509',
    '0x499ff76f979af1cbb48847e4a403fcd0deb4977a175e50ca22d716818b6081a1',
    '0x5978055415a38bfc0eaef98e45dbaf54e90bfe5a6e938d3a32bcf02d0e134c14',
  ],
  // e v e r m o o n
  '0x2a92dcf136933f2ce453e5528de489cc9a4152794861f461368c5314f7b86eff': [
    '0x85d1e39ffea3848b184341c89bbb6169f2738c52a72243e307e10fad61f3a32b',
    '0x8f68d877a7d0cd9bfe568f1300ae12da5f3ca7f603345f779a55044e396f18c7',
    '0x0ea31855b7a70102666b1147ea39bbf7f14d23e62e5be3fc3049d54093234308',
    '0x621a6be276a3aade65900be49af831d2ac98a1b16b0686f5e55468a203664fb8',
    '0xd64bdd086f4ad63834b3956ed92438d2f6a351288b675aca5d7b16a7c2096324',
    '0x9117a22d6d74dffe4d8e30cf55acd87a1341a4e44251e98c67092e6e4b83edcc',
    '0x8f459ab26fb828d9a63bab4d6b5bc3da2c042652f8c3b5008e9d7d208c1abc18',
    '0x58a0e3753c2026e255fe8e8980ded631eb83ed91ffe2d4a7a14ef4384bec55a1',
    '0x0d4e9976411776beec091e953f977a6e23f2f89cd660aef77b3135e47a20d685',
    '0x79767140e48b6916eb061304a354f8086241285193f3696e86de2a75393ae596',
    '0x9d7525be93e272e1dd080765fc4f143d046b600eb54a64547823e49dbb50def3',
    '0x90fcbf1158e3aa3015a6efbcd1e77d9d033e6e1810979857ac8cf2057cb8df79',
    '0xe597614afc78d0d36dcfcdf891cca5b0bfdf56e1c89a63d4ff785df61523ce42',
    '0xcd065047f94e14d8417fbc9f2c5a756cb9df164debd68daf94c24c66f21f6a13',
    '0x487929e375b5bad3ad60498fc06b343486dd4746622283accdcfd2ddb3ee8657',
    '0xe911df08831174070f12db3b4fcd68df909ef3e0e9cb3736c8050a080988a2f3',
    '0xbccca1a35ca4978bea4d43e8083de6489ce99cd21c60f789337442c5590a6cfd',
    '0xbdf4b094a19c4cd0eaf982bcc7f1ae300af06b8b4ef75a1df6c66d748ea19b5e',
    '0xbd0e28e8e27cde35d74b7ab05089c6d0eedae3ec2460f220576ffc0af3524d3c',
    '0x3003e5af3e34b3a43071958005dc73bc3a953d8d8d5a581aa5bbdfca819fbb44',
    '0x564f39bf35492cf7628b2ec45cc442b2cc4b9b92780d2f04006787c33d7359c3',
    '0xe93edbd0b658800dc7447928691f306ba9f1359cee98e2a484e87208fe99db97',
    '0x17545df5c8756f9337374f8e416ba4d50eb17bd062346e566a41ce76d50251a6',
    '0x59d6696be02868e88eb5d4c4af9ea70224fce8a86c83e934d4de8b03f0739ebf',
    '0xcdeb371745fc5d0af19d4d644cbc000bb6642e138ec56ae2c23e1514c048d342',
    '0x9224344bb9002ee1f5ca4a049e57fcb393cff6181551c621e5f16a1a40667e22',
    '0x47c25dc95dd4cf46d10b4f266f886367e62afc83349c84d51c004fda3deb8946',
    '0xf10d3b236199e693a9728c3a6eed86dfc9c8e2e3322cb47bca7a8a67239cced2',
    '0x84a19c4dc1b6352202660598ac9a58f5ed75f2c16bbe13244175ab4f74cdf283',
    '0xe2c8d811a36fc43ef8e1014935c069bcbb4a090f85303987366be642b03b6d10',
    '0xc660e9474648a6d0353c6ec0e8bf6da03b53f997537ac8aa8baff7ffb81f632f',
    '0xeb2fbe884faf895f42f5a5e6703070becc168af08cb1e9ca1332eab0493919d4',
    '0xbd64ea6b74132d89c8306303a3b6aa6ac789f0011a2da9c0d19e8e1d2d28a76f',
    '0xc65aa4bfd5136d7ae91fd8728c55e4a6c81ce2dd19a676201f5a250b0c1a3b1e',
    '0xcb37c7010595a414fce9baa0ea179518c79ffaa86dab61f13454ff490f3cf6bf',
    '0xc17d77ea92d90845040a3d394a3ff321644c87afc8ac836d02d6943a4682292f',
    '0x59a5f76a974aa00fdcf79dff73ddf88e959c9a7e5eb23f6f8b3d5c8672148f62',
    '0xffdc4a70d555255ef82de0c85512d8be5ab62f046c948eedf52ba7a321454397',
    '0xb2ebb3098081c7d70780d3cd53374ced76ff811fafcb8a20f0cd6c0f4bdb71c9',
    '0x109a40c17fad9c49dca3f16deb7e807ace9237316bbf2f8ad1ba2955ad0801d5',
    '0x8cd66a59dc1e087e99dc4184aacb17ce28c2aa76b314436c43fce5706adadc61',
    '0xe5185e36a3c77de00658198aa8e25877d715bee490cc26a6913536d4e2b36755',
    '0x2e55411265fc753d2b72895b4679b19a4601270c1f9307cc758f4ab1c05c76f5',
    '0xa3e62d394ca6b873621e2af16f349b7b8cc94043868b97354ae224e3c1dfa079',
    '0xa132c8abed9f9b2b81144fa95647c0f66644d5de8521940010e835db49a364e7',
    '0xe5a0c33ecb46c307125597ff6b31385c6e7f4e098c18629a2cc309ebb9672e4f',
    '0xdaaf4a376ad30107409a4b4ad2bc2098da5c9125091c80e4c5f3d7a024795274',
    '0x2687629951ec9865bb9e62abfc1eda3883ccc3e0ebe709a33f3143984c915c5c',
    '0x2a10bb9e76c2e1650201a1ea5d62cad6f6d676b7cb1c122543131b2d92e1ffc9',
    '0x0dabba80c36844c1fc4bfcab3854b4d5e8a3c7778778c919d062446f225719e7',
    '0x885e69961d669bbb685e6916e0d78bc3738b61f854933ac180dc902604a2242c',
    '0x27fb0bd10b7426727b50ca5349f7b97083ad0905b30525747f9ab8630ded1514',
    '0x2f04e3a5e0acd5750766526fbaae070ef7013c69799b2e3d2474fd30e444bd57',
    '0x10075bfa146887756813da9889daf9b8055ed90d86b206c217a879f731d36beb',
    '0x1c8870574c59ee11a701dc6512000c3512c4fbb0efd85aae3e647af51d1658f6',
    '0xfa6d952e70f549b41f7038e80564d1cf9b25a9663e27c03e779395056b82ecdb',
    '0xe7f9bde649fe9a24dabe35a6d55faca1466d30c3bb2f65a79611edf0ba126413',
    '0x6a00fb12cea87b258a214a4a54f97e8c880f27510e6a9650f639dc200f6fc12b',
    '0x0e1c347dd09abb078e980a032e7e6c390e173ecdcc20fcbabfbea39e12a9ed66',
    '0x84dff28b0277c17c9415898541c8bad26a90b112116ee7972e5d2159733d4480',
    '0x4ab03eddfd99ba2468cd044c3453d9a2c1fc4999c11dbde91fcd0bb01841ee25',
    '0x400a5e8d88e52ff62d1644c2ccc0985087561dd6e759aa6ab12f943d348caa07',
    '0xd20ea39601c87c2c03ab099254761bea215dc94a15c3b0a22ddf132b8d439204',
    '0x2f68c61fc88331894ef76a0d4febda7164186c94a9ebea2db51acb44d10fd8a7',
    '0x83cf6797746187f38ee57c5ff5d94ce62c9f27d39873d4336d30cb602a36e135',
    '0xf0f45888c8267b44251ff3c94e46b98eff6c284c7cb5072fb7b2260c8af8cbd8',
    '0x73ff83b91c18558916cc26b84dde77a6b0c8a4d7e7bd88df88051b1e0755d63a',
    '0x54103d7232633b40818ab7377753841e7b63d98704cb173a3729d97c9e2cd0db',
    '0x1563e6d33a739c0e4a31ff22e43810dfce3b66911053fa7e65273a8f965f0ab2',
    '0x31111fc5141a15fe00a3170b669b54a41bd07d36d9db3b7225e623f8faee9bff',
  ],
  // e e s e e
  '0xcee2ffd24d33748309909d8f0e6738b8db211a07d4e9e28dd487198913976e7d': [
    '0xc69f8f81f9d9ed136b450a5deb68b9cb6e13f5986d3ccad0063823100c2205e1',
    '0xe7981b0c6044362f0c5410e5d10aa53081d1c294b3e4dbbea0862bee66f53bad',
    '0xfb4ed679fe2005dd339076e435e00c6c92b3568dec254bd63ff9c2808c3096ef',
    '0xf84bd39e3d06dc538a5eeca7113fa5864f856d3fde7677df7bf91891242492f6',
    '0x3f73df28b022b472357d58ca311602c50bb45ec13b171bd43d13b02254261274',
    '0x74b6d17b3d33bb2f2e0bdfd88f72d262a921c83ed9d5f97f6b2dd1e761a40d76',
    '0xf9c68f7871a4ef582916c90ccfa47f88d16f277bf7c463ff66f43bb660333db6',
    '0x30521af14eb017af337f02ed5be2954a825bebf0c278c379a588b8e87ddc6f53',
    '0x5fca7a465ccbeda450ede41698b94d8be17bed81dda589e0f893dee71f4af9b3',
    '0x8820bdc3dfc243bf82d655354cd41dec9b45d8a79d176e17c3bc8fc1c57fb734',
    '0xd44be1cd629f5244fc56282a289656001ae0113c716ac6ba0a12eab92e5a4ddf',
    '0xe8102da9a552a2dc469cfa25807946ba12b186e650986ea20b3e90725929ebce',
    '0x69a50f080c2f4037bcf3ba9c54f509dca6f4d293b2095aa5d039049a6131ad3b',
    '0x80ab8dcb5f404889b4a891fe060f4f24d7118ca67e30f8aa859a3530db5647be',
    '0x5fe4049af32de734413253de1d7c62b7b06d5f7f438094b26c366d0270113c35',
    '0xd40496b5c3d51896c3d8bc2f62cb01a557283d0a4a8701073cbb613bc9ccce5c',
    '0x96389eb0d531dac7aa655166d2c83e248a06819e70c2704f9fe6f3c02da3dac0',
    '0xd66ef77e75fc046f323dd8e73090d51b175f47ae5e0d5c5044ee1ef5d3419f2d',
    '0x0217091c271744b2f0c0776c8f1ffa4a964a9516cfd7403408903fd8bf5d02c7',
    '0x63545119a4f5232354b3585779153496bb1dabf3cde3f827f026de23f13bbc0d',
    '0x036f38ee811ba49df9580174d5b1b29641994f2a4008fcf631b0b1e5aedbc5ac',
    '0x99987cb0b0a11608e80ff4c1c6bc5fa44fb9e8deba000bfc4264f8f3d9414167',
    '0xdc4a6de4be05e453ff49b902050e02b7a2cfa737fba62a73ebd5354fa7ea543b',
    '0xc7f899451705e003062d67f9e702ff72ecdef4c13a96f9ae74b84d2e6c0dc4ea',
    '0xd6852331232ba5c976fc93f6ea227988743195aa961a4f9e2f2bc7721fc22fd9',
    '0x1600a47f2d438e711a4243c53e00e11f31faccc5f83672380eac816effb21fb3',
    '0xa5f79141aa86d7ecc4ef2e19d777c3ec1aace3c17d0b7109df62612f3481273a',
    '0x4aa126b042aa089fceb3ed2c49d9f8cff071d562dfe3d1ba6ce547e01b7c6d44',
    '0x9368e40be68078a07023e2d815e7ef18b597939150cf58037e452e8ba2d350d2',
    '0xbb849eec4e7fe2c6ba89026c98531e081137ecc3f91b2d42cf21c4b7f942599d',
    '0x2a93f3a4c26b9cfcd1bf5bc38d54dca92ce05bbadfbb35b6236215bfbe687edb',
    '0x38ab78b1dcd73a04479753ed0b06f8d4ca56d1acf817b14eb3917cca380930ae',
    '0x214bdda7b58f8dc2319f9da288461e07e1ce09988152c6b989aca10b90a0f865',
    '0x8e12dac64baf74b1514dc8852890f50d081565fa81fb5a7ab92dbe76afd91703',
    '0xcc7b4bf8b8bbd01703114ab64bdaefb2ea04e86505530499464c1db91cd09ea8',
    '0x6471571aaa46fad97a38e73d78e641057e58e4dce8690c0ef1fe2d6041530cc0',
    '0x87c531d60b1e1b936ca19a6d18a45b45b2410ca0f89fb7ba863c5c8a0dc2fd73',
    '0x73dcfe758d64be3244dfc7b36809452e4a19322386f06b98fb328ef05b0e58f4',
    '0x44bf48ce14574c9823049fd90c436f5aee083df5143f049df17bbf2e60e5c812',
    '0xa5359d734ce5ac8e5b8c8e82478c1ce963df7dab6c98e60cd82ecae3c13a3ecf',
    '0xfddf6f5fbdafdab4e47ed536f8cc176b1170c4c9fd8f940f1b42aae6bc16a7cd',
    '0xaf263604c426eab2ae7c604ec5e4e95c82262def50899ed24c2beb3b11056ba6',
    '0x69efdc2c9c4bc7bf07d0981587235fae74f89f6a856a2e2cb1242fc82b835263',
    '0x6bcab9b63e9df2d2b6369b32de6430cc2d352b43f9b9d1090681d890c0c261bf',
    '0x46c726042a18ff02dad992d530d75120056bd7612b76a064807dc806558ed1bb',
    '0xe48fe1a0759d6b02aea6000e3d827d1f9d285a4b8f2a598143cb89fd2bd9afb6',
    '0xa3ea555bcd3e522b55b3221829e81fc1ec05ce28fba5fddce72f717ec8a0657d',
    '0x47ffd9769a5ab9865405a2028c8453a38dc8a4ac3d42d06090ba9bbcbf3953c9',
    '0x36b2021046363f618ccd72fc8e2bbf10cdccf38d6e50ff2cec89eef395199de5',
    '0xe80e5ca8c5967cf6686205b545a944198316ba9fae0e5a63dc07781428ab7356',
    '0x8f86ca6b9370f92f8666f70560c6fcceb208835f9042c9ebfd3fc9e9080c6007',
    '0x49d70ec0d765bee06b2ee8fc1ca30f3d843fbd3aba6bf52c14eccb2bb58420f2',
    '0x894dc9ecbee2428b5cf70e08bc98a37c2741b900291cd9c82ef6c788610203f1',
    '0xadd39f83e5f6d82dc74906bdcbf4a6a99779a949bc41a5c678062d4e5cbffa39',
    '0xc95139395c02ebeee983b47755d6163794d7b04beef842c69d51e7d93511ef22',
    '0x2058148b3f2b8a1b0ca87b6c884e52213b8a5243fbfb819c14a73604d19c92f3',
    '0xd0205807a2b62d1d4fdc3824a5988177192db701e788499cbf9e413be9cebe53',
    '0x3cfbc99f2963f3dd1948edb123de44fd088374793d7c540232a38a3253f1a3a8',
    '0x204e87b496295a667cf5b96ef241c6e88e993833a64327cacdb569d4a00cc519',
    '0x19e344ca5e8b6be76fb4e06f9bc22662ed233a726c776a983dbffe2efd0d227e',
    '0x6cfc59026d29347e7b40c553933b9f72d427e80f773e7c05d189806151b895f3',
    '0xb723179bee498a711a92fa50f63ae95713908500304ccd4c4a9384347cf7d999',
    '0x9551cf4a011157ac41342899fc69913fc62c8fb92b4a50cfb6237058357af630',
    '0x5466bce86f350fd4d2710503dbb7555ecdf90d2d7955d5cf334da11ac13c26a0',
    '0xfbc6a32cfad2a148b51e3ed6988ddbc8187c9318da8750f2a3dc7974c5e35d7d',
    '0xdcf0577bb3f35c33bd620e009a64c665c2fbf388b6eb4e4cd33228a698a686ed',
    '0x448e04b909b9c658de9511964a8092fafbeb80a5fea084c854acdb36fa239819',
    '0x6cbf728e2c7435b28def53ab8deb3657273dd482046fe356c22cb540290105e7',
    '0x85436bf38fba4d0e27dfb1cfb9bfd03f7f5f881c96e3af6cc30d63568b9ea21d',
    '0xc47bc9600067ceb49792a3833363c0771b724344174950795e99069bb08cedd4',
    '0x198026615d6b9b3e0685ce968a023427510915076064d03262d4a8a9420f7d27',
    '0xbbc1b93135b31ba1c206b6ceae7d6bc0ea4198c68ebf110f9f17dd749da1383f',
    '0x7336eba0c8ae991eb60162895e9de949db928b480332a7ea04e2d016ae44b8f5',
    '0x96fcde8e3540e681d613b338f0ce179633e857c94642c8d1fbb6b76bf49eccb2',
    '0xbf40861e083f0b0f08714b42db8dedb4748379e8d79ae18351303e0d152d23b3',
    '0x555582b18addf1cd38f7f32fb21bd72af4bb4b5d9cf91222b259d339dac0b7a9',
    '0x20ec4f7aac4976426891607dae2186ef4bee97d9c46fcf6279bf83303ca016be',
    '0x7a7dd01e852a7ba895a6fe57ccb2be332000452def586957c0426f4b88c17f53',
    '0xb22ed731d49cc1501755d0ea47b840fb990003d647b821bc55ae6ba7bc0cfdd7',
    '0xb5c23a574a6a3b0396f705c745af50299a67e454bbb7eb0ae7bd7e03a001c86f',
    '0x85f1c8ae31bbcb788ae55eef2bbd7c6e2f71b00615fc09c2a888c3b9cf51523a',
    '0xb418666729ea1f10c637008961fda1e8036a79c29071c372cd56c673192d1526',
    '0x6d5bfcd12e88ce57f733300c0934d3d841859837f968aa143e10283094a14b75',
    '0x1f8af56740cb6b666fd500ee68d79f4f6c14fe30cb5f6731bb180211a94b2dd0',
    '0xf3e85f28f5bca452b7b092ea8e16b91ecc4d98a6d63daede02912484d8fd9eed',
    '0x4e19b05a4d59b0c7e2274a0ecc701a3be94afa0efd8d73d7f6d7717da2e6257d',
    '0x3dece789f70297a75d03791d15495e5a0e70da37ee108ab456a552b717936a13',
    '0xfb5c90a77c5ea560755ec733fc57a31a21ba0a1ee1db759423ea39cbc230da3a',
    '0x37208d959b2ed0afdcf348cfa8c35eb570b4c2dceb7d3d46263a9765d69b6b80',
    '0x9e42d1b803f45e288c5bdd5c28a1c8edc7ce52241b9e2f78aa18a2fba15aeffa',
    '0x55b69cdbc633ff3ee7e11f45c25a38bcf04c82e9282b7d014bdf0701a9f5f0eb',
    '0x749d01add941a1b40a95118b876ae108a8eee7cdb205c313849342b7eef158ba',
    '0xc3f43046430c146be446c26be490a26e5dfc7e8436099e2228f878570002a135',
    '0xc5816569e0b675e24bd9befd37a592fa7181a55d4e2390226d327a7166589e47',
    '0x63164d90c90cfc50b3df94d94daa94612de6f727ede7f40c6a6c22127933cf0b',
    '0x6c522a31d1ee17fc1a4aa7493cbf4b600051475040b0cc65f1a5f4f0dd0d2ca2',
    '0x8606bcbb6c89f8110166e038f22727b8e37c20b220822f7a7d235fd1c69c905c',
    '0xcf732e0986a89adad3bc1545c7dd4cb8e5f1055093a9a823bef38cd5ba88a1af',
    '0x7e8daf0bbd189bbd223968f6e8870f84962314670aefe1e7dbc5893e27a92625',
    '0x32116a07224b4dde4cde775f947cc485af81e5905d4cec269d515f32356080cd',
    '0x27ca66eab5cf56e25ad8c8196515cbff2bac874f9865d7f9d9006b230e08972b',
    '0x3a0d6546f094f3214ffae3fc44ed8b5bc043c87d1b6428599719dea9ceabff8b',
    '0x2bf4d7b19880d7be6bc2b7f18713063dcee683d2f27f46a53d3d26e793ec1821',
    '0xaac4f862841fc57ba9043a5b7e031cb7f1b966e0cf318c35e40d3e7717007542',
    '0x610cdeb60b3f2c3560975f0c3c0283cefcf4f89113e907676795be1517806c64',
    '0xe9d2d77a98e233475ed589ee6d33e171f2d6f3396561f3ba7ae8688ecab6b5ad',
    '0x6deb90ac8aca1db751e95b7b02316f10c2f2529b531c7dbb211f50d1bb5dd881',
    '0x3e9667021bb74f9e103a8001ba86768b27450d8de95f3d67fa02fb7968fa4398',
    '0xb51692f99304abea2d4d290f2d0dffd476b91d6257e03d93ba634ca46af6e52d',
    '0x6a7f10bd58c364df921c859851484ba4e65d6ee3084fac81bceb3cc80ab5dc3b',
    '0x6587d3195e0241d14a525257d254b804798bf7ee2710089a166d19af344cf57f',
    '0x2899237888c4385db245e5dfd09e67432bd7c00ed21b6367b66bee838ffab9c0',
    '0xb77bb416148ecf5ba487339252409a6cf389677f64e9c951c35ebbc85f90a2f8',
    '0x509ce98a3d9a291fb3077650e47e949a2b32bade8bb230937d6ff26f4ec9bcb3',
    '0x39c46d9532e126fea5df704a1dee650c3e091dc51a2d23977987e5b3c983fc8c',
    '0x17f7e4196cb9c581b90065510a1867e6af6f856803678e147bcbddb6199e6fad',
    '0x66b8fbf6bf6c600d533f7480ac25a27fa903ecba35ea25a8ac60fff46c9aab82',
    '0x60ec9b0d5a59fdd370a046da857dbcadd789d93f3699566124566ecc737f27bc',
    '0x44c19b9496761d97d789089b8591c3ee54b7ee8915c7e457fd6e980e58ef40a6',
    '0x87c2bef79ca5dfc4b7b92659ffc0973ee17fb0b333da1c717982351bdfc607de',
    '0x65fcf7c707a4be0334e154746a3f0922b60530fb46ebf4ffce85bbcc5c2e984d',
    '0xd13200e1d7540d6181fa06ad84727551c0d2a0ea73deee2b66c36a6bdd994800',
    '0x3038264d2037baea8140051f3e7da075444893097361f430bc774f7c8cd565e9',
    '0x03a6a92f21d6cdb553f62c8b1288952794dff062199ea465655cd3833e744026',
    '0x6be2b8121605527490eadbbb6f245c65f52ae8e20ffd9e55fc59b79632da26cf',
    '0x2cebdabda738e2e38696264a0c6b9f00d554c341d87d630f5d769663cd3962d9',
    '0x36106009e4c63068145e9e778190853f6c5ad591084e4e8d1653217209ca7a8e',
    '0xb8b823c4573047c8ff600a641d4e82ed38dc1237bfb1cfaf93f77434075241d2',
    '0x7b1327665ac21ca2401ce075ba2ad9d315fe0b6dd71bd1d8e8a2ca8290c40d52',
    '0xbe510663f639d5debe3c47c298fb761a2adbfa669c5f5523f93a1764b313cd26',
    '0xb3a34f1c7d1964ee935afd451a2a592237bff432137b3a3ff5161a9530c87c2b',
    '0xf2ee2248b30fb87eda3727d3e8b7762dfccdcb3e8cf890924a9bad3f815c7b57',
    '0xaad27868625dabcdfd8f5782ba3525475b7ca3c951b55f0dc9ad79ac4e779ef6',
    '0x99148d42c4eb793e96490464fcbc114ae13ea97221a9e1988e95db556e0ac6cf',
    '0xa6992a5b5a58519b596c0b01a3aa06fb241f0bc1d28045f01f570b6de200ada4',
    '0xdf6a3eddf7afd2db00144a0df708f72e04a91fc5b6d83fba4c0f14cbc0e941fb',
    '0x41a61050295480be6634430fc031017d622f59024188940065b67976d45f4771',
    '0x3976d11af25fcf91148f8b60f3c3e6c318d2fc24572c929b3b22dc7a0a837e97',
    '0xe4b14761299ae6b21aec2d054998afad9f6bc9cfbe70ef8c03c57bc2fc280e97',
    '0x157e2c93ab97f1d851dbf3dade799d7c80e8e5c4ca0f7f97ba40ed26541b5e68',
    '0x1fa340354e57dee75900576b4a783acc95d16081da6a9962899f8bd1272531d3',
    '0xbdf2f1c68fdd59b1b843d67c0f19ae6df54e16ff030bddb2c4057f9bbd8d977b',
    '0xdc1ba9c244624620134079c5dab829102542da4df006f412b8fdd26c4ab6d309',
    '0xcf451cf7a7fb7cad15c853dd00f2c73b2b328759b8a3fdfe7a2a207a1433b302',
    '0x3879bccaf689b33231c51051c6a0115cb4331c5a66751fedaac2bf9010dc18e6',
    '0xf4530d643d1ded9afaf392aab9a0f2f82315c2c2279fefb7f5b33c20a99f5e07',
    '0xae26361cfaac3a064640c24fbd27e70f276d7f92348bf8ef999dac757dc71d1a',
    '0xf139a7b53dbe513603e2061f6c5d103daade163e65222b539a56143f50ffe9be',
    '0xa2ce203ab03241b9700f2f1198813a4a4380633d04485f1895a00bd63a809e4b',
    '0x2857ab77f7cfdcf19826dff6fcae60b2d066faca412efbcf7eb94815d2ca5d00',
    '0xcfc62ed40fd0a9a129c36e594c29f8b6903f3a9a02cf9d2715efdea32bbb1fbe',
    '0x675cb8185867ac90eec93f68a91f0be5fc13b84da38395e803375e78c7354cef',
    '0x003937eeff9921ab743e91cf331c98674a91fb6fb967243daed4cfe49b6a465f',
    '0x6ed844450fa22e64e8de940d93953e027419f3b0835daaa24838f6204937af18',
    '0x050c08b11460c6f3ea88fa23217e31c2868c92ec68499ad3f672246d8f8284e3',
    '0x66edf9c75865d2af8cfc349604de66d8d95af18189b80753d081b3efad40d067',
    '0x8c6ad44b0c25da02ae64f75709c6ac143f79f6d40cb685ac7ed39a8dd89f2bf8',
    '0x552262953d750b87fc5b4bc8502f8f426ba1783aeb614fdf32c4257c04f54845',
    '0x63f2eb013c57e744f986f0380a276b7e79a6a10b2f5d4e2d7f67d80b02f7622d',
    '0xcef65922f7ab4c7a8420fefd0fe9ec5d082efad0a069d80fa0dc598254500075',
    '0xea4a0df7ea2bd7ea1c335731daae4d6177cbc4f3d1e0dd7d81057131c67abbcc',
    '0x7bd3d607a599b758f0129daa0ff1a2fc9ed0efc429a02725e28c63f21795f327',
    '0xccbe493beae4feaab9199126c007aae50bc38f18a7876534d99d76756c5b90b3',
    '0x458cf801574ccdffbe25cfff7bba0be5d7b5f9d0394964b5555a7ac779cbbf7e',
    '0x68ea119278d76e3ab1cb06abc9fa3ac517d0412a50e5232971a33850148f9581',
    '0xc17cb347f3da799b33cab621be000ec9db34d0dea0df08f59097a0b00d606535',
    '0x318dcf3fb31e87b852ce9dbf45ab46439f485ebb0e7951fd29054ea6e40e7d53',
    '0x36f066cd48b64b1598099975bf89c29295756860c15222816e23607b84e858f9',
    '0x33af95ef3be345b823e251b6d7ecc2dc6a7cce8ffa2936ce282f29b62fbd77b1',
    '0x73e14eb250579f567f814d677a696e1364205e70d36f0a53ffc4e56b4dc45a39',
    '0x4275011adfe88ebf0e486a9ab9afe0a7c1a77659ba0eb9353b8a9448a0e7ee95',
    '0x41a0a1fcb3bc9c058deb6f5de5dd28a577f0f8e786279c1c84bb896faf3d2d8d',
    '0x7e2adbf16706ee5de2e1b4e73d93a1bbe76afc4428f50533d6c2bb7d0ea126da',
    '0x3b0ef8c2647313f66e7394d9c7850c0c17b98c13c48954a0ee6993b900dc1e29',
    '0xa8a863dd1dced719a2f3b339c470768d919ecdee2a5f1bcacb990b0e3b591545',
    '0x1e8b7df0c7b65aedba959e63b5ad437eca56f142b5a3a7833a8dcb0ab0b7b832',
    '0xdcf53611e01138ce60d8cb98d0ad9219ceb3ee7e01571a9cf244bb953aad4e75',
    '0x6b9f938a76ea3f723c9a163a91b75915081bc10bdddc409709e949e4687d86f5',
    '0x20978eb96fd5fa40d0d60176d36391eca545b4fc5f413283a783397006ddbb12',
    '0x57ca40a46446d21b36b283b7d4555acba9f9a3ec3a8791e57ffa4f464b47b049',
    '0x1a9338699784381051c3e16dabc8d8283d07c6ebabb24106cbbe72eb66c3ffb7',
    '0x21b0e37c629a20d28d7d2bfef8bb0ac5fa62f26fd30250526c73abd7569c4331',
    '0xc1f47c35d0362ca77c03b54e7db986dc1475e8d1716e2450e56c9ab41ba38f75',
    '0x57f654d568a0de2df965029e9de1839e4d99aa38e6e75dfc0d5978acfd9cecd3',
    '0x206ba5fe8e023389bbed64012142bac014fc53c58796a80ecdff6f748638601c',
    '0x7f065aee84987044c375ca0cdc8dbbef308eb105659a428641cf9bd83dd2cbbb',
    '0x281620e1fe1e02de5938f39181ce22822dc484be0d7bfbbd957b623541cfec11',
    '0xa74f3c5e0116bf90b67449cdbd618307dc5c856abe3932f85107b5154c6a5e95',
    '0x332630428eb5dafdd5674ece743c5b1532ce55944e545572c851d0490e4d2f65',
    '0x90d74c7a499b77109a51a213185543d536630eb3d7ed9d00b9d7f31cb89a5835',
    '0x7ea2566dfc4218be3fef81ef8b580eb24fbf6b6d19c805510bb141d6c356790b',
    '0x0043c7c53e1d1786e9e394bd5158c6cf14eb7ee5ea35e4a80a40fb2d6dd6fa46',
    '0x7f4a8cd031e1d71340c3a742e36eca366084f9741672869774bbc9cc31180349',
    '0xb5de4e31179225e3bba251bbbc105b778cc89a75cd4a3cb311606630b0e36645',
    '0x1e775ad23469315d447614e68766db30bde62fac8e9a34c790f9c033eb978708',
    '0xe095d191b68dde6182793338b616a57922124f09186938d3584f793d939466fc',
    '0xe9ca64a99a771658f128ec1fde7f2f6aefce3b2a213d8d21f70d3568cd024f84',
    '0xa7034aeb3e76a17077c242d132bdad54c776c5b28b4abe94784c42e9754c71b8',
    '0x63670708aacc3563809240e0016572f917324329c09555001faa069bff8d80e3',
    '0x8e992c4b1d11f080545c9075ac7fb3e133218fc1c06eb93b80842c847f5b9030',
    '0xf2f77c63ec7920d781ba51f7b8664ea87db6241898e2a81fc3d23e642fcddad7',
    '0x7328f2b6139e0e133d3ca0059a3986d2dcd74c4cf69f93208ec864ab5f98ebe5',
    '0x9664e71d69adf9b447273c84b9e629276c201d67aa6dbed52a7b8818e5a9761a',
    '0x5931bb886a2efa5758874a7613cd915bcf0d4f9ea216f14571b73796996d5029',
    '0xaf5f25b4b7478807ef9e1d76e0a6efe00b2602d4d28d921fc5b4d36fc6f66a55',
    '0x13f3dbab1fadd24960b8d3f5921bf103c4792728205af65f26540ef7f954fa05',
    '0xc3c04e224999e726063d49d1c97730685806dbcb9b762dbac42132ade9346f6f',
    '0x2a0655b03312dfcb8cc60910b8b949400802620883d3e1a7a78c56b9f4d622f5',
    '0xbd83d008bf079720794caea27e5f9ae5db35b519974e3c2a86416a7a8c1b951d',
    '0x28ec41fa91d6bd29c2b548015aa40e392d0032df7e18527b98928a3a43eb0a9a',
    '0x4d07b092cab745341ff6399abf02e07f361b508415b27c5881801dca40c40fe3',
    '0x20a8f33f06baa31de62f34ab2c882937f0518127eba6aeb8cefd6bc8bcfb341f',
    '0x7e4528580bf84f0dd8101f871315295f34566bb8a995efe9f081372be5d02cbf',
    '0xcdeb371745fc5d0af19d4d644cbc000bb6642e138ec56ae2c23e1514c048d342',
    '0x32fd36b1230766508e9adf483c42bc359f69fd1d68e1b768d3071390bbe173b7',
    '0x96a34e37ef45788fb0cd975a5170f89e8a8120978e0d79bc7fab598d459119f9',
    '0xe204adde0142df4ca1539a070a46f81eb8a582ffd5f54187d1691627bad12351',
    '0xfc2654fb370dc087d71f13178d27b1d5a25859d5cc5d3a884a959a40f9b849ff',
    '0x72f910318099c18288024848338a4993b7d0c3c26ae79b5379a9698b51a04b1a',
    '0x344e3ce18a2c2277dc32608ce7130b2bcddc4b97637d948208c893d7183fb045',
    '0x6c252e6351401a96a440ee1a81643ccdcd6144ebdeb8acebbf587104466bd73c',
    '0x905fadd8bd8a968f78158651e2b855a4592ef24d71a8d0525641b3efc8f8f1ef',
    '0xcb57a50822cc370197313c6f914fb315f22d35e5aad5544b8d09adc72a4bfed6',
    '0x15c2dcd4f0051f648bb885c3921ff75f02336a0ab6681a82423e393edf5d4b22',
    '0x1f51791257615cfaff9985a8ec1329a65fc0eeb7a55677e773ae14657f8a55cf',
    '0x82e19530127fa3510d39542bc21ef9c21ab51048556801aeb96b0c17afbb8656',
    '0xf3489280d38eef42784e5d9226dfb6c739b8c46743016e66b9e074802a68d365',
    '0x68fb5be0b4e7898b8dd20582b537f0e8306d089fa6dd84f5b5c4a04d45854d0b',
    '0x4c6c799f83d8d6f42b6443e08ea8ece68d57db38f93b27168ebdefd174f3fadf',
    '0xfa0b7c23c9c17ea3e8a0e2d5427f77590fd54fdc65a9e748133e528a92a7ee72',
    '0xc2b660942c37f0f1e3432d1f01096e33c35fd0ff8043d8847bfdaaddb4153185',
    '0x1231799124bbf59987e0d0bc2577f70886785ce95bf7d85d6ad0d110838c7c68',
    '0x6cd5a52f3d9809041c05af997752a6b7fd278dfb27f6ebe43ee691ba3eeb999c',
    '0x6ed940157f9a5f2a3281c9d0c089b5014144ce18c99d0d100b81504c273d7e7e',
    '0xd20ea39601c87c2c03ab099254761bea215dc94a15c3b0a22ddf132b8d439204',
    '0xa29f565ed3f3fe9f97dadcb99226e4f448f33ec3d88abfece848766366038fa9',
    '0xb0421375446c6d6e9e0be5909d69ada0c1590ea07d82711cdcf017ac3e64ad90',
    '0x39e67c3cdd4da1a0e1d5cf25a01f9503490c70ded7701784fba57a71b3585b27',
    '0x90e33a66933b1ab9383a4caa8995c9740a6eda50a13725c19ed7636352162476',
    '0x6f6ab4e6ddbf16ed362094c0d02cebde15b5518ec0337a3b0808c983a0d42860',
    '0x63ac5b4cdb125855624c413c2ad2751162a66d1f982bc5784a0bfdf7870c7e0a',
    '0xe82c74b793a30f2bb79d55162aa90b6aa8db397cf96f6c519b030588d87168dc',
    '0x897ddd69b6c4e796a2b292b28c513dba6f470d145e7b224a576fd3d41807944e',
    '0xf90ac3f3411f6a35a0093a087bec10b7339bb0257e64d018feb25549beb4b6b7',
    '0x200036db97714781d9f8e88e1aa09c04c444afb480293823bf16bf5d1036e2e5',
    '0x6dcf28b66c7f44a23ba8676bc6d5621bf77ae87042bc685f057e48b690893186',
    '0x9c32abe3673ad3a0323af3dc674e892366cb935934e6c85409ecd64861d52c6d',
    '0x5c60c8750d53d52d47e49b8c421aab9d3fb4d5e06b38b1c7c94b7f9a4db9280e',
    '0x34c521c7a0f01bd50f66005ccc9ce99207300f14c1845f4772a37cb9571b7919',
    '0x9346ecac81ff8b37dd2cab93f7ecac102860c7a1766d835a36beab41126183aa',
    '0xf4c5464b69c107c0ead5074b92b2f0107363ff8efc0cf0b37669499b600fde93',
    '0x00843b4ec91682122ab35eca5c0e9ab09484fd01fb6a81eb263e482a73b227a8',
    '0xa4493e5e2e0f403462c9740180fb604f6987934a76d4b1b7b260f0a7ab300985',
  ],
}

export const getPr0x1edVestingAddress = (
  claimContract: string | any,
  account: string | any,
) => {
  if (typeof claimContract == 'string' && typeof account == 'string') {
    const claimContractId = ethers.utils.id(claimContract)
    const accountId = ethers.utils.id(account)
    if (
      _.isArray(us3rByVestingAddress[claimContractId]) &&
      us3rByVestingAddress[claimContractId].includes(accountId)
    ) {
      return pr0x1edVestingAddress[claimContractId] || claimContract
    }
  }
  return claimContract
}
