import React, { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  styled,
  tableRowClasses,
  backdropClasses,
  Grid,
  debounce,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { Link, useNavigate } from 'react-router-dom'
import { removeXFromROIAndMakeItPercentage, getReplacedCdnEndpoint } from '../../utils'
import { useParticipationEntryPoint } from '../../Context'
import { RenderLaunchHostedBy } from '../../components/NewUpcomingLaunchCard'
import { ReferencesBg } from '../../assets'
import InputWithLabel from '../../components/InputWithLabel'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../redux/slices/themeSlice'
import { ChipText } from '../../components/NewUpcomingLaunchCard'

type Project = {
  id: string
  name: string
  bannerImageUrl: string
  logoUrl: string
  projectTitle: string
  totalRaise: number
  startsIn: number
  saleType: string
  marketMaker: string
  registrationStarted: boolean
  registrationEndDate: number
  routeName: string
  roi: string
}

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#fff',
  borderBottom: '0.5px solid rgba(0,0,0,0.2)',
  color: '#000',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
})
export const StyledBodyTableCell = styled(TableCell)({
  height: '72px',
  borderBottom: '0.5px solid rgba(0,0,0,0.2)',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
  lineHeight: '15px',
})

const CompletedSales: React.FC<{ tokenSales: any }> = ({ tokenSales }) => {
  // const [projects, setProjects] = useState<Project[]>(tokenSales)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchedProject, setSearchedProject] = useState('')
  const [totalRecord, setTotalRecords] = useState(tokenSales.length)

  const [showMore, setShowMore] = useState(false)
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown540 = useMediaQuery('(max-width:540px)')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const itemsPerPage = 10

  const totalPages = Math.ceil(tokenSales.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const [currentItems, setCurrentItems] = useState(
    tokenSales.sort((a: any, b: any) => b.order - a.order).slice(startIndex, endIndex),
  )

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(1, prevPage - 1))
  }

  const handleSearchedAddress = () => {
    // if searched address is not valid
    if (!searchedProject) return;

    // // find address in winners array
    const projectsResult = tokenSales.filter(
      (project: Project) => project.projectTitle.toLowerCase().includes(searchedProject.toLowerCase()),
    )

    if (!projectsResult.length) {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Project not found',
        }),
      )
      return
    }

    // Reset if current page is greater than total page searched
    if (Math.ceil(projectsResult.length/itemsPerPage) < currentPage) {
      setCurrentPage(1);
    }

    setTotalRecords(projectsResult.length)
    setCurrentItems(projectsResult.sort((a: any, b: any) => b.order - a.order).slice(startIndex, endIndex))
  }

  useEffect(() => {
    if (tokenSales.length === 0) return

    setCurrentItems(tokenSales.sort((a: any, b: any) => b.order - a.order).slice(startIndex, endIndex))
    setTotalRecords(tokenSales.length)

    if (searchedProject) {
      handleSearchedAddress();
    }

  }, [tokenSales, startIndex, endIndex])

  useEffect(() => {
    if (!searchedProject) {
      setCurrentPage(1)
      setCurrentItems(tokenSales.sort((a: any, b: any) => b.order - a.order).slice(startIndex, endIndex))
      setTotalRecords(tokenSales.length)
    }
  }, [searchedProject])

  useEffect(() => {
    setProjectLinkSource('')
  }, [tokenSales])

  const { setProjectLinkSource } = useParticipationEntryPoint()

  return (
    <Box
      // marginTop={'106px'}
      width={'100%'}
      padding={isDown767 ? '0' : isDown1200 ? '0 64px' : '0 168px'}
      sx={{
        backgroundImage: `url(${ReferencesBg})`,
        backgroundSize: 'cover',
        backgroundPositionY: '17%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        sx={{ paddingTop: isDown1200 ? '24px' : ''
      }}>
        <Typography
          fontWeight={600}
          fontSize={isDown767 ? 32 : 56}
          lineHeight={isDown767 ? '32px' : '61.5px'}
          letterSpacing={'0px'}
          textAlign={'left'}
          fontFamily={'Hellix'}
          color="#000"
          mb={1}
        >
          Completed Sales
        </Typography>
        <Typography
          fontFamily={'Inter'}
          color="#767676"
          fontSize={isDown767 ? 17 : 18}
          lineHeight={'27px'}
          fontWeight={400}
          pb={'24px'}
        >
          Previous sales that were hosted on our platform.
        </Typography>
      <Box
        sx={{
          padding: '13px',
          paddingX: '20px',
          borderRadius: '12px 12px 0 0',
          display: 'flex',
          flexDirection: isDown600 ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isDown600 ? 'flex-start' : 'center',
          backgroundColor: '#F2F2F2',
          border: '1px solid rgba(0,0,0,0.2)',
          borderBottom: 'unset',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={isDown600 ? '100%' : 'fit-content'}
        >
          {!isDown600 ? (
            <InputWithLabel
              value={searchedProject}
              onChange={setSearchedProject}
              placeholder="Search Project"
              bgColor="#fff"
              color="#767676"
              caretColor="#767676"
              fm="Inter"
              mb={0}
              sx={{
                ml: '10px',
                backgroundColor: '#fff',
                borderRadius: '50px',
              }}
              fs={15}
              fw={400}
              w={'297px'}
              br="44px"
              lh="22.5px"
              h="38px"
              px="8px"
              onAdornmentClick={handleSearchedAddress}
            />
          ) : (
            <PaginatorBox
              startIndex={startIndex}
              endIndex={endIndex}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              currentPage={currentPage}
              totalRecord={totalRecord}
            />
          )}
        </Box>
        {!isDown600 && (
          <PaginatorBox
            startIndex={startIndex}
            endIndex={endIndex}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            currentPage={currentPage}
            totalRecord={totalRecord}
          />
        )}
        {isDown600 && (
          <InputWithLabel
            value={searchedProject}
            onChange={setSearchedProject}
            placeholder="Search Project"
            color="#767676"
            bgColor="#fff"
            caretColor="#767676"
            fm="Inter"
            mb={0}
            fs={15}
            fw={400}
            w={'100%'}
            br="44px"
            lh="22.5px"
            h="38px"
            px="8px"
            onAdornmentClick={handleSearchedAddress}
            sx={{
              backgroundColor: '#fff'
            }}
          />
        )}
      </Box>
        <Box
          width={'100%'}
          maxWidth={`${window.innerWidth - 40}px`}
          display={'flex'}
          flexDirection={'column'}
          paddingLeft={'0px'}
          paddingTop={'0px'}
        >
          <TableContainer
            component={Paper}
            sx={{
              height: '100%',
              // maxHeight: '574px',
              borderRadius: 'unset',
              border: '0.5px solid rgba(0,0,0,0.2)',
              boxShadow: 'none',
              backgroundColor: 'unset',
              backgroundImage: 'none',
              flexShrink: 1,
              overflowX: 'auto',
              // maxWidth: `calc(${window.innerWidth}px - 40px)`,
              width: '100%',
              '&::-webkit-scrollbar': {
                display: 'flex',
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#313335',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.2)',
              },
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                // minHeight: '570px',
                // height: '100%',
                // flexShrink: 1,
                // width: '100%',
                height: '100%',
                flexShrink: 1,
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    textWrap: 'nowrap',
                  }}
                >
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                  <StyledHeadTableCell>IDO Total Raise</StyledHeadTableCell>
                  <StyledHeadTableCell>All Time High</StyledHeadTableCell>
                  <StyledHeadTableCell>Hosted By</StyledHeadTableCell>
                  <StyledHeadTableCell>Exchanges</StyledHeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  overflowY: 'hidden',
                  overflowX: 'auto',
                  backgroundColor: '#fff',
                }}
              >
                {currentItems.map((project: any) => (
                  <TableRow
                    key={project.id}
                    component={Link}
                    to={`/project/${project.routeName}`}
                    onClick={() => setProjectLinkSource('Closed')}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#FF7722',
                        'td': {
                          color: '#000'
                        },
                        '.subtext-saletype': {
                          color: '#000'
                        },
                        '.project-exchanges > div': {
                          backgroundColor: '#000',
                          '> p': {
                            color: '#fff',
                          }
                        }
                      },
                      [`&.${tableRowClasses.root}`]: {
                        height: '56px',
                        maxHeight: '56px',
                      },
                      '&:last-child': {
                        borderBottom: '0px solid transparent',
                      },
                      '&:last-child td': {
                        borderBottom: '0px solid transparent',
                      },
                      textDecoration: 'none',
                    }}
                  >
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '220px' : '250px',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <img
                          src={getReplacedCdnEndpoint(project.logoUrl)}
                          alt={project.projectTitle}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 10,
                            border: '0.5px solid #1D1F21',
                          }}
                          loading="lazy"
                        />
                        <Box ml={'12px'}>
                          <Typography
                            sx={{
                              color: '#000',
                              fontSize: '15px',
                              fontWeight: 400,
                              lineHeight: '22.5px',
                            }}
                          >
                            {project.projectTitle}
                          </Typography>
                          <Typography
                            className='subtext-saletype'
                            sx={{
                              color: '#7E8180',
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: '15.6px',
                              letterSpacing: '0.5px',
                            }}
                          >
                            {project.saleType}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '200px' : '250px',
                      }}
                    >
                      {project.totalRaise === -2
                        ? 'Launch Drop'
                        : '$' + project.totalRaise.toLocaleString('en')}
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      {project.roi === 'TBD'
                        ? project.roi
                        : removeXFromROIAndMakeItPercentage(project.roi)}
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      <RenderLaunchHostedBy hostedBy={project.hostedBy} height='1.75rem' />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      <Box
                        className="project-exchanges"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                      {
                        project.exchanges && project.exchanges.length ? 
                        project.exchanges.map((exchange: string, index: number) => <ChipText key={index} text={exchange} color={'#767676'} bgColor={'#f2f2f2'} />)
                        : <>N/A</>
                      }
                      </Box>
                    </StyledBodyTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* <Box mt={2} textAlign="center">
        <OutlinedButton
          onClick={handleShowMore}
          sx={{ height: '48px', width: '100%' }}
        >
          {projects.length === 7 ? 'Show More' : 'Show Less'}
        </OutlinedButton>
      </Box> */}
    </Box>
  )
}


interface IPaginatorBox {
  currentPage: number
  endIndex: number
  startIndex: number
  handlePrevPage: () => void
  handleNextPage: () => void
  totalRecord: number
}

const PaginatorBox: React.FC<IPaginatorBox> = ({
  startIndex,
  endIndex,
  handleNextPage,
  handlePrevPage,
  currentPage,
  totalRecord,
}) => {
  const itemsPerPage = 10;

  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderRadius={'32px'}
      bgcolor={'##fff'}
      height={'46px'}
    >
      <Typography
        fontSize={14}
        fontWeight={400}
        lineHeight={'16.8px'}
        fontFamily={'Inter'}
        color="#767676"
      >
        {startIndex + 1} -{' '}
        {endIndex > totalRecord ? totalRecord : endIndex} of{' '}
        {totalRecord}
      </Typography>
      <KeyboardArrowLeftIcon
        onClick={() => (currentPage > 1 ? handlePrevPage() : null)}
        sx={{
          cursor: 'pointer',
          height: '24px',
          color: '#767676',
          opacity: currentPage > 1 ? 1 : 0.5,
        }}
      />
      <KeyboardArrowRightIcon
        onClick={() =>
          currentPage < Math.ceil(totalRecord / itemsPerPage) ? handleNextPage() : null
        }
        sx={{
          cursor: 'pointer',
          height: '24px',
          color: '#767676',
          opacity: currentPage < Math.ceil(totalRecord / itemsPerPage) ? 1 : 0.5,
        }}
      />
    </Grid>
  )
}

export default CompletedSales
