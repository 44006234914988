import { Box, Skeleton, useMediaQuery, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const SkeletonForUpcomingLaunches = () => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isiPhone = /iPhone/i.test(navigator.userAgent)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    largeMobile: {
      breakpoint: { max: 766, min: 640 },
      items: 1.5,
      partialVisibilityGutter: 40,
    },
    mediumMobile: {
      breakpoint: { max: 639, min: 500 },
      items: 1.2,
      partialVisibilityGutter: 30,
    },
    smallmobile: {
      breakpoint: { max: 499, min: 450 },
      items: 1.05,
      partialVisibilityGutter: 10,
    },
    extraSmallMobile: {
      breakpoint: { max: 449, min: 300 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  }

  const SkeletonCard = () => (
    <Box
      sx={{
        width: isDown767 ? '100%' : '32%',
        maxWidth: '390px',
        height: isDown767 ? '480px' : '540px',
        background: '#1A1A1A',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height="220px"
        sx={{ bgcolor: 'grey.800' }}
      />
      <Box p={2}>
        <Skeleton
          variant="circular"
          width={48}
          height={48}
          sx={{ bgcolor: 'grey.800', mb: 2 }}
        />
        <Skeleton
          variant="text"
          width="60%"
          height={32}
          sx={{ bgcolor: 'grey.800', mb: 1 }}
        />
        <Box display="flex" gap={1} mb={2}>
          {[1, 2].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={80}
              height={24}
              sx={{ bgcolor: 'grey.800', borderRadius: '12px' }}
            />
          ))}
        </Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={80}
          sx={{ bgcolor: 'grey.800', borderRadius: '8px', mb: 2 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={48}
          sx={{ bgcolor: 'grey.800', borderRadius: '8px' }}
        />
      </Box>
    </Box>
  )

  return (
    <Box width="100%" mt={isDown767 ? 0 : 3} mb={'20px'}>
      <Typography
        component="div"
        fontWeight={600}
        fontSize={isDown767 ? 32 : 56}
        lineHeight={isDown767 ? '32px' : '61.5px'}
        letterSpacing={'0px'}
        textAlign={'left'}
        fontFamily={'Hellix'}
        color="#FFFFFF"
        mb={4}
        mt={2}
      >
        Current & Upcoming Sales
      </Typography>

      {isDown767 ? (
        <Box width={`calc(${window.innerWidth}px - 40px)`}>
          <Carousel
            responsive={responsive}
            containerClass="carousel-container"
            itemClass="custom-carousel-item"
            ssr={true}
            arrows={isiPhone ? true : false}
            showDots={false}
            rewindWithAnimation={true}
            partialVisible={true}
            rewind={true}
            infinite={true}
            swipeable={isiPhone ? false : true}
          >
            {[1, 2, 3].map((_, index) => (
              <SkeletonCard key={index} />
            ))}
          </Carousel>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap"
          gap={2}
          mb={2}
          sx={{
            '& > *': {
              flexGrow: 0,
              flexShrink: 0,
            }
          }}
        >
          {[1, 2, 3].map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </Box>
      )}

      {/* Show More Button Skeleton */}
      <Box mt={2} textAlign="center">
        <Skeleton
          variant="rounded"
          width="100%"
          height={48}
          sx={{ borderRadius: '24px' }}
        />
      </Box>
    </Box>
  )
}

export default SkeletonForUpcomingLaunches
