import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const TermsOfServices = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <Box
      sx={{
        padding: isMobile ? '0 24px 0 24px' : '0 100px 0 100px',
        paddingTop: 10,
      }}
    >
      <Typography fontSize={20} mb={2} fontWeight={600}>
        Ape Terminal Terms of Service
      </Typography>
      <Typography fontSize={20} mb={2}>
        These Ape Terminal Terms of Service govern your access to and the use of
        the App (as defined below) and its Services (as defined below).
      </Typography>
      <Typography fontSize={20} mb={2}>
        These Ape Terminal Terms of Service together with any documents
        expressly incorporated by reference (“Terms”) are entered into by and
        between you (“you” or “User”) and the Company (as defined below, the
        “Company” or “we”), and constitutes a legally binding agreement between
        you and the Company. You and the Company are each a “Party” and,
        together, the “Parties” to these Terms.
      </Typography>
      <Typography fontSize={20} mb={2}>
        Please read the Terms carefully before you start using the App or any of
        the Services. By accessing or using the App or the Services you
        acknowledge and warrant that you have read, understood and accepted to
        be bound by all of the terms and conditions stipulated in these Terms
        (including all other documents, incorporated herein by reference). If
        you do not agree with all of the provisions of these Terms, or any of
        these Terms is unacceptable for you, you must not access or use the App
        or any of the Services.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We reserve the right, at our sole discretion, to change or modify these
        Terms at any time and without prior notice to you. Any and all changes
        or modifications will become effective immediately upon publication on
        the App or our website (with the “Last Revised” date at the end) and you
        agree and acknowledge that you will not be explicitly notified about
        possible amendments and modifications. By continuing to access the App
        and the Services once revised Terms are published, you agree to be bound
        by its terms. Please ensure that you check the applicable Terms every
        time you use our App in order to understand which Terms apply. If you
        have any questions or comments regarding these Terms, please contact us
        at support@apeterminal.io.
      </Typography>
      <Typography fontSize={20} mb={2}>
        To be eligible to use or access the App, including any Services offered
        on or through the App, you must be of legal age to form a binding
        contract (at least 18 years old in most jurisdictions; or the applicable
        age of majority and contractual capacity in your country of residence).
        By accessing or using the App, including any Services offered on or
        through the App, you represent and warrant that you are of legal age to
        form a binding contract.
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. Interpretation and definitions
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. The following capitalized terms shall have the meanings hereinafter
        assigned to them unless the context clearly otherwise requires:
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Ape Terminal” shall mean a product consisting of an App and Services as
        owned and operated by the Company;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “AML” shall mean Anti Money Laundering as set forth in Clause 5;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “App” shall mean Ape Terminal, a mobile, web, and software as a service
        application accessible through the Website, fully owned by the Company;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Claims” shall have the meaning set out in Clause 10;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Company” or “we” shall mean APE Better Technologies Limited, duly
        incorporated and registered in Marshal Islands with entity number 121608
        and address Trust Company Complex, Ajeltake Road, Ajeltake Island,
        Majuro, Marshall Islands MH96960, represented by director Hassan Sheikh
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Content” includes, without limitation: (1) any general news and
        information, commentary, research reports, educational material and
        information and data concerning the financial markets, securities and
        other subjects; (2) financial and investment interactive tools, such as
        alerts or calculators; (3) Company name, logos, products and service
        names, trademarks owned by the Company and (4) any other information,
        content, services, or software.
      </Typography>
      <Typography fontSize={20} mb={2}>
        “DEX” shall mean decentralized exchange;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “DLT” shall mean distributed ledger technology;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “ETH” shall mean the cryptocurrency Ethereum;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “IDO” shall have the meaning as set forth in Clause 3.4.;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “IMO” shall have the meaning as set forth in Clause 3.5.;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “ISO” shall have the meaning as set forth in Clause 3.7.;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Indemnified Party(ies)” shall have the meaning set forth in Clause 10;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Intellectual Property” shall mean all of our intellectual property,
        including inventions, discoveries, processes, methods, compositions,
        formulae, techniques, information, source code, brand names, graphics,
        User interface design, text, logos, images, information and data
        pertaining to the Services, whether or not patentable, copyrightable or
        protectable in trademark, and any trademarks, copyrights or patents
        based thereon;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “KYC” shall mean Know Your Customer as set forth in Clause 5;
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        “Last Revised” shall mean the latest version of the Terms denoted by its
        date positioned at the end of the document;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “$ATX” shall mean a unique utility cryptographic token that is native
        to Ape Terminal;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Privacy Policy” shall mean Ape Terminal Privacy Policy, accessible at
        the Website and incorporated herein by reference, for additional
        information regarding our privacy terms.
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Restricted Areas” shall have the meaning as set out in Clause 5.1;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Ineligible Persons” shall have the meaning as set out in Clause 5.1;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Services” shall have the meaning as set out in Clause 3;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Terms” shall mean these Ape Terminal Terms of Service, including any
        other documents incorporated herein by reference;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Tokens” shall mean any digital asset which is based on the
        cryptographic protocol of a computer network that may be (i) centralized
        or decentralized, (ii) closed or open-source, and (iii) used as a medium
        of exchange and/or store of value. Furthermore, a Token is expressed as
        a unit; capable of being transferred, stored, and traded on a
        peer-to-peer basis with or without conditions or limitations.
      </Typography>
      <Typography fontSize={20} mb={2}>
        “User” or “you” shall mean you, the user of the Ape Terminal App;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Wallet” shall mean a combination of a User’s public address and
        corresponding private key (or mnemonic phrase or keystore file with
        password) that holds any tokens that are created and/or received on a
        public blockchain such as Ethereum or Binance Chain, which may associate
        with corresponding transactions related to this address;
      </Typography>
      <Typography fontSize={20} mb={2}>
        “Website” shall mean the Company’s website located at apeterminal.io.
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. Access to the App and Services
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. By using the App or Services, you represent and warrant that:
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. you have full legal capacity and authority to agree and bind yourself
        to these Terms;
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. you are aware of applicable laws and regulations governing your use
        of the Services. You shall be solely responsible for ensuring compliance
        with any laws and regulations applicable to you and your use of the App
        or Services, and you shall be solely liable for any liability that may
        arise due to a breach of your legal and regulatory obligations in this
        regard;
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. your use of the App or Services is (A) not prohibited by applicable
        law, and (B) at all times compliant with all applicable laws and
        regulations;
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. you are solely responsible for use of the Services and App and, if
        applicable, for all activities that occur on or through your User
        account. The Company shall not be liable for any damages, losses, or
        liabilities arising out of errors, failures, or inaccuracies in the App
        or its Services that are directly attributable to actions, omissions, or
        negligence on the part of the User;
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        5. you take steps to ensure the confidentiality of your personal
        information and restrict access to the devices you use to access the
        App.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        6. you waive the right to participate in a className or representative
        action lawsuit or a classNamewide arbitration against Ape Terminal, the
        Company and any of its representatives or affiliates.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        3. To access the App and any of the Services, or some of the resources
        they offer, you may be asked to provide certain registration details,
        personal data or other information. It is a condition of your use of the
        App and any of the Services, that all the information you provide is
        accurate, correct, current, and complete. You agree that all information
        you provide is governed by our Privacy Policy, and you consent to all
        actions we take with respect to your information consistent with our
        Privacy Policy.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        4. You are solely responsible for implementing reasonable measures for
        securing your account, user name, password, or any other piece of
        information as part of our security procedures, you must treat such
        information as confidential, and you must not disclose it to any other
        person or entity. You also acknowledge that your App accounts are
        personal to you and agree not to provide any other person with access to
        the App, or portions of it, using your user name, password, or other
        security information. You are not allowed to use another User’s account.
        To avoid any unauthorized access to your account, you agree to ensure
        you log out of your account at the end of each session. You agree to
        notify us immediately of any unauthorized access to, or use of, your
        user name or password or any other breach of security.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        5. The Company shall not be liable for any damages, losses, or
        liabilities arising out of errors, failures, or inaccuracies in the App
        or its Services that are directly attributable to actions, omissions, or
        negligence on the part of the User. The User expressly acknowledges that
        their actions, decisions, and compliance with security practices play a
        critical role in the proper functioning and security of the App. The
        Company cannot and will not be liable for any loss or damage rising from
        your failure to comply with these Terms or to secure your App accounts
        and passwords, including, but not limited, to selecting a password that
        is not easily compromised.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        3. Services
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        6. We offer the User access to a suite of tools within the App that
        facilitate the native trading and storage of cryptocurrencies. The tools
        provided by the App are intended to assist the User in monitoring,
        analyzing, and executing cryptocurrency trades on various supported
        cryptocurrency exchanges.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        7. Ape Terminal App offers a range of advanced services and features
        intended to maximize Users´ financial opportunities, including:
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        1. Non-custodial Wallet;
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        2. allowing users to set a minimum amount of money required to make as
        many transactions as possible to buy as much of the token supply for a
        cryptocurrency that launches (“Sniping Bot”);
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        3. dollar cost averaging (i.e. keeping track of average buys and sells
        that are fully automated for Users without having to keep track of
        price) (“DCA Bot”)
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        4. order matching that automatically, and according to pre-established
        criteria, matches Users’ trades with open orders from other Users in
        respect of Tokens;
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        5. Sandwich Trades that enable you to generate yield by strategically
        utilizing your altcoin positions to front-run trades (“Sandwich Bot”);
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        6. Allowing users to stake other native cryptocurrencies to give them
        access to non-inflationary yield on their staked tokens with up to 5%
        per day (“Ape, Tokens”)
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        7. Enabling users to enter into presale’s performed through Ape
        Terminal’s launchpad/accelerator program
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        8. Ape Terminal App provides the User with a non-custodial Wallet
        feature. This wallet allows the User to securely store, manage, and
        transfer their cryptocurrency holdings. It is important to note that the
        Company does not have access to or control over the User's private keys
        or stored cryptocurrencies. The User is solely responsible for
        maintaining the security and confidentiality of their wallet
        information. Our Services facilitate peer-to-peer transactions that
        match Users with other Users. You hereby acknowledge and agree that we
        do not operate as a centralized third party exchange and that we will
        not be held liable for any losses resulting from your transactions.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        9. Initial DEX Offering (“IDO”). Ape Terminal App provides a platform
        for the Initial DEX Offering (“IDO”) for utility tokens. After the User
        undergoes necessary KYC Procedures as required by Ape Terminal, the
        Users are given the option to enter and participate in the IDO.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        10. Initial Snipe Offering (“ISO”). The Ape Terminal App allows the
        Users to participate in the "Initial Snipe Offering" (“ISO”), which is
        scheduled to commence subsequent to the IDO. This process establishes a
        regulated and engaging framework for the issue and launch of the utility
        tokens. It involves a predetermined assembly of participants
        (“Whitelisted Users”), offering them an early opportunity to acquire the
        issued tokens before other Users.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        11. For eligibility to engage in the sale and secure the chance to
        acquire any tokens in the ISO, Users are required to deposit ETH or
        USDT/USDC. The ISO operates on a whitelist mechanism, where Whitelisted
        Users are pre-selected using a lottery-based system and granted the
        privilege to purchase (“snipe”) tokens in advance, preceding the public
        launch of any such tokens. Upon the conclusion of the whitelisting
        process, Users are informed of their eligibility for sniping, while
        other Users receive a refund of their assets and retain the opportunity
        to partake in an alternative Offering.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        12. Initial Mev Offering (“IMO”). The Ape Terminal App offers Users to
        engage in the Initial Mev Offering (“IMO”), which is scheduled to
        commence subsequent to the ISO. The IMO allows Users to stake assets and
        front-run other Users who are selling their tokens. Users engaged in
        staking are presented with details like the annual percentage yield and
        other interface elements.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        13. Ape Terminal App also provides its native utility token, known as
        $ATX, with various utilities and benefits for holders and stakers,
        including discounts on fees (if paid in $ATX token), Access to presales
        hosted through Ape Terminal’s platform based on a tier system of token
        holdings of $ATX, and other utilities as described (and as amended from
        time to time) on our website.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        14. By using our Services you acknowledge and agree that we do not
        provide any financial services, including but not limited to investment
        advice, financial planning, or brokerage services. You further
        acknowledge and agree that $ATX token is a utility token and is not
        intended to be used as a financial instrument or investment vehicle. It
        is designed solely for specific uses within our App, such as accessing
        certain features, services, or benefits.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        15. Changes to Services. We reserve the right, in our sole discretion,
        to modify or discontinue, temporarily or permanently, the Services or
        any part thereof with or without notice. You agree that we will not be
        liable to you or any third party for any modification, suspension or
        discontinuance of the Services.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        4. Fees and taxes
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        16. By using the Services you agree to pay all applicable fees. Any fees
        or any other prices of the Services shall be stated at all times on our
        App and made available to you before using the Services. Unless
        explicitly stated otherwise, all fees and prices are exclusive of any
        applicable value-added tax (VAT) or other taxes.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        17. You bear sole responsibility for paying any and all taxes, duties,
        and assessments now or hereafter claimed or imposed by any governmental
        authority associated with their use of the App and the Services, and/or
        payable as the result of using and/or exploiting any crypto assets and
        interacting with smart contracts. Blockchain-based transactions are
        novel, and their tax treatment is uncertain.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        18. Fee changes. We may, in our sole discretion and at any time, modify
        the fees for the Services. We will provide you with a notice 7 days
        prior to any change in fees to give you an opportunity to terminate your
        account before such change becomes effective. Fee guide is located here
        [apeterminal.io]. If you do not agree to the fee change you must cancel
        your account in order to avoid future charges. If you use the Services
        after a fee increase you will be deemed to have accepted the fee change.
        We will not be liable to you or any third party for any fee change.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        19. Refunds. Except when required by law, fees are non-refundable.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        5. Know Your Customer / Anti Money Laundering
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        20. Any person or entity, including anyone acting on its behalf, being
        based, domiciled, located or incorporated in or is a citizen or resident
        or green card holder in any territory under the jurisdiction of the
        United States of America, including any state of the United States of
        America or the district of Columbia and all of its territories (the
        “USA”), or Canada, Republic of Korea, Singapore, The people’s republic
        of China, Bosnia, Albania, Belarus, Burma, Cote d'ivoire, Cuba,
        Democratic republic of Congo, Congo-Brazzaville, Congo-Kinshasa, Iran,
        Iraq, Liberia, Democratic People's Republic of Korea, Seychelles, Sudan,
        Syria, Tajikistan, Russian Federation, Zimbabwe, Uzbekistan or any other
        country or territory included in the OFAC or any other US, UN, EU or
        other applicable sanctions list (together “Restricted Area(s)”), as well
        as an individual, or an individual employed by or associated with an
        entity, identified on bis’s denied persons, unverified, or entity lists,
        or OFAC’s list of specially designated nationals, foreign sanctions
        evaders, or list of consolidated sanctions may not use Ape Terminal
        Services (“Ineligible Person(s)”).
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        21. The User hereby acknowledges that Know Your Customer (“KYC”) and
        Anti-Money Laundering (“AML”) verifications are a fundamental
        requirement for compliance and security. Ape Terminal is obliged to
        exert control over Users in order to comply with KYC standard practices
        and AML applicable laws and regulations. Ape Terminal expects the Users
        to be acting in good faith regarding the information provided.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        22. Ape Terminal reserves the right to collect additional personal
        information about you, such as name, surname, other identification
        documentation (as required), in order to maintain compliance with any
        applicable law, regulation or policy, including any KYC or AML
        requirements and policies, in connection with your Ape Terminal user
        account, when opening your Ape Terminal user account or at a later stage
        if Ape Terminal’s deems collecting additional information important or
        necessary for ensuring compliance with any applicable laws. When doing
        so, Ape Terminal may authorize independent KYC third-party service
        providers to collect such information on our behalf for the purposes of
        completing any compliance verification process in accordance with the
        applicable law, regulation or policy, including any KYC or AML
        requirements and policies.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        23. In the event of violation of this provision, Ape Terminal reserves
        the right to refuse, suspend or terminate services to any account owner
        or to terminate any account with immediate effect.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        24. Ape Terminal shall not be responsible for fraudulent, deceptive or
        otherwise malicious use of any tools whatsoever by any Ineligible Person
        to use Ape Terminal’s services, under the semblance of provenance from
        any other jurisdiction outside the restricted areas.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        25. The $ATX tokens may not be marketed, advertised or offered for
        participation directly or indirectly to any Ineligible Person and
        neither this Website nor these Terms, nor any material or information
        contained herein pertaining to the $ATX tokens, may be supplied to any
        Ineligible Person or used in connection with any offer for the sale of
        $ATX tokens to any Ineligible Person.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        26. For ensuring transparency, integrity and security on the App Ape
        Terminal reserves the right to engage the services of a third-party
        service provider for the verification of identities and screening for
        KYC/AML compliance. Third-party service provider shall conduct the
        necessary identity verification checks to ensure compliance with
        applicable regulations.
      </Typography>
      <Typography fontSize={20} mb={2}>
        27. The User acknowledges that the information submitted for KYC/AML
        verification might be processed by a third-party service provider and
        that Ape Terminal shall have access to the verified information in
        compliance with data protection regulations. Ape Terminal shall not be
        liable for any discrepancies or issues arising during the verification
        process conducted by third-party service provider. The User expressly
        agrees to cooperate with third-party service provider and Ape Terminal
        in providing accurate and authentic information for KYC/AML checks. By
        accepting these Terms, the User acknowledges and agrees that the User is
        required to provide personal identifiable information and must pass KYC
        protocols. The User consents to the utilization of third-party services
        and agrees to provide the correct information as requested by the
        third-party service provider. Failure to provide necessary information
        may result in the inability to access or use certain services or
        functionalities provided by the App. The User acknowledges, agrees and
        understands that Ape Terminal will transfer to a third party service
        provider any collected KYC/AML data and that Ape Terminal has the
        independent right to terminate the User’s participation based on the
        results of the KYC/AML checks.
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. Your responsibilities
      </Typography>
      <Typography fontSize={20} mb={2}>
        28. You shall not use the Services in any manner except as expressly
        permitted in these Terms. Without limiting the generality of the
        preceding sentence, you may not:
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. infringe any proprietary rights, including but not limited to
        copyrights, patents, trademarks, or trade secrets of the Company;
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. use the Services to transmit any data or send or upload any material
        that contains viruses, Trojan horses, worms, time-bombs, keystroke
        loggers, spyware, adware, or any other harmful programs or similar
        computer code designed to adversely affect the operation of any computer
        software or hardware;
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. use any robot, spider, other automated device, or manual process to
        monitor or copy the Services or any portion thereof other than copying
        or exporting as permitted by the Terms;
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. make any back-up or archival copies of the App or any part thereof,
        including the disassembling or de-compilation, or reverse-engineering of
        any of the closed-source or proprietary software running on the App;
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. trade or obtain financing on or through the App, or use any Service,
        with anything other than funds, keys, or digital tokens that you have
        legally obtained;
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. if you are a resident or national of a Prohibited Jurisdiction or a
        U.S. Person, access the App or any Services using any virtual private
        network, proxy service, or any other third party service, network, or
        product with the intent of disguising your IP address or location;
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. use the Services in (A) any unlawful manner or (B) for fraudulent or
        malicious activities, or (C) in any manner inconsistent with these
        Terms, or (D) violate applicable laws in any manner.
      </Typography>
      <Typography fontSize={20} mb={2}>
        29. By using the Services you expressly agree that you are solely
        responsible:
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. for keeping your own keystore files, passwords, mnemonic phrases
        and/or private keys. We shall not ask for any password or private keys
        from you, nor shall we ask you to transmit any funds or digital tokens,
        as applicable. Accordingly, we shall not be responsible for any losses
        caused by your transmittal of funds or Tokens, as applicable.
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. You are solely responsible for any loss to your Wallet. Your Wallet
        is not accessible by the Company, and we will not keep your keystore
        files, passwords, mnemonic phrases and/or private keys.
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. to familiarise yourself with the App, the Services, and its intended
        usage. You agree to follow all the requisite steps involved in using the
        App as intended.
      </Typography>
      <Typography fontSize={20} mb={2}>
        30. You represent and warrant that you are the ultimate and effective
        legal and beneficial owner of any Tokens transferred to your account or
        Wallet on the App, that you shall not act as nominee or trustee for any
        other person and that you shall not transfer, assign, pledge, charge or
        otherwise create any security interest or encumbrance whatsoever over
        such Tokens.
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. Acknowledgement and assumption of risks
      </Typography>
      <Typography fontSize={20} mb={2}>
        31. By engaging with our App, you acknowledge and assume the inherent
        risks associated with the holding and trading in cryptographic tokens,
        blockchain technology and DLT field. Risks include, but are not limited
        to, the following:
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. Market Risk. The market for cryptographic tokens is still new and
        uncertain. You should only have funds invested in cryptographic tokens
        or speculate in cryptographic tokens, if you are prepared to lose the
        entirety of such tokens invested in. Whether the market for one or more
        cryptographic tokens will move up or down, or whether a particular token
        will lose all or substantially all of its value, is unknown. You should
        be cautious about holding cryptographic tokens. We do not invite or make
        any offer to acquire, purchase, sell, transfer or otherwise deal in any
        crypto asset. You are solely responsible and liable for any and all
        trading and non-trading activity on or through the App and for any gains
        and losses sustained when using the Services.
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. Liquidity and Listing Risk. Markets for cryptographic tokens have
        varying degrees of liquidity. Some are quite liquid while others may be
        thinner. Thin markets can amplify volatility. There is never a guarantee
        that there will be an active market for one to sell, buy, or trade
        cryptographic tokens or products derived from or ancillary to them. We
        make no representations or warranties about whether a cryptographic that
        may be staked via the App will be traded on or through the App any point
        in the future, if at all.
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. Trading Risk. In addition to liquidity risks, values in any digital
        token marketplace are volatile and can shift quickly. You should pay
        close attention to your position and holdings, and how they may be
        impacted by sudden and adverse shifts in trading and other market
        activities. You also acknowledge and agree that costs of transacting on
        blockchain technology is variable and may increase at any time. You
        acknowledge and agree to these risks and represent that we cannot be
        held liable to changes and fluctuations in value or increased costs.
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. Legal Risk. The legal status of certain cryptographic tokens is
        uncertain. This can mean that the legality of holding or trading them is
        not always clear. Whether and how one or more cryptographic tokens
        constitute property, or assets, or rights of any kind may also seem
        unclear. You are responsible for knowing and understanding how
        cryptographic tokens are addressed, regulated, and taxed under laws that
        apply to you.
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. Technology Failure Risks. Risks associated with using an
        internet-based currency are: risk of hardware, software and internet
        connection failure or problems, risk of malicious software introduction,
        and risk of third parties obtaining unauthorized access to
        infrastructure stored within your Wallet. You accept and acknowledge
        that we will not be responsible for any communications failures,
        disruptions, errors, distortions or delays when connecting your Wallet
        and using Ethereum network or any chain or similar device for processing
        transactions, however caused. You agree and acknowledge that we do not
        represent or warrant that any of the Services or the App are secure from
        hacker or other malicious attack, which may result in stealing or loss
        of User confidential information or any other data. You acknowledge that
        we will not be responsible for and will not replace any tokens lost due
        to third-party malicious acts or due to other circumstances beyond our
        control.
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. The Services-related risks. You acknowledge the risks of using the
        Services. You bear sole responsibility for evaluating the Services
        before using them, and all transactions accessed through the Services
        are irreversible, final, and without refunds. The Services may be
        disabled, disrupted or adversely impacted as a result of sophisticated
        cyber-attacks, surges in activity, computer viruses, and/or other
        operational or technical challenges, among other things. We disclaim any
        ongoing obligation to notify you of all of the potential risks of using
        and accessing our Services. You agree to accept these risks and agree
        that you will not seek to hold us responsible for any consequent losses.
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. Quality or accessibility risks. As a condition to accessing or using
        the App or the Services, you acknowledge, understand, and agree that
        from time to time, the App and the Services may be inaccessible or
        inoperable for any reason, including, but not limited to equipment
        malfunctions, periodic maintenance procedures or repairs, causes beyond
        our control or that we could not reasonably foresee, disruptions and
        temporary or permanent unavailability of underlying blockchain
        infrastructure or unavailability of third-party service providers or
        external partners for any reason.
      </Typography>
      <Typography fontSize={20} mb={2}>
        8. Novel technology risks. Blockchain technology or distributed ledger
        technology-related projects are new and relatively untested and outside
        of our exclusive control. You acknowledge and agree that cryptography is
        a progressing field and that there are risks we cannot foresee and it is
        unreasonable to believe that such risks could have been foreseeable.
        Advances in code cracking or technical advances may present risks to
        smart contracts, cryptographic tokens and the App, which could result in
        theft or loss of your cryptographic tokens or property, among other
        potential consequences. By using the Services you acknowledge and agree
        to undertake these risks.
      </Typography>
      <Typography fontSize={20} mb={2}>
        Please note that this statement serves as a general description of risks
        associated with blockchain technology, DLT field and cryptographic
        tokens and should not be considered exhaustive. It is essential to
        conduct thorough research and seek professional advice to fully
        understand the risks involved before engaging in activities related to
        cryptographic tokens or blockchain technology.
      </Typography>
      <Typography fontSize={20} mb={2}>
        8. No representation and warranties of the Company
      </Typography>
      <Typography fontSize={20} mb={2}>
        32. We make no representations and warranties. The Services and the App
        are provided on an “as is” and “as available” basis. We make no
        guarantees of any kind in connection with the Services. Your use of the
        Services and the App shall be at your own risk. The Services, and the
        App are provided without any express or implied warranty of any kind,
        including warranties of merchantability, merchantable quality, legal
        effect, accuracy, appropriateness, completeness, non-infringement, or
        fitness for a particular purpose. We do not warrant that the Services or
        the App will be error-free, will meet your requirements, or be timely
        and secure.
      </Typography>
      <Typography fontSize={20} mb={2}>
        33. We make no warranties and representations that the Services and the
        App have been and will be provided with due skill, care and diligence or
        about the accuracy or completeness of Services and the App content and
        assumes no responsibility for.
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. errors, mistakes or inaccuracies of Content;
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. personal injury or property damage, of any nature whatsoever,
        resulting from your access and use of the Services and the App;
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. any unauthorized access or use of our servers and/or any and all
        personal information and/or financial information stored therein;
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. any interruption or cessation of transmission to or from the App;
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. any bugs, viruses, Trojan horse, or the like that may be transmitted
        to or through the actions of any third party;
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. any loss of your data or Content from the App; and/or
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. any errors and omissions in any content or for any loss or damage of
        any kind incurred as a result of the use of any Content posted, emailed,
        transmitted, or otherwise made available via the Services and the App;
      </Typography>
      <Typography fontSize={20} mb={2}>
        8. any damages arising out of improper use of the App, errors or
        failures unauthorized access or use of our servers and/or any and all
        personal information and/or financial information stored therein
      </Typography>
      <Typography fontSize={20} mb={2}>
        You will not have the right to make or pass any representations or
        warranties on behalf of Ape Terminal to any third party.
      </Typography>
      <Typography fontSize={20} mb={2}>
        The Provider clarifies that the App does not provide trading services or
        execute trades on behalf of the User. The trading functionality within
        the App is designed to provide information and tools to assist the User
        in making informed trading decisions. The User acknowledges and
        understands that all trading activities, decisions, and outcomes are the
        sole responsibility of the User.
      </Typography>
      <Typography fontSize={20} mb={2}>
        34. No advertisement. You explicitly acknowledge and agree that we do
        not advertise, recommend or advise any trading or exchanging of Tokens,
        or any other form transaction on our App. It is your sole responsibility
        to exercise caution, conduct your own due diligence, and seek
        professional advice before engaging in any transactions on or through
        our App.
      </Typography>
      <Typography fontSize={20} mb={2}>
        35. No endorsement. We do not warrant, endorse, guarantee, or assume
        responsibility for any Services on our App and we will not be liable for
        any transaction between you and other users. You are solely responsible
        for the execution of your transaction.
      </Typography>
      <Typography fontSize={20} mb={2}>
        36. No financial advice. We do not provide investment and/or financial
        advice in any manner whatsoever. Any information made available on the
        App should in no event be construed as providing any investment or other
        financial advice of any kind. you should always seek financial and/or
        investment advice and do your own independent research before using our
        App or our Services. if you choose to engage in transactions based on
        content on the App, then such decisions and investments and any
        consequences flowing therefrom are your sole responsibility.
      </Typography>
      <Typography fontSize={20} mb={2}>
        9. Limitation of Liability
      </Typography>
      <Typography fontSize={20} mb={2}>
        37. To the fullest extent permitted by applicable law: (i) in no event
        will the Company or any of its representatives or any of the Company
        parties be liable for any indirect, special, incidental, consequential,
        punitive or exemplary losses or damages of any kind (including, but not
        limited to, where related to loss of revenue, income or profits,
        diminution of value, loss of use or data, loss or depletion of goodwill,
        loss of business opportunity, loss of contract, damages for business
        interruption, loss of anticipated savings, or the like) arising out of
        or in connection with any acceptance of or reliance on these Terms, or
        with the use the App and/or the Services or otherwise related to these
        Terms, regardless of the form of action, whether based in contract, tort
        (including, but not limited to, simple negligence, whether active,
        passive or imputed), or any other legal or equitable theory (even if the
        party has been advised of the possibility of such damages and regardless
        of whether such damages were foreseeable); and (ii) in no event will the
        aggregate liability of the Company and any of its representatives
        weather in contract, warranty, tort (including negligence, whether
        active, passive or imputed), or other theory, arising out of or in any
        way related to these Terms, the access and use of the App the Services,
        exceed 30 USD.
      </Typography>
      <Typography fontSize={20} mb={2}>
        10. Indemnification
      </Typography>
      <Typography fontSize={20} mb={2}>
        38. By agreeing to these Terms and accessing the App and the Services,
        you agree, to the fullest extent permitted by applicable law, to
        indemnify, defend, and hold harmless the Company, its past, present and
        future affiliates, subsidiaries and service providers, and each of their
        past, present and future officers, directors, agents, joint venturers,
        employees, representatives, partners, and licensors (collectively
        “Indemnified Parties”, and each such person or entity individually,
        “Indemnified Party”) from and against any and all actual or alleged
        claims, demands, damages, losses, liabilities, obligations, penalties,
        interest, fees, expenses (including, without limitation, attorneys’ fees
        and expenses) and costs (including, without limitation, court costs,
        costs of settlement, and costs of pursuing indemnification and
        insurance), of every kind and nature whatsoever, whether known or
        unknown, foreseen or unforeseen, matured or unmatured, or suspected or
        unsuspected, in law or equity, whether in tort, contract, or otherwise,
        (collectively, “Claims”), that arise directly or indirectly out of:
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. your use or misuse of the App and the Services or any contract
        related thereto;
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. violation, breach or alleged breach of any provision of these Terms
        by you or any person using the Services on your behalf;
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. your violation of any applicable laws, rules, regulations, codes,
        statutes, ordinances, or orders of any governmental or
        quasi-governmental authorities;
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. anything you contribute to the Services;
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. your violation of the rights or obligations of any third party,
        including any intellectual property right, publicity, confidentiality,
        property, or privacy right;
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. your negligence or wilful misconduct;
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. your use of a third-party product, service, and/or App;
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        8. any misrepresentation made by you or
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        9. any other matter for which you are responsible hereunder or under
        law.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        39. You agree that your use of the App and Services shall be in
        compliance with all applicable laws, regulations and guidelines.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        40. We reserve the right to assume, at your expense, the exclusive
        defense and control of any matter subject to indemnification by you. You
        agree to promptly notify us of any Claims and cooperate with our defense
        of any Claims. You will not in any event settle any Claims without our
        prior written consent.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        11. Termination rights
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        41. These Terms shall remain in force and effect unless terminated for
        the time you use the App. Without limiting any other provision of these
        Terms, we reserve the right to, in our sole discretion and without
        notice or liability, deny access to or use the App (including blocking
        certain IP addresses) to any person for any reason or for no reason,
        including without limitation, for violation or breach of any
        representation, warranty or covenant contained in these Terms or any
        applicable law or regulation. We may, in our sole discretion and without
        notice, terminate your use or participation on the App or delete your
        account and any content or related information and files in your account
        and/or bar any further access to such files or the Services.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        42. If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake and borrowed name, or the name of any third party, even if you
        may be acting on behalf of the third party. In addition to terminating
        or suspending your account, we reserve the right to take appropriate
        legal action, including without limitation pursuing civil, criminal and
        injunctive redress.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        12. Intellectual property
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        43. These Terms shall not be understood and interpreted in a way that
        they would mean the assignment of Intellectual Property rights unless it
        is explicitly defined so in these Terms. You may not use any of
        Intellectual Property for any reason, except with our express, prior,
        written consent.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        44. The Website, the App and any of the Services, including their design
        elements or concepts and any and all underlying Intellectual Property,
        including, but not limited to copyrights, patents, service marks, any
        registered trademarks, domain names and other proprietary rights, are
        the property of the Company, and are protected by copyright, patent,
        trade secret and other intellectual property laws. Unless otherwise
        expressly stated, the Company retains any and all rights, title and
        interest in and to the App and the Services (including, without
        limitation, all Intellectual Property rights), including all copies,
        modifications, extensions and derivative works thereof. Your right to
        use the App and the Services is limited to the rights expressly granted
        in these Terms. No licenses to use any of trademarks or any other
        Company’s brands are to be inferred or assumed pursuant to the use of
        any of the Services. All rights not expressly granted to you are
        reserved and retained by the Company.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        13. Privacy Policy
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        45. Please review our Privacy Policy, available at: apeterminal.io. By
        using the App, you agree to be bound by our Privacy Policy, which is
        incorporated into these Terms.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        14. Force Majeure
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        46. We will not be in breach of these Terms or liable for any delay or
        failure to perform any obligation under these Terms where the delay or
        failure results from any event, circumstance or cause beyond our
        reasonable control. The Parties agree that due to the specific nature of
        the blockchain / DLT field, the circumstances of force majeure shall in
        particular include also (but shall not be limited to) interruption in
        telecommunications or internet services or network provider services,
        failure of equipment, and/or software, hacker attacks, market
        disturbances, other major event or natural catastrophe, change of laws
        or regulations, adverse regulatory or enforcement action of public
        authorities, technical failures and the like.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        15. Consumer Exclusion
      </Typography>
      <Typography fontSize={20} mb={2}>
        47. You hereby acknowledge and represent that you are not considered a
        consumer in relation to the Services provided, and the provisions of any
        applicable consumer protection rules, or any consumer protection rights,
        including distance selling rules, shall be excluded to the fullest
        extent permitted by any applicable law. By using this App, you waive any
        consumer protection rights and distance selling rights that may be
        applicable under any relevant law.
      </Typography>
      <Typography fontSize={20} mb={2}>
        16. Notices
      </Typography>
      <Typography fontSize={20} mb={2}>
        48. We may provide any notice to you under these Terms by (i) posting a
        notice on or through the App; or (ii) sending an email to the email
        associated with you. Notices we provide by posting on or through the App
        will be effective upon posting, and notices we provide by email will be
        effective when we send the email.
      </Typography>
      <Typography fontSize={20} mb={2}>
        49. To give us notice under these Terms, you must contact us by email to
        support@apeterminal.io. We may update this email address for notices to
        us by posting a notice on or through our app. Notices to us will be
        effective after they are sent.
      </Typography>
      <Typography fontSize={20} mb={2}>
        50. All communications and notices to be made or given pursuant to these
        Terms must be written in the English language. You agree and acknowledge
        that all agreements, notices, disclosures, and other communications that
        we provide to you, including these Terms, will be provided in electronic
        form.
      </Typography>
      <Typography fontSize={20} mb={2}>
        17. Miscellaneous
      </Typography>
      <Typography fontSize={20} mb={2}>
        51. Entire Agreement. These Terms, including all other documents,
        incorporated herein by reference, represent the entire agreement between
        you and us regarding the subject matter of these Terms, in particular,
        the use of the App, and the Services. These Terms supersede all prior or
        contemporaneous representations, understandings, agreements, or
        communications between you and us, whether written or verbal, regarding
        the subject matter of these Terms.
      </Typography>
      <Typography fontSize={20} mb={2}>
        52. Severability. In the event any one or more of the provisions of
        these Terms is for any reason held to be invalid, illegal or
        unenforceable, in whole or in part or in any respect, then and in any
        such event, such provision(s) only will be deemed null and void and will
        not affect any other provisions of these Terms, and the remaining
        provisions of these Terms will remain operative and in full force and
        effect.
      </Typography>
      <Typography fontSize={20} mb={2}>
        53. Assignment. Without our prior written consent, you are prohibited
        from assigning these Terms and any rights, duties, and obligations
        contained herein. However, we retain the right to freely assign or
        transfer these Terms, in whole or in part, without any limitations.
      </Typography>
      <Typography fontSize={20} mb={2}>
        54. Third Party Rights. Unless expressly provided to the contrary in
        these Terms, no third party (i.e. person who is not party to these
        Terms) shall have any rights to enforce or to enjoy any benefit of any
        terms contained herein.
      </Typography>
      <Typography fontSize={20} mb={2}>
        55. No Waiver. Any failure by the Company to exercise any of its
        respective rights, powers, or remedies under these Terms of Service, or
        any delay by the Company in doing so, does not constitute a waiver of
        any such right, power, or remedy. The single or partial exercise of any
        right, power, or remedy by the Company does not prevent either from
        exercising any other rights, powers, or remedies.
      </Typography>
      <Typography fontSize={20} mb={2}>
        56. Governing law. These Terms will be governed by and construed in
        accordance with the laws of the Republic of Switzerland.
      </Typography>
      <Typography fontSize={20} mb={2}>
        57. Dispute Resolution. Any dispute, controversy or claim arising out of
        or in relation to these Terms, including the validity, invalidity,
        breach or termination thereof, shall be submitted to mediation in
        accordance with the Swiss Rules of Mediation of the Swiss Arbitration
        Centre in force on the date when the request for mediation was submitted
        in accordance with these Rules. The seat of the mediation shall be
        Zürich, Switzerland. The mediation shall be conducted in English. If
        such dispute, controversy or claim has not been fully resolved by
        mediation within 60 (sixty) days from the date when the mediator(s) has
        (have) been confirmed or appointed by the Swiss Arbitration Centre, it
        shall be settled by arbitration in accordance with the Swiss Rules of
        International Arbitration of the Swiss Arbitration Centre in force on
        the date when the Notice of Arbitration was submitted in accordance with
        those Rules. The number of arbitrators shall be three. The seat of the
        arbitration shall be Zürich, Switzerland. The arbitration proceedings
        shall be conducted in English.
      </Typography>
      <Typography fontSize={20} mb={2}>
        First published on: 24 November, 2023
      </Typography>
      <Typography fontSize={20} mb={2}>
        Last revised on: 24 November, 2023
      </Typography>
    </Box>
  )
}

export default TermsOfServices
