import { CHAIN_ID as SUPPORTED_CHAIN_ID } from './constants/chains';
import { PRODUCTION_ENV, UAT_ENV, DEV_ENV } from "./constants/environments";

// Env variable by each environment
export const APP_ENV = process.env.REACT_APP_NODE_ENV || 'uat';
const ENVs = (envName = 'production') => {
  let envs = {
    production: PRODUCTION_ENV,
    uat: UAT_ENV,
    development: DEV_ENV,
  }

  // @ts-ignore
  return envs[envName] || PRODUCTION_ENV;
}

export const IS_DEV_MODE = !['production', 'uat'].includes(APP_ENV);

export const CHAIN_ID = SUPPORTED_CHAIN_ID;
export const MAIN_CHAIN_ID = IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC ;
export const APPLICATION_URL = window.location.origin
export const APPLICATION_NAME = 'Ape Launchpad'
export const KYC_CLIENT_ID = process.env.REACT_APP_KYC_CLIENT_ID || 'ape_terminal_9eafe';
export const APPLICATION_WEB3_SIGNIN_MSG = `Sign in to the ${APPLICATION_NAME}`
export const SERVER_URL = ENVs(APP_ENV).SERVER_URL || 'https://api-ng.apeterminal.io/api';
export const GOOGLE_CLIENT_ID = process.env?.REACT_APP_GOOGLE_CLIENT_ID || "733766919259-u5t95890bv6q8beidh2i7bk0qef0q5fd.apps.googleusercontent.com";
export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST || 'https://socket.ape-lauchpool.eachop.tech/notification'

// gulf countries are not allowed to join the sales
export const IS_GCC_WEBSITE = window.location.hostname === "gcc.apeterminal.io";

// export const RPC_URL = {
//   BSC:  "https://bsc.blockpi.network/v1/rpc/public",
//   ETH: "https://ethereum.blockpi.network/v1/rpc/public",
//   ARB: "https://arbitrum.blockpi.network/v1/rpc/public",
//   AVAX: "https://avalanche.blockpi.network/v1/rpc/public",
// };

export const RPC_URL = {
  BSC:  "https://rpc.ankr.com/bsc",
  [CHAIN_ID.BSC]: "https://rpc.ankr.com/bsc",
  ETH: "https://rpc.ankr.com/eth",
  [CHAIN_ID.ETH]: "https://rpc.ankr.com/eth",
  ARB: "https://rpc.ankr.com/arbitrum",
  [CHAIN_ID.ARB]: "https://rpc.ankr.com/arbitrum",
  AVAX: "https://rpc.ankr.com/avalanche",
  [CHAIN_ID.AVAX]: "https://rpc.ankr.com/avalanche",
  BSC_TESTNET: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [CHAIN_ID.BSC_TESTNET]: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [CHAIN_ID.BASE]: 'https://rpc.ankr.com/base',
};

//@ts-ignore
export const CONTRACT_ADDRESS = ENVs(APP_ENV).CONTRACT_ADDRESS;

export const WALLET_CONNECT_PROJECT_ID = "1a7e8d507a1b268947b7a23c971a35b3";

// we try to make less database changes as possible on the migration to Azure from AWS
// so will only do a manual endpoint swap on frontend
export const ASSETS_CDN_ENDPOINT_DEPRECATED = "https://launchpad-bucket.s3.amazonaws.com";
export const ASSETS_CDN_ENDPOINT = "https://cdn.apeterminal.io";

export const ENABLE_ANALYTIC = window.location.hostname.endsWith("apeterminal.io");

export const ETH_TOPIC_HASH = {
  // Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)
  ERC20_TRANSFER: "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
}

export const DEFAULTS :Record<string, any> = {
  PROJECT_AVATAR_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-avatar.svg",
  // PROJECT_BANNER_URL: "https://placehold.co/760x404/000000/636363.jpg"
  PROJECT_BANNER_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-cover.svg"
}

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyBbStaoWZQ9WQA9RtZ1pvDz7z1u_1lGI4I",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "ape-fcm-test.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "ape-fcm-test",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "ape-fcm-test.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "877958911089",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:877958911089:web:cbe5186f852794d26a77d0"
}
