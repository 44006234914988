import { useMediaQuery } from '@mui/system'
import OrangeButton from '../../components/OrangeButton'

const ButtonLogin = ({ onClick }: { onClick?: () => void }) => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  return (
    <OrangeButton
      text="Login"
      br="24px"
      fs={15}
      fw={500}
      fm="Inter"
      lh="22.5px"
      p="14px 32px"
      h={isDown1023 ? '40px' : '48px'}
      w="max-content"
      onClick={onClick}
      sx={{
        textTransform: 'none',
        marginLeft: !isDown1023 && '10px',
        marginRight: isDown1023 && '10px',
      }}
    />
  )
}

export default ButtonLogin
