import { Box, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '../../AmplitudeContext'
import { BoxUnion, CircledOne, CircledTwo } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import CircularProgess from './CircularProgess'

const ChanceOfWinningBox = ({ winningChances, ticketPrice }: any) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown1065 = useMediaQuery('(max-width:1065px)')
  const navigate = useNavigate()
  const { trackEvent } = useAnalytics();

  const urlPath = window.location.pathname; 
  const pathSegments = urlPath.split('/');
  const projectId = pathSegments[2];
  const handleProjectXFollow = () => {
    trackEvent('ProjectXFollow', { project_name: projectId });
    window.open('https://twitter.com/apeterminal', '_blank');
  };
  const handleProjectReferralsOpen = () => {
    trackEvent('ProjectReferralsOpen', { project_name: projectId });
    navigate('/portfolio?referral=true')
  };
  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={'16px'}
        width={'100%'}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            backgroundColor: '#000000',
            border: '1px solid #1D1F21',
            borderRadius: '8px',
            padding: '8px',
            gap: '2px',
            display: 'none'
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '16px',
              // textAlign: 'left',
              letterSpacing: '-0.01em',
              background:
                'linear-gradient(90.22deg, #FF7722 0.16%, #F4FDAB 99.78%), #B1B4B3',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              display: 'inline-block',
              width: '100%',
            }}
          >
            1 ticket
          </Typography>
          {ticketPrice === -1 ? (
            <Typography
              sx={{
                fontSize: '14px',
                color: '#7E8180',
                fontWeight: 400,
                lineHeight: '16.8px',
                fontFamily: 'Inter',
              }}
            >
              Launch Drop
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: '14px',
                color: '#7E8180',
                fontWeight: 400,
                lineHeight: '16.8px',
                fontFamily: 'Inter',
              }}
            >
              ${ticketPrice} USDC <br /> allocation
            </Typography>
          )}
        </Box>
        {false && <CircularProgess percent={Math.min(5 + (+Number(winningChances)?.toFixed(1) || 0), 15)} />}
        <Box sx={{display: 'none'}} paddingX={isDown1065 ? '24px' : '20%'}>
          <Typography
            pt={isDown1000 ? '0px' : '16px'}
            pb={isDown1000 ? '16px' : '8px'}
            fontFamily={'Hellix'}
            fontSize={32}
            fontWeight={600}
            lineHeight={'40px'}
            color={'#000'}
          >
            You have little <br />chance of winning
          </Typography>
          <Typography
            fontFamily={'Inter'}
            fontSize={16}
            fontWeight={400}
            lineHeight={'24px'}
            color={'#7E8180'}
          >
            Make sure you're holding at least{' '}
            <b style={{ color: '#FF7722' }}>$200</b>, this updates every 2
            hours.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            paddingX: isDown1065 ? '8px' : '16px',
            paddingTop: '12px',
            paddingBottom: '16px',
            mt: isDown1000 ? '12px' : '24px',
            borderRadius: '8px',
          }}
        >
          {/* <img
            src={BoxUnion}
            alt="box-union"
            style={{
              position: 'relative',
              top: '-1px',
            }}
          /> */}
          <Typography
            fontSize={17}
            fontWeight={500}
            lineHeight={'27.2px'}
            color="#000"
            textAlign={'left'}
            fontFamily={'Inter'}
          >
            <span
              style={{
                // background:
                //   'linear-gradient(90.22deg, #FF7722 0.19%, #F4FDAB 109%)',
                // backgroundClip: 'text',
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
              }}
            >
              Boost
            </span>{' '}
            your chances!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              pt: '12px',
              gap: '16px',
            }}
          >
            <Box
              gap={'8px'}
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#767676',
                  fontWeight: 400,
                  lineHeight: '16.8px',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                }}
              >
                <span style={{ height: '18px' }}>
                  <img src={CircledOne} alt="circled-one" />
                </span>
                Follow Ape Terminal
              </Typography>
              <OutlinedButton
                onClick={() => handleProjectXFollow()}
                sx={{
                  height: '40px',
                  width: '100%',
                  border: '1px solid #313335',
                  backgroundColor: '#000',
                }}
              >
                Follow Ape Terminal
              </OutlinedButton>
            </Box>
            <Box
              gap={'8px'}
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#767676',
                  fontWeight: 400,
                  lineHeight: '16.8px',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                }}
              >
                <span style={{ height: '18px' }}>
                  <img src={CircledTwo} alt="circled-two" />
                </span>
                Refer & invite your friends
              </Typography>
              <OutlinedButton
                onClick={() => handleProjectReferralsOpen()}
                sx={{
                  height: '40px',
                  width: '100%',
                  border: '1px solid #313335',
                  backgroundColor: '#000',
                }}
              >
                Refer your friends
              </OutlinedButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ChanceOfWinningBox
