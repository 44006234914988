import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { TableProjectLogo } from '../../assets'
import {
  FixedBodyTableCell,
  FixedHeadTableCell,
  StyledBodyTableCell,
  StyledHeadTableCell,
} from './MySalesTable'
import { replaceUnderscoresWithSpaces } from '../../utils'
import ClaimRow from './ClaimRow'

const ClaimTable = ({ claimSales }: any) => {
  console.log('🚀 ~ ClaimTable ~ claimSales:', claimSales)
  const isDown800 = useMediaQuery('(max-width:800px)')

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        maxWidth={'1200px'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: claimSales.length <= 5 ? '100%' : '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>Available</StyledHeadTableCell>
                <StyledHeadTableCell>Tokens</StyledHeadTableCell>
                <StyledHeadTableCell>Claimed</StyledHeadTableCell>
                <StyledHeadTableCell>Next Unlock in</StyledHeadTableCell>
                <StyledHeadTableCell></StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#fff',
              }}
            >
              {claimSales.map((sale: any, index: any) => (
                <ClaimRow sale={sale} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default ClaimTable
