import { useCallback, useState } from 'react'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  useUnLinkEmailWidthAddressMutation,
  useVerifyGoogleTokenMutation,
} from '../../services/modules/users'
import {
  setAccessTokenGoogle,
  setAddressLinkEmail,
  setAddressRegisterUserAccount,
  setEmail,
  setEmailRegisterUserAccount,
  setMainAppToken,
  setSignature,
} from '../../redux/slices/userSlice'
import { useAccount, useDisconnect } from 'wagmi'
import { shortenAddress } from '../../utils'
import {
  useFetchNotificationHistoryQuery,
  userApi,
} from '../../services/modules/notification'

interface DisconnectButtonProps {
  setOpenToast: React.Dispatch<React.SetStateAction<boolean>>
  setMessage: React.Dispatch<React.SetStateAction<string>>
  setShowLoader: (open: boolean) => void
}

const DisconnectButton: React.FC<DisconnectButtonProps> = ({
  setOpenToast,
  setMessage,
  setShowLoader,
}) => {
  const [unLinkEmailWidthAddress] = useUnLinkEmailWidthAddressMutation()
  const [verifyGoogleToken] = useVerifyGoogleTokenMutation()
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const user = useAppSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const { disconnect } = useDisconnect()

  const { refetch } = useFetchNotificationHistoryQuery({
    limit: 10,
    page: 1,
  })

  const handleUnLinkEmailWidthAddress = useCallback(async () => {
    if (user.accessTokenGoogle || user.mainAppToken) {
      setLoading(true)
      try {
        const result = await unLinkEmailWidthAddress().unwrap()
        const addressPart = shortenAddress(
          address || user.addressLinkEmail || user.addressRegisterUserAccount,
          5,
          4,
        )
        const emailPart = user.email || user.emailRegisterUserAccount
        if (result) {
          setMessage(`Wallet ${addressPart} is unlinked to email ${emailPart}`)
          dispatch(setAccessTokenGoogle('')) // remove access token login gg 
          setOpenToast(true)
          if(user.email && user.googleToken && user.nameGoogleToken ) {
            const data = await verifyGoogleToken({
              email: user.email,
              googleToken: user.googleToken,
              fullName: user.nameGoogleToken,
            }).unwrap()
            if (data) {
              setShowLoader(true)
              refetch()
              dispatch(userApi.util.resetApiState())
            }
          }
          setTimeout(() => {
            if (address && !user.email) {
              dispatch(setEmail(''))
              dispatch(setEmailRegisterUserAccount(''))
            }
            if (user.addressLinkEmail && address !== user.addressLinkEmail) {
              dispatch(setAddressLinkEmail(''))
              dispatch(setAddressRegisterUserAccount(''))
            }
            if (address === user.addressLinkEmail) {
              if (user.isLoggedInWithEmail || !user.isLoggedInWithEmail) {
                disconnect()
                dispatch(setAddressLinkEmail(''))
                dispatch(setAddressRegisterUserAccount(''))
                dispatch(setSignature(''))
                dispatch(setAccessTokenGoogle('')) // remove access token login gg 
                dispatch(setMainAppToken('')) // remove access token login wallet 
              }
            }

            setOpenToast(false)
            setLoading(false)
          }, 3000)
        }
      } catch (error: any) {
        setMessage(error?.data?.message)
        setOpenToast(true)
        setLoading(false)
        console.log('Error unlinking email:', error)

        setTimeout(() => {
          setOpenToast(false)
        }, 3000)
      }
    }
  }, [
    address,
    disconnect,
    dispatch,
    refetch,
    setMessage,
    setOpenToast,
    setShowLoader,
    unLinkEmailWidthAddress,
    user.accessTokenGoogle,
    user.addressLinkEmail,
    user.addressRegisterUserAccount,
    user.email,
    user.emailRegisterUserAccount,
    user.googleToken,
    user.isLoggedInWithEmail,
    user.mainAppToken,
    user.nameGoogleToken,
    verifyGoogleToken,
  ])

  return (
    <OutlinedButton
      sx={{
        height: '40px',
        width: '200px',
        color: '#000',
        '&:hover': {
          border: '1px solid #313335',
        },
      }}
      disabled={loading}
      onClick={handleUnLinkEmailWidthAddress}
    >
      Disconnect
    </OutlinedButton>
  )
}
export default DisconnectButton
