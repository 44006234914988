// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { api } from "./api";
import { userApi } from './modules/users'
import { store } from "../redux/configureStore";
import { FIREBASE_CONFIG } from "../constant";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const messaging = getMessaging(app);

/**
 * Generate FCM token
 * 
 * @param address string
 */
export const generateToken = async (address: string = '') => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BMLQmwXqxEqDbK1hg_Uqv0aUL5H5im1m5oUHK5xCrZVC0wFPeQBSCCD7mA-jwj3W0Vr_LjJEf19pt8t6Covil-E'
    })
    console.info('FCM token ==>>', token);

    // Firebase subscribe topic
    subscribeToTopics({deviceId: token, walletAddress: address})
  } catch (error) {
    console.log('Firebase subscribe: ', error);
  }
}

/**
 * Subscribe to a topic
 * 
 * @param topicsData { deviceId: "string" }
 */
const subscribeToTopics = async (topicsData: any) => {
  const mutation = userApi.endpoints.firebaseSubscribeTopic.initiate;
  try {
    const result = await store.dispatch(mutation(topicsData)).unwrap();
    console.log('Subscribed to topics successfully:', result);
  } catch (error) {
    console.log('Failed to subscribe to topics:', error);
  }
};

export { messaging, getToken, onMessage, subscribeToTopics };
