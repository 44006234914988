/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import BigNumber from 'bignumber.js'
import { ChainData, chainConfig } from '../constants/chains'
import { AvailableContracts } from '../hooks/useContract/types'
import { ethers } from 'ethers'
import * as viemChains from 'viem/chains';
import { defaultAbiCoder } from '@ethersproject/abi'
import { ASSETS_CDN_ENDPOINT_DEPRECATED, ASSETS_CDN_ENDPOINT, CHAIN_ID } from '../constant'
import { PublicKey } from '@solana/web3.js'
import _ from 'lodash'

dayjs.extend(duration)

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const PROJECT_TYPE_DICTIONARY = {
  AIRDROP: "Airdrop",
  IDO: 'IDO'
} as const

export const isValidSolanaAddress = (address: string) => {
  try {
    const publicKey = new PublicKey(address)
    return true
  } catch (error) {
    return false
  }
}

export const isValidEthAddress = (address: string) => {
  try {
    // check valid ethereum address
    ethers.utils.getAddress(address)
    return true
  } catch (error) {
    return false
  }
}

export const isZeroAddress = (address: string) => {
  return address === ZERO_ADDRESS
}
//
/* eslint-disable @typescript-eslint/no-extra-semi */
export const shortenAddress = (address: string, subStart = 8, subEnd = 8) => {
  if (!address) return ''
  return (
    address.substring(0, subStart) +
    '...' +
    address.substring(address.length - subEnd, address.length)
  )
}

export const getNetworkNameByChainId = (chainId: number) => {
  switch (chainId) {
    case 1:
      return 'Mainnet'
    case 3:
      return 'Ropsten'
    case 4:
      return 'Rinkeby'
    case 5:
      return 'Goerli'
    case 42:
      return 'Kovan'

    case CHAIN_ID.BSC:
      return 'BSC Mainnet'

    case 97:
      return 'BSC Testnet'

    case 43114:
      return 'Avalanche'

    case 8453:
      return 'Base'

    default:
      return 'Unknown'
  }
}

export const getChainCoinNameByChainId = (chainId: number) => {
  switch (chainId) {
    case 1:
      return 'ETH'
    case 3:
      return 'ETH'
    case 4:
      return 'ETH'
    case 5:
      return 'ETH'
    case 42:
      return 'ETH'

    case CHAIN_ID.BSC:
      return 'BNB'

    case 97:
      return 'BNB'

    case 128:
      return 'HT'

    // matic
    case 137:
      return 'MATIC'

    // mumbai
    case 80001:
      return 'MATIC'

    case 42161:
      return 'ARB'

    case 43114:
      return 'AVAX'

    case 8453:
      return 'BASE'

    default:
      return 'Unknown'
  }
}

export const numberFormatter = (num: string) => {
  if (num.includes('.')) {
    // decimal
    num = parseFloat(num).toFixed(2)
  }

  return num
}

export const sixDigitsFormatter = (num: number): string => {
  if (num < 1) {
    return String(parseFloat(num.toPrecision(2)))
  }

  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  const amount = truncate(num / si[i].value) + si[i].symbol
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const truncateExact = (num: number, fixed: number): number => {
  if (num) {
    const sNumber = num.toString()
    const index = sNumber.indexOf('.')
    const newNumber = index !== 0 ? sNumber : '0' + sNumber
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
    const number = newNumber.toString().match(re)
    if (number) {
      return parseFloat(number[0])
    }
    return num
  } else {
    return num
  }
}
export const truncate = (num: number) => {
  if (num) {
    const floatedTo = num >= 1 ? 2 : 3
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (floatedTo || -1) + '})?')
    const number = num?.toString()?.match(re)
    if (number) {
      return number[0]
    }
  } else {
    return num
  }
}

type ChainChangeRequestType = (chainId: string, cb: () => void) => Promise<void>
export const chainChangeRequest: ChainChangeRequestType = async (
  chainId,
  cb,
) => {
  ;(window as any).ethereum
    .request({
      method: 'eth_requestAccounts',
    })
    .then(function () {
      ;(window as any).ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId,
            },
          ],
        })
        .then(() => {
          cb()
        })
        .catch((error: any) => {
          console.error('change req err #1', error, chainId)
          addChainToMetamask(chainConfig[chainId], cb)
        })
    })
    .catch((error: any) => {
      console.error('change req err #2', error)
      addChainToMetamask(chainConfig[chainId], cb)
    })
}

type AddChainToMetamaskType = (
  chainData: ChainData,
  cb: () => void,
) => Promise<void>

export const addChainToMetamask: AddChainToMetamaskType = async (
  chainData,
  cb,
) => {
  console.log('chainData', chainData)
  try {
    ;(window as any).ethereum
      .request({
        method: 'eth_requestAccounts',
      })
      .then(function () {
        ;(window as any).ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: [chainData],
          })
          .then(() => {
            cb()
          })
          .catch((error: string) => {
            console.error('change add err #2', error)
          })
      })
  } catch (error) {
    console.error('change add err #1', error)
  }
}
export const expandDecimals = (
  _value: BigNumber,
  _decimals: number,
): BigNumber => {
  return _value.times(Math.pow(10, _decimals))
}
export const collapseDecimals = (_value: BigNumber, _decimals: number) => {
  return _value.div(Math.pow(10, _decimals))
}
export const applySlippage = (_value: BigNumber, _slippage = 1.5) => {
  return _value.minus(_value.times(_slippage / 100))
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isValidEthereumAddress(_address: string) {
  // Check if the string is a hexadecimal number
  if (!/^(0x)?[0-9a-fA-F]{40}$/.test(_address)) {
    return false
  }

  // Check if the string starts with "0x"
  if (!_address.startsWith('0x')) {
    return false
  }

  return true
}

export const makeSignature = async (provider: any, params: any) => {
  try {
    console.log('params', params)
    let nonce = await provider.send('eth_getTransactionCount', [
      params.fromAddress,
      'latest',
    ])

    console.log('nonce', nonce)

    const DomainSeparator = ethers.utils.keccak256(
      defaultAbiCoder.encode(
        ['string', 'address'],
        ['0x01', AvailableContracts.APE_CONTRACT],
      ),
    )

    console.log('domain seperator', DomainSeparator)
    const message = ethers.utils.keccak256(
      defaultAbiCoder.encode(
        ['uint256', 'address', 'uint256', 'uint256'],
        [
          params.projectId,
          params.fromAddress,
          params.amount, // amount

          params.idoNumber,
        ],
      ),
    )
    console.log('message length', message.length)
    let finalHash = ethers.utils.keccak256(
      ethers.utils.solidityPack(
        ['bytes1', 'bytes1', 'bytes32', 'bytes32'],
        ['0x19', '0x01', DomainSeparator, message],
      ),
    )
    console.log('final hash', finalHash)
    // waleed wala code

    // sign tx using metamask
    const signature = await provider.send('personal_sign', [
      ethers.utils.hexlify(finalHash),
      params.fromAddress,
    ])

    console.log('signature', signature)

    return signature
  } catch (error) {
    console.log('make signature error', error)
  }
}

export const addCommasToNumber = (number: number | string) => {
  // Convert the number to a string
  let numberString = number.toString()

  // Use a regular expression to add commas
  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return numberString
}
export const replaceUnderscoresWithSpaces = (inputString: string) => {
  // Use the replace method with a regular expression to replace underscores with spaces
  const resultString = inputString.replace(/_/g, ' ')

  return resultString
}

export const addFirstVisitInLocalStorage = () => {
  let firstVisit = localStorage.getItem('firstVisit')
}

// capitalize first letter of string
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// lowercase words and put underscore between them if they are more than one word
export const lowercaseWordsAndPutUnderscore = (string: string) => {
  return string.toLowerCase().split(' ').join('_')
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export const shortenText = (inputText: string, numToShow: number): string => {
  if (numToShow <= 0) {
    return inputText
  }

  if (inputText.length <= numToShow) {
    return inputText
  }

  const startSubstring = inputText.substring(0, numToShow / 2)
  const endSubstring = inputText.substring(inputText.length - numToShow / 2)

  return `${startSubstring}...${endSubstring}`
}

export const shortenUrl = (input: string): string => {
  if (input.length < 12) {
    // Handle cases where the string is less than 12 characters
    return input
  }

  const firstPart = input.substring(0, 10)
  const lastPart = input.substring(input.length - 11)

  return `${firstPart}...${lastPart}`
}

export const trimSpace = (input: string): string => {
  // trim space and get first word and lowercase it
  const firstWord = input.trim().split(' ')[0].toLowerCase()

  return firstWord
}

export const sortItemsBySmallerTimestamp = (items: any) => {
  return items.sort((a: any, b: any) => {
    return a.ido.endDate - b.ido.endDate
  })
}

export const sortIDOsOnPortfolioPageByTimestamp = (items: any) => {
  console.log('sortIDOsOnPortfolioPageByTimestamp', items)
  let _items = items.sort((a: any, b: any) => {
    return b.ido.endDate - a.ido.endDate
  })

  return _items
}

export const multiplierValue = (multiplier: number) => {
  if (multiplier === 0) return `${multiplier}`
  else return `${multiplier}x`
}

export const parseJwt = (token: string) => {
  if (!token) return
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  const payload = JSON.parse(jsonPayload)

  console.log('payload', payload)

  return payload
}

export const getChainIdFromName = (chainName: string) => {
  switch (chainName) {
    case 'ETH':
      return 1
    case 'BSC':
      return CHAIN_ID.BSC
    case 'HT':
      return 128
    case 'MATIC':
      return 137
    case 'MUMBAI':
      return 80001
    case 'ARB':
      return 42161
    case 'ARB-testnet':
      return 421614
    case 'AVAX':
      return 43114
    case 'GOERLI':
      return 5
    case 'BASE':
      return 8453
    case 'BSC_TESTNET':
        return CHAIN_ID.BSC_TESTNET
    default:
      return undefined;
  }
}

export const removeXFromROIAndMakeItPercentage = (roi: string|null) => {
  if(!roi) {
    return 'TBA';
  }
  if (roi.includes('x')) {
    let roiValue = roi.split('x')[0]
    //convert to percentage as 13x to 1,300%
    return `${Number((Number(roiValue) * 100).toFixed(0)).toLocaleString(
      'en',
    )}%`
  } else {
    return roi
  }
}

export const RED_RADIAL_GRADIENT =
  'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 51, 247, 0.08) 0%, rgba(255, 51, 247, 0) 100%), #141414'

export const RED_LINEAR_GRADIENT =
  'linear-gradient(145deg, rgba(16, 201, 242, 0.4) 0%, rgba(255, 255, 255, 0.09) 50%, rgba(224, 102, 255, 0.4) 100%)'

export const GREEN_RADIAL_GRADIENT =
  'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(165, 253, 13, 0.08) 0%, rgba(165, 253, 13, 0.00) 100%), #141414'

export const GREEN_LINEAR_GRADIENT =
  'linear-gradient(145deg, rgba(16,242,234,0.4038209033613446) 0%, rgba(255,255,255,0.09289653361344541) 50%, rgba(99,234,113,0.4010197829131653) 100%)'

export const ORANGE_RADIAL_GRADIENT =
  'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 185, 0, 0.08) 0%, rgba(255, 185, 0, 0.00) 100%), #141414'

export const ORANGE_LINEAR_GRADIENT =
  'linear-gradient(145deg, rgba(255,185,0,0.3954175420168067) 0%, rgba(255,255,255,0.09289653361344541) 50%, rgba(255,185,0,0.4010197829131653) 100%)'

export const PURPLE_RADIAL_GRADIENT =
  'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(102, 78, 223, 0.18) 0%, rgba(102, 78, 223, 0.00) 100%), #141414'

export const PURPLE_LINEAR_GRADIENT =
  'linear-gradient(145deg, rgba(99,234,113,0.4010197829131653) 0%, rgba(255,255,255,0.09289653361344541) 50%, rgba(99,75,219,0.3954175420168067) 100%)'

// replace AWS S3 bucket endpoint (in DB)
export const getReplacedCdnEndpoint = (input :any) => {
  if(typeof input != 'string') return input;
  return input.replace(ASSETS_CDN_ENDPOINT_DEPRECATED, ASSETS_CDN_ENDPOINT).replace(/\+/g, " ");  
}

export const consoleLog = (...args :any[]) => {
  // @ts-ignore
  if(typeof window.consoleLog != 'function') return;
  // @ts-ignore
  window.consoleLog(...args);
}

export const assert = (input :any, error :any) => {
  if(!input) throw new Error(error || "Assertion failed");
}

export const formatEthBalance = (input :ethers.BigNumber|BigInt, decimals :number|ethers.BigNumber = 18, dp :number = 4, commify = true) => {
  if(typeof input == 'bigint') {
    input = ethers.BigNumber.from(input);
  }
  if(!ethers.BigNumber.isBigNumber(input)) return 'n/a';
  if(ethers.BigNumber.isBigNumber(decimals)) {
    decimals = decimals.toNumber();
  }
  if(dp > 0) {
    const concater = ethers.utils.parseUnits("1", decimals - dp);
    input = input.div(concater).mul(concater);
  }
  const formattedBalance = ethers.utils.formatUnits(input, decimals).replace(/.0$/, "");
  if(commify) {
    return ethers.utils.commify(formattedBalance);
  }
  return formattedBalance;
}

export const formatEthBalanceLite = (balance :ethers.BigNumber|BigInt|undefined, decimals :number|ethers.BigNumber = 18, isString: boolean = false) => {
  if (!balance) return '0';

  if(typeof balance == 'bigint') {
    balance = ethers.BigNumber.from(balance);
  }

  return isString ? ethers.utils.formatUnits(Number(balance), decimals).toString() : ethers.utils.formatUnits(Number(balance), decimals);
}

export const formatDuration = (durationSecs :number, fallback = 'n/a') => {
  if(!durationSecs || typeof durationSecs != 'number') return fallback;
  // just in case we passing a time delta
  if(durationSecs < 0) durationSecs = 0;
  const text = dayjs.duration(durationSecs * 1000).format("Y[Y] M[M] D[D] H[h] m[m] s[s]");
  return text.replace(/^0Y /, '').replace(/^0M /, '').replace(/^0D /, '');
}

export const getException = (exception: unknown) => {
  const message =
    _.get(exception, "reason") ||
    _.get(exception, "error") ||
    _.get(exception, "message") ||
    (typeof exception == "string" && exception) ||
    (_.has(exception, "toString")
      ? (exception as any).toString()
      : "Unknown exception");
  const stack =
    _.get(exception, "error.stack") ||
    _.get(exception, "stack") ||
    "<no stack>";
  const code = _.get(exception, "code");
  return {
    message,
    stack,
    code,
  };
};

export const getExceptionMessage = (exception: unknown) => {
  return getException(exception).message;
};

export const getEpochMs = () => {
  return (new Date()).getTime();
}

export const getEpoch = () => {
  return Math.floor((new Date()).getTime() / 1000);
}

export const insertSeparator = (_array: any[], _separator: any = "") => {
  return _.flatMap(_array, (value, index, array) =>
    array.length - 1 !== index ? [value, _separator] : value
  );
};

export const sleep = (secs :number) => {
  return new Promise(resolve => {
    setTimeout(resolve, secs * 1000);
  });
}

export const getChainName = (chainId ?:string|number) => {
  if(!chainId) return "Unknown";
  const chain = _.values(viemChains).find(c => c.id === +chainId);
  return chain ? chain.name : `chainId:${chainId}`;
}

export function formatBigNumber(tokenDeposited: BigInt, decimals = 18): string {
  if (!tokenDeposited) {
    return "0"; 
  }
  try {
    
    const bigNumberValue = new BigNumber(tokenDeposited.toString());
    const formattedValue = bigNumberValue.dividedBy(new BigNumber(10).pow(decimals))
    
    if(formattedValue.isEqualTo(0)){
      return "0"
    }

    if (formattedValue.isLessThan(0.01)) {
      return "< 0.01";
    }
  
    return  Number(formattedValue.toFixed(2)).toLocaleString('en')
  } catch (error) {
    console.error("Error formatting tokenDeposited:", error);
    return "Invalid value";
  }
}
